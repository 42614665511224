import React, { useState, useEffect, useMemo } from "react";
import {
  pdf, // Importamos pdf para generar el blob
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import EditarCotizacionForm from "./EditarCotizacionForm";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore"; // Importar funciones de Firestore
import { db, storage } from "../firebaseConfig";
import { ref, deleteObject } from "firebase/storage";
import Modal from "react-modal";
import Swal from "sweetalert2";
import OpenRegular from "../fonts/OpenSans-Regular.ttf"; // Ruta de la fuente
import OpenBold from "../fonts/OpenSans-Bold.ttf"; // Ruta de la fuente

Font.register({
  family: "Open",
  fonts: [
    { src: OpenRegular, fontWeight: "normal" },
    { src: OpenBold, fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  pdfContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pdfViewer: {
    width: "80%",
    height: "90%",
    border: "1px solid #ccc",
    borderRadius: 5,
    overflow: "hidden",
  },
  page: {
    padding: 30,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
    marginTop: "20px",
  },
  logoContainer: {
    width: "50%",
    marginTop: "5px",
  },
  logo: {
    width: "150px",
    height: "auto",
  },
  headerInfo: {
    width: "50%",
    textAlign: "right",
  },
  companyName: {
    fontSize: "10px",
    fontWeight: 600,
    fontFamily: "Open",
  },
  companyDetails: {
    fontSize: "10px",
    fontFamily: "Open",
    marginTop: "2px",
  },
  titleSection: {
    textAlign: "center",
    marginBottom: "20px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#1976d2",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  clientInfo: {
    width: "50%",
    fontSize: "10px",
  },
  infoTitle: {
    fontSize: "10px",
    fontFamily: "Open",
    marginBottom: "5px",
  },
  infoText: {
    fontSize: "10px",
    fontFamily: "Open",
    marginBottom: "3px",
  },
  infoTextColor: {
    fontSize: "10px",
    fontFamily: "Open",
    marginBottom: "3px",
    color: "#1976d2",
    fontWeight: 600,
  },
  infoBlessed: {
    fontSize: "11px",
    marginTop: "10px",
    fontFamily: "Open",
    textAlign: "left",
    marginBottom: "10px",
  },
  cotizacionTable: {
    width: "50%",
    textAlign: "right",
    marginTop: "-10px",
    left: "40px",
  },
  table: {
    marginBottom: "10px",
    fontFamily: "Open",
    width: "100%",
    borderWidth: 1,
    borderColor: "#fff",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#fff",
    backgroundColor: "#F9F9F9",
  },
  tableRowHeader: {
    flexDirection: "row",
    backgroundColor: "#6c6c6c",
    borderBottomWidth: 1,
    borderColor: "#fff",
    color: "#fff",
    fontSize: "10px",
  },
  tableCell: {
    padding: 8,
    textAlign: "left", // Cambiar a 'center' si deseas centrar el contenido
    borderRightWidth: 1,
    borderRightColor: "#fff",
    borderRightStyle: "solid",
    fontSize: "11px",
    lineHeight: "1.5",
  },
  tableCellDesc: {
    padding: 8,
    textAlign: "left",
    borderRightWidth: 1,
    borderRightColor: "#fff",
    borderRightStyle: "solid",
    fontSize: "10px",
    lineHeight: "1.5",
    color: "#7E7D7D",
    wordBreak: "break-word", // Ajusta palabras largas
    whiteSpace: "pre-wrap", // Permite saltos de línea
    overflow: "hidden", // Evita desbordamientos
    wrap: true,
  },
  closeButton: {
    color: "#fff",
    top: "15px",
    fontSize: "20px",
  },
  tableSmall: {
    marginTop: "10px",
  },
  tableRowSmall: {
    display: "flex",
    left: "55%",
    flexDirection: "row",
    marginBottom: "3px", // Margen reducido para menor altura de fila
  },
  tableLabelSmall: {
    fontSize: "9px", // Tamaño de fuente más pequeño
    fontWeight: "bold",
    width: "30%",
    padding: "6px",
    color: "#fff",
    backgroundColor: "#6c6c6c",
    textAlign: "left",
  },
  tableValueSmall: {
    fontSize: "9px", // Tamaño de fuente más pequeño
    width: "70%",
    textAlign: "left",
    margin: "5px",
  },
  footerContainer: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-start",
    minHeight: 150, // Asegura que tenga espacio al final
  },
  footerLeft: {
    width: "45%",
    textAlign: "justify",
    left: 25,
  },
  footerRight: {
    width: "40%",
    textAlign: "right",
    minHeight: 100, // Añade un mínimo para evitar que se salga de la página
  },
  footerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerRowGray: {
    backgroundColor: "#F9F9F9",
    padding: 8,
  },
  footerRowBlue: {
    backgroundColor: "#1565c0",
    padding: 8,
  },
  footerLabel: {
    fontSize: 11,
    textAlign: "right",
  },
  footerValue: {
    fontSize: 11,
    textAlign: "right",
  },
  footerLabelBlue: {
    color: "#fff",
    fontWeight: "bold",
  },
  footerValueBlue: {
    color: "#fff",
    fontWeight: "bold",
    margin: 0,
  },
  footerLabelContainer: {
    minWidth: 20,
    paddingRight: 10,
    flex: 1, // Añadido para ocupar todo el espacio disponible
    justifyContent: "flex-end", // Alinea el contenido al final
    alignItems: "flex-end", // Alinea el contenido horizontalmente al final
  },
  footerValueContainer: {
    paddingLeft: 0,
    paddingRight: 5,
    margin: 0,
    flex: 1, // Asegura que ocupe el mismo espacio que el label
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  condicionesTitle: {
    fontSize: "10px",
    marginBottom: 15,
    marginTop: 10,
    fontFamily: "Open",
  },
  termsContainer: {
    gap: 10,
    textAlign: "justify",
    fontFamily: "Open",
  },
  numeroDePagina: {
    color: "#999999",
    fontSize: 12,
  },
  condicionesContainer: {
    marginBottom: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: "#000",
  },
  condicionesTitulo: {
    fontSize: 12,
    fontWeight: 600,
    color: "red",
    marginBottom: 5,
    fontFamily: "Open",
  },
  condicionesTexto: {
    fontSize: 11,
    marginBottom: 3,
    fontFamily: "Open",
    textAlign: "justify",
  },
  condicionesTexto2: {
    fontSize: 11,
    marginBottom: 3,
    fontFamily: "Open",
    textAlign: "justify",
  },
  espaciado: {
    marginBottom: 20,
  },
  // Contenedor de la sección de cancelaciones
  cancelacionesContainer: {
    marginBottom: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: "#000",
  },
  cancelacionesTitulo: {
    fontFamily: "Open",
    fontSize: 12,
    fontWeight: "bold",
    color: "red",
    marginBottom: 5,
    marginTop: 15,
  },
  cancelacionesTexto: {
    fontSize: 11,
    marginBottom: 5,
    textAlign: "justify",
    fontFamily: "Open",
  },
  // Estilo para la sección de garantía
  garantiaContainer: {
    marginBottom: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: "#000",
  },
  garantiaTexto: {
    fontSize: 11,
    textAlign: "justify",
    marginBottom: 3,
    fontFamily: "Open",
  },
  // Contenedor de los datos bancarios
  bancariosContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch", // Mantiene ambas secciones alineadas verticalmente
    padding: 10,
    borderWidth: 1,
    margin: 0,
    borderColor: "#000",
    height: 120, // Ajustamos la altura para que el contenedor mantenga el tamaño fijo
  },
  datosBancariosIzquierda: {
    width: "48%", // Ajusta el ancho para dejar espacio para la línea divisoria
    gap: 5,
  },
  datosBancariosDerecha: {
    width: "48%", // Ajusta el ancho para dejar espacio para la línea divisoria
    textAlign: "left",
  },
  lineaDivisoria: {
    width: 1, // Ancho de la línea divisoria
    backgroundColor: "#000", // Color de la línea
    height: "119px", // Fuerza a la línea a ocupar toda la altura del contenedo
    top: -10,
  },
  datosBancariosTitulo: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Open",
    marginBottom: 5,
  },
  banco: {
    fontSize: 11,
    fontFamily: "Open",
    marginBottom: 3,
  },
  moneda: {
    fontSize: 11,
    marginBottom: 3,
    fontFamily: "Open",
  },
  beneficiario: {
    fontSize: 11,
    fontFamily: "Open",
    marginBottom: 3,
  },
  cuenta: {
    fontSize: 11,
    marginBottom: 3,
    fontFamily: "Open",
  },
  clabe: {
    fontSize: 11,
    fontFamily: "Open",
    marginBottom: 3,
  },
  referencias: {
    fontSize: 11,
    color: "red",
    marginBottom: 5,
    fontFamily: "Open",
  },
  envioComprobante: {
    fontSize: 11,
    color: "red",
    fontFamily: "Open",
    marginBottom: 3,
  },
  correos: {
    fontSize: 11,
    marginBottom: 2,
    fontFamily: "Open",
  },
});

const ResumenCotizacion = ({
  cotizacion,
  isOpen,
  onClose,
  clientes,
  setCotizaciones,
  cotizaciones,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showSummary, setShowSummary] = useState(true);
  const [pdfVisible, setPdfVisible] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAdjuntosModal, setShowAdjuntosModal] = useState(false);
  const [adjuntos, setAdjuntos] = useState([]);

  // Obtener archivos adjuntos directamente desde la cotización
  useEffect(() => {
    if (showAdjuntosModal && cotizacion.archivos) {
      setAdjuntos(cotizacion.archivos);
    }
  }, [cotizacion, showAdjuntosModal]);

  const handleDeleteArchivo = async (archivoId, archivo) => {
    try {
      // Si estás utilizando una colección separada para archivos, implementa aquí la lógica de eliminación.
      // Dado que en CotizacionForm los archivos están dentro de "cotizaciones", ajustaremos la lógica.

      // 1. Eliminar el archivo de Firebase Storage
      const fileRef = ref(storage, archivo.url); // Suponiendo que 'url' es el path en Storage
      await deleteObject(fileRef);

      // 2. Actualizar la cotización en Firestore para eliminar el archivo
      const cotizacionRef = doc(db, "cotizaciones", cotizacion.id);
      await updateDoc(cotizacionRef, {
        archivos: cotizacion.archivos.filter((a) => a.url !== archivo.url),
      });

      // 3. Actualizar el estado local de adjuntos
      setAdjuntos((prevAdjuntos) =>
        prevAdjuntos.filter((a) => a.url !== archivo.url)
      );

      // 4. Actualizar la lista de cotizaciones si es necesario
      if (typeof setCotizaciones === "function") {
        setCotizaciones((prevCotizaciones) =>
          prevCotizaciones.map((c) =>
            c.id === cotizacion.id
              ? {
                  ...c,
                  archivos: c.archivos.filter((a) => a.url !== archivo.url),
                }
              : c
          )
        );
      }

      // Opcional: Mostrar una notificación de éxito
      Swal.fire({
        icon: "success",
        title: "Archivo eliminado",
        text: `El archivo "${archivo.nombre}" ha sido eliminado correctamente.`,
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error("Error al eliminar el archivo:", error);
      Swal.fire({
        icon: "error",
        title: "Error al eliminar",
        text: "Hubo un problema al eliminar el archivo. Por favor, intenta nuevamente.",
      });
    }
  };
  useEffect(() => {
    const fetchCliente = async () => {
      if (cotizacion?.cliente) {
        try {
          const clienteDoc = await getDoc(
            doc(db, "clientes", cotizacion.cliente)
          );
          if (clienteDoc.exists()) {
            setCliente(clienteDoc.data());
          } else {
            console.error("Cliente no encontrado");
            setCliente(null);
          }
        } catch (error) {
          console.error("Error al obtener el cliente:", error);
          setCliente(null);
        }
      } else if (cotizacion?.nombreCliente) {
        try {
          const clientesRef = collection(db, "clientes");
          const q = query(
            clientesRef,
            where("nombreCliente", "==", cotizacion.nombreCliente)
          );
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const clienteDoc = querySnapshot.docs[0];
            setCliente(clienteDoc.data());
          } else {
            setCliente(null);
          }
        } catch (error) {
          console.error("Error al obtener el cliente por nombre:", error);
          setCliente(null);
        }
      } else {
        setCliente(null);
      }
    };

    fetchCliente();
  }, [cotizacion]);

  useEffect(() => {
    if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen, onClose]);

  // Memoriza el documento PDF
  const memoizedPdfDocument = useMemo(() => {
    if (!cotizacion || !cotizacion.productosSeleccionados.length) {
      return null;
    }

    // Definir variables dentro de useMemo
    const productosPorPagina = 25; // Ajusta este número según sea necesario
    const paginasDeProductos = [];
    const direccionCliente = cotizacion.direccionCliente || {};
    const calle = direccionCliente.calle || cliente?.calle || "";
    const numeroExt = direccionCliente.numeroExt || cliente?.numeroExt || "";
    const colonia = direccionCliente.colonia || cliente?.colonia || "";
    const ciudad = direccionCliente.ciudad || cliente?.ciudad || "";
    const estado = direccionCliente.estado || cliente?.estado || "";
    const codigoPostal =
      direccionCliente.codigoPostal || cliente?.codigoPostal || "";

    for (
      let i = 0;
      i < cotizacion.productosSeleccionados.length;
      i += productosPorPagina
    ) {
      paginasDeProductos.push(
        cotizacion.productosSeleccionados.slice(i, i + productosPorPagina)
      );
    }

    // Subtotal
    const subtotal = cotizacion.productosSeleccionados.reduce(
      (acc, producto) => acc + producto.tarifa * producto.cantidad,
      0
    );

    // Descuento Aplicado
    const descuentoPorcentaje = cotizacion.descuento || 0;
    const descuentoAplicado = subtotal * (descuentoPorcentaje / 100);

    // Subtotal con Descuento (Restante)
    const subtotalConDescuento = subtotal - descuentoAplicado;

    // IVA Total
    const ivaRate = 0.16; // Tasa de IVA
    const ivaTotal = subtotalConDescuento * ivaRate;

    // Total final
    const total = subtotalConDescuento + ivaTotal;

    function formatDate(dateString) {
      const date = dateString ? new Date(dateString) : new Date();
      const day = date.getDate().toString().padStart(2, "0");

      const months = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ];
      const month = months[date.getMonth()];

      const year = date.getFullYear();

      return `${day} ${month} ${year}`;
    }

    return (
      <Document>
        {paginasDeProductos.map((productosPagina, pageIndex) => (
          <Page
            key={pageIndex}
            size="A4"
            orientation="landscape"
            style={styles.page}
          >
            {/* Header y detalles de la cotización solo en la primera página */}
            {pageIndex === 0 && (
              <>
                {/* Header */}
                <View style={styles.header}>
                  <View style={styles.logoContainer}>
                    <Image src="/img/logo-iciamex2.png" style={styles.logo} />
                  </View>
                  <View style={styles.headerInfo}>
                    <Text style={styles.companyName}>
                      UNIFICACION PARA LA AUTOMATIZACION SA DE CV
                    </Text>
                    <Text style={styles.companyDetails}>
                      Lic. Ramiro Hernández 448, Las Pintitas
                    </Text>
                    <Text style={styles.companyDetails}>
                      El Salto, Jalisco, Mexico, C.P. 45693
                    </Text>
                    <Text style={styles.companyDetails}>(33) 1602 2358</Text>
                    <Text style={styles.companyDetails}>UAU151222IT1</Text>
                  </View>
                </View>

                {/* Title */}
                <View style={styles.titleSection}>
                  <Text style={styles.title}>COTIZACIÓN</Text>
                </View>

                {/* Information Sections */}
                <View style={styles.infoContainer}>
                  {/* Left Side: Cliente Information */}
                  <View style={styles.clientInfo}>
                    <Text style={styles.infoTitle}>Cliente:</Text>
                    <Text style={styles.infoTextColor}>
                      {cotizacion.nombreCliente || "Nombre del Cliente"}
                    </Text>
                    {calle && (
                      <Text style={styles.infoText}>
                        {`${calle}${numeroExt ? ` ${numeroExt}` : ""}${
                          colonia ? `, ${colonia}` : ","
                        }`}
                      </Text>
                    )}
                    {(ciudad || estado) && (
                      <Text style={styles.infoText}>
                        {`${ciudad}${ciudad && estado ? ", " : ""}${estado}`}.
                      </Text>
                    )}
                    {codigoPostal && (
                      <Text style={styles.infoText}>
                        {`C.P. ${codigoPostal}`}
                      </Text>
                    )}
                  </View>

                  {/* Right Side: Cotización Table */}
                  <View style={styles.cotizacionTable}>
                    <View style={styles.tableSmall}>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>
                          {`Cotización #`}
                        </Text>
                        <Text style={styles.tableValueSmall}>
                          {cotizacion.numeroCotizacion
                            ?.toString()
                            .padStart(4, "0")}
                        </Text>
                      </View>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>{`Fecha`}</Text>
                        <Text style={styles.tableValueSmall}>
                          {formatDate(cotizacion.fechaCotizacion)}
                        </Text>
                      </View>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>
                          {`Vencimiento`}
                        </Text>
                        <Text style={styles.tableValueSmall}>
                          {formatDate(cotizacion.fechaVencimiento)}
                        </Text>
                      </View>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>{`Vendedor`}</Text>
                        <Text style={styles.tableValueSmall}>
                          {cotizacion.vendedorNombre || "N/A"}
                        </Text>
                      </View>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>{`Atención`}</Text>
                        <Text style={styles.tableValueSmall}>
                          {cotizacion.atencion || "N/A"}
                        </Text>
                      </View>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>{`Planta`}</Text>
                        <Text style={styles.tableValueSmall}>
                          {cotizacion.planta || "N/A"}
                        </Text>
                      </View>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>{`Área`}</Text>
                        <Text style={styles.tableValueSmall}>
                          {cotizacion.area || "N/A"}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <Text style={styles.infoBlessed}>
                  Gracias por darnos la oportunidad de hacerle una propuesta de
                  nuestros equipos y/o servicios. Estamos seguros que quedará
                  satisfecho con los beneficios que ofrecemos.
                </Text>
              </>
            )}

            {/* Product Table */}
            <View style={styles.table}>
              {/* Encabezados de la tabla */}
              {pageIndex === 0 && (
                <View style={styles.tableRowHeader}>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableHeader,
                      { flex: 0.1 },
                    ]}
                  >
                    #
                  </Text>
                  <Text
                    style={[styles.tableCell, styles.tableHeader, { flex: 5 }]}
                  >
                    Artículo & Descripción
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableHeader,
                      { flex: 1.6 },
                    ]}
                  >
                    Modelo
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableHeader,
                      { flex: 0.5 },
                    ]}
                  >
                    Cant.
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableHeader,
                      { flex: 1.2 },
                    ]}
                  >
                    Precio Unitario
                  </Text>
                  <Text
                    style={[styles.tableCell, styles.tableHeader, { flex: 1 }]}
                  >
                    Importe
                  </Text>
                </View>
              )}

              {/* Filas de Productos */}
              {productosPagina.map((producto, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={[styles.tableCell, { flex: 0.1 }]}>
                    {index + 1 + pageIndex * productosPorPagina}
                  </Text>
                  <Text style={[styles.tableCellDesc, { flex: 5 }]}>
                    {
                      producto.descripcion
                        ? producto.descripcion // Si existe descripción, solo mostrarla
                        : producto.nombre // Si no hay descripción, mostrar el nombre
                    }
                  </Text>

                  <Text style={[styles.tableCell, { flex: 1.6 }]}>
                    {producto.modelo || producto.numeroDeParte}
                  </Text>
                  <Text style={[styles.tableCell, { flex: 0.5 }]}>
                    {producto.cantidad}
                  </Text>
                  <Text style={[styles.tableCell, { flex: 1.2 }]}>
                    {parseFloat(producto.tarifa)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                  <Text style={[styles.tableCell, { flex: 1 }]}>
                    {parseFloat(producto.importe)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </View>
              ))}

              {/* Si es la última página de productos, mostramos los totales */}
              {pageIndex === paginasDeProductos.length - 1 && (
                <View style={styles.footerContainer} wrap={false}>
                  {/* Condiciones de Pago - Izquierda */}
                  <View style={styles.footerLeft}>
                    {cotizacion.notas &&
                      cotizacion.notas.split("\n").map((linea, index) => (
                        <Text
                          key={index}
                          style={{ fontSize: 10, marginBottom: 4 }}
                        >
                          {linea}
                        </Text>
                      ))}
                  </View>

                  {/* Sección de Totales - Derecha */}
                  <View style={styles.footerRight}>
                    <View style={[styles.footerRow, styles.footerRowGray]}>
                      <View style={styles.footerLabelContainer}>
                        <Text style={[styles.footerLabel]}>Subtotal</Text>
                      </View>
                      <View style={styles.footerValueContainer}>
                        <Text style={[styles.footerValue]}>
                          $
                          {subtotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Text>
                      </View>
                    </View>

                    {descuentoAplicado > 0 && (
                      <>
                        <View style={[styles.footerRow, styles.footerRowGray]}>
                          <View style={styles.footerLabelContainer}>
                            <Text style={[styles.footerLabel]}>
                              Descuento ({descuentoPorcentaje}%)
                            </Text>
                          </View>
                          <View style={styles.footerValueContainer}>
                            <Text style={[styles.footerValue]}>
                              -$
                              {descuentoAplicado
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.footerRow, styles.footerRowGray]}>
                          <View style={styles.footerLabelContainer}>
                            <Text style={[styles.footerLabel]}>Restante</Text>
                          </View>
                          <View style={styles.footerValueContainer}>
                            <Text style={[styles.footerValue]}>
                              $
                              {subtotalConDescuento
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Text>
                          </View>
                        </View>
                      </>
                    )}

                    <View style={[styles.footerRow, styles.footerRowGray]}>
                      <View style={styles.footerLabelContainer}>
                        <Text style={[styles.footerLabel]}>IVA</Text>
                      </View>
                      <View style={styles.footerValueContainer}>
                        <Text style={[styles.footerValue]}>
                          $
                          {ivaTotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Text>
                      </View>
                    </View>

                    <View style={[styles.footerRow, styles.footerRowBlue]}>
                      <View style={styles.footerLabelContainer}>
                        <Text
                          style={[styles.footerLabel, styles.footerLabelBlue]}
                        >
                          Total
                        </Text>
                      </View>
                      <View style={styles.footerValueContainer}>
                        <Text
                          style={[styles.footerValue, styles.footerValueBlue]}
                        >
                          $
                          {total
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          {cotizacion.divisa}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              )}
            </View>

            {/* Footer: Page number */}
            <View
              fixed
              style={{
                position: "absolute",
                bottom: 10,
                left: 20,
                right: 30,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center", // Para asegurarte de que estén verticalmente alineados
              }}
            >
              {/* Texto alineado a la izquierda */}
              <Text
                style={{
                  fontSize: 10,
                  textAlign: "left",
                  marginLeft: 10,
                  color: "#999999",
                }}
              >
                UNIFICACION PARA LA AUTOMATIZACION SA DE CV
              </Text>

              {/* Número de página alineado a la derecha */}
              <Text
                style={styles.numeroDePagina}
                render={({ pageNumber }) => `${pageNumber}`}
              />
            </View>
          </Page>
        ))}

        {/* Página de Términos y Condiciones */}
        <Page size="A4" orientation="landscape" style={styles.page}>
          <View style={styles.termsContainer}>
            <Text style={{ fontSize: "13px" }}>{`Términos y condiciones`}</Text>
            {cotizacion.terminos &&
              cotizacion.terminos.split("\n").map((linea, index) => (
                <Text key={index} style={{ fontSize: "11px" }}>
                  {linea}
                </Text>
              ))}
          </View>

          {/* Footer: Page number */}
          <View
            fixed
            style={{
              position: "absolute",
              bottom: 10,
              left: 20,
              right: 30,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center", // Para asegurarte de que estén verticalmente alineados
            }}
          >
            {/* Texto alineado a la izquierda */}
            <Text
              style={{
                fontSize: 10,
                textAlign: "left",
                marginLeft: 10,
                color: "#999999",
              }}
            >
              UNIFICACION PARA LA AUTOMATIZACION SA DE CV
            </Text>

            {/* Número de página alineado a la derecha */}
            <Text
              style={styles.numeroDePagina}
              render={({ pageNumber }) => `${pageNumber}`}
            />
          </View>
        </Page>

        <Page size="A4" orientation="landscape" style={styles.page}>
          {/* Sección de Condiciones Importantes */}
          <View>
            <Text style={styles.condicionesTitulo}>
              CONDICIONES IMPORTANTES.
            </Text>
            <Text style={[styles.condicionesTexto, styles.espaciado]}>
              Las cotizaciones, incluyendo imágenes y precios, son de carácter
              informativo, y podrán variar según el producto y la fecha del
              pedido. En el caso de fabricación de equipos, el diseño final será
              enviado una vez que se haya confirmado orden de compra o anticipo.
            </Text>
            <Text style={styles.condicionesTexto}>
              Favor de confirmar existencias antes de realizar pedido.
            </Text>
            <Text style={styles.condicionesTexto}>
              Precios en Moneda Nacional (Pesos Mexicanos). A todos los precios
              enlistados hay que agregarles el IVA. Precios sujetos a cambio sin
              previo aviso.
            </Text>
            <Text style={styles.condicionesTexto}>
              En algunos pedidos se requiere 50% de anticipo para empezar la
              fabricación del proyecto.
            </Text>
          </View>

          {/* Sección de Cancelaciones */}
          <View>
            <Text style={styles.cancelacionesTitulo}>
              TODA CANCELACIÓN O DEVOLUCIÓN GENERA UN CARGO.
            </Text>
            <Text style={[styles.cancelacionesTexto, styles.espaciado]}>
              Se aplicará un 50% del valor del producto a las cancelaciones
              solicitadas, y según la etapa del proyecto, pero estará
              condicionado a la aprobación de nuestra área de Ingeniería y
              Mantenimiento. Este valor es debido a que los equipos son
              fabricados especialmente y de acuerdo a las especificaciones del
              cliente.
            </Text>
            <Text style={styles.cancelacionesTexto}>
              <Text style={{ color: "red" }}>IMPORTANTE:</Text> Todos los
              pedidos que requieran del servicio de paquetería se enviarán a la
              dirección de facturación si el cliente no especifica por escrito
              la dirección del envío. El tiempo de entrega dependerá de la
              paquetería elegida por el cliente.
            </Text>
            <Text style={[styles.cancelacionesTexto, styles.espaciado]}>
              La capacitación o asesoría fuera de nuestras instalaciones
              generará un costo adicional, así como las visitas para cualquier
              servicio o mantenimiento solicitado, agregándose al mismo un cargo
              por transporte, hospedaje y viáticos, y estarán sujetas a
              programación.
            </Text>
          </View>

          {/* Sección de Garantía */}
          <View style={styles.garantiaContainer}>
            <Text style={styles.garantiaTexto}>
              GARANTÍA. Los equipos cuentan con 1 año de garantía, ya sea por
              defectos de manufactura o de los materiales, excepto en las partes
              eléctricas y en aquellos que por mal uso se deterioren. Todas las
              garantías se validarán en nuestro domicilio a excepción de
              aquellas que se soliciten en otra ubicación, siempre y cuando haya
              sido cubierto al 100% el costo que este genere. Nos reservamos el
              derecho de aplicar la garantía si el equipo ha sido utilizado de
              forma inadecuada o detecta intentos de reparación por personas no
              autorizadas.
            </Text>
          </View>

          <View style={styles.bancariosContainer}>
            {/* Columna Izquierda */}
            <View style={styles.datosBancariosIzquierda}>
              <Text style={styles.datosBancariosTitulo}>DATOS BANCARIOS</Text>
              <Text style={styles.banco}>Banco: Banamex</Text>
              <Text style={styles.moneda}>Depósitos en Moneda Nacional</Text>
              <Text style={styles.beneficiario}>
                Beneficiario: UNIFICACION PARA LA AUTOMATIZACION SA DE CV
              </Text>
              <Text style={styles.cuenta}>Cta: 04856983</Text>
              <Text style={styles.clabe}>Clabe: 002320701048569836</Text>
            </View>

            {/* Línea Divisoria */}
            <View style={styles.lineaDivisoria} />

            {/* Columna Derecha */}
            <View style={styles.datosBancariosDerecha}>
              <Text style={[styles.referencias, styles.espaciado]}>
                Referenciar el depósito con el nombre del cliente, número de
                cotización, pedido, factura, o cualquier dato que nos ayude a
                identificarlo.
              </Text>
              <Text style={[styles.envioComprobante, styles.espaciado]}>
                Favor de enviar su comprobante de pago a los siguientes correos:
              </Text>
              <Text style={styles.correos}>contacto@iciamex.com</Text>
              <Text style={styles.correos}>contabilidad@iciamex.com</Text>
            </View>
          </View>

          {/* Footer: Número de página y texto alineado */}
          <View
            fixed
            style={{
              position: "absolute",
              bottom: 10,
              left: 20,
              right: 30,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center", // Para asegurarte de que estén verticalmente alineados
            }}
          >
            {/* Texto alineado a la izquierda */}
            <Text
              style={{
                fontSize: 10,
                textAlign: "left",
                marginLeft: 10,
                color: "#999999",
              }}
            >
              UNIFICACION PARA LA AUTOMATIZACION SA DE CV
            </Text>

            {/* Número de página alineado a la derecha */}
            <Text
              style={styles.numeroDePagina}
              render={({ pageNumber }) => `${pageNumber}`}
            />
          </View>
        </Page>
      </Document>
    );
  }, [cotizacion, cliente]);

  useEffect(() => {
    let isMounted = true;
    let url;

    const generateBlobUrl = async () => {
      if (!memoizedPdfDocument) {
        setPdfBlobUrl(null);
        setLoading(false);
        return;
      }
      setLoading(true);
      const blob = await pdf(memoizedPdfDocument).toBlob();
      if (isMounted) {
        url = URL.createObjectURL(blob);
        setPdfBlobUrl(url);
        setLoading(false);
      }
    };

    generateBlobUrl();

    // Función de limpieza
    return () => {
      isMounted = false;
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [memoizedPdfDocument]);

  const handlePrint = () => {
    setPdfVisible(true);
  };

  const handleClosePDF = () => {
    setPdfVisible(false);
  };

  if (pdfVisible) {
    return (
      <div style={styles.pdfContainer}>
        <button
          className="cerrar-button"
          style={styles.closeButton}
          onClick={handleClosePDF}
        >
          <i className="bi bi-x-lg"></i>
        </button>
        <PDFViewer className="pdf-viewer" style={styles.pdfViewer}>
          {memoizedPdfDocument}
        </PDFViewer>
      </div>
    );
  }

  const handleEdit = () => {
    setEditMode(true);
    setShowSummary(false);
  };

  const closeModal = () => {
    setEditMode(false);
    setShowSummary(true); // Mostramos el resumen al cerrar el formulario de edición
    onClose();
  };

  const obtenerIconoArchivo = (nombreArchivo) => {
    const extension = nombreArchivo.split(".").pop().toLowerCase();
    switch (extension) {
      case "xls":
      case "xlsx":
      case "csv":
        return { icon: "bi-file-earmark-excel", color: "green" };
      case "doc":
      case "docx":
        return { icon: "bi-file-earmark-word", color: "blue" };
      case "pdf":
        return { icon: "bi-file-earmark-pdf", color: "red" };
      case "jpg":
      case "jpeg":
      case "png":
      case "svg":
        return { icon: "bi-image", color: "blue" };
      default:
        return { icon: "bi-file-earmark", color: "gray" }; // Ícono genérico
    }
  };
  if (editMode) {
    return (
      <EditarCotizacionForm
        cotizacion={cotizacion}
        clientes={clientes}
        productos={cotizacion && cotizacion.productosSeleccionados}
        setCotizaciones={setCotizaciones}
        cotizaciones={cotizaciones}
        onClose={closeModal}
      />
    );
  }

  // Si el resumen debe mostrarse y la cotización y los productos están disponibles
  if (showSummary && cotizacion && cotizacion.productosSeleccionados) {
    return (
      <div className="resumen-cotizacion-container">
        <button className="cerrarBotonResumen" onClick={onClose}>
          <i className="bi bi-x-lg"></i>
        </button>
        <div className="resumen-cotizacion-actions">
          <div
            className="dropdown"
            onMouseEnter={() => setShowOptions(true)}
            onMouseLeave={() => setShowOptions(false)}
          >
            <button className="dropbtn">
              <i className="bi bi-printer iconResumen"></i> Imprimir / PDF
            </button>
            {showOptions && (
              <div className="dropdown-content">
                <button onClick={handlePrint}>
                  <i
                    className="bi bi-file-earmark-text"
                    style={{ marginRight: "10px" }}
                  ></i>
                  Imprimir
                </button>
                <a
                  href={pdfBlobUrl}
                  download={`${cotizacion.numeroCotizacion || "sin_numero"}_${
                    cotizacion.asunto || "sin_asunto"
                  }.pdf`}
                  style={{ textDecoration: "none" }}
                >
                  <button>
                    <i
                      className="bi bi-download"
                      style={{ marginRight: "10px" }}
                    ></i>
                    Descargar PDF
                  </button>
                </a>
              </div>
            )}
          </div>
          <button>
            <i className="bi bi-envelope-fill iconResumen"></i> Correo
          </button>
          <button onClick={handleEdit}>
            <i className="bi bi-pencil-fill iconResumen"></i> Editar
          </button>
          <button>
            <i className="bi bi-receipt iconResumen"></i> Convertir en Factura
          </button>
          {cotizacion.archivos && cotizacion.archivos.length > 0 && (
            <button onClick={() => setShowAdjuntosModal(true)}>
              <i className="bi bi-paperclip iconResumen"></i> Archivos Adjuntos
            </button>
          )}
        </div>

        <Modal
          isOpen={showAdjuntosModal}
          onRequestClose={() => setShowAdjuntosModal(false)}
          contentLabel="Archivos Adjuntos"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "40%", // Tamaño del modal ajustado
              maxHeight: "80vh",
              overflowY: "auto",
              padding: "20px",
              borderRadius: "10px",
              position: "relative", // Importante para la "X" flotante
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          {/* Botón "X" para cerrar */}
          <button
            onClick={() => setShowAdjuntosModal(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              fontSize: "1.5rem",
              color: "#000",
              cursor: "pointer",
            }}
            title="Cerrar"
          >
            <i className="bi bi-x"></i>
          </button>

          {/* Título */}
          <h2
            style={{ marginBottom: "20px", fontSize: "1.5rem", color: "#000" }}
          >
            Archivos Adjuntos
          </h2>

          {/* Lista de Archivos Adjuntos */}
          <ul style={{ listStyle: "none", padding: 0 }}>
            {adjuntos.map((archivo, index) => {
              const { icon, color } = obtenerIconoArchivo(archivo.nombre);
              return (
                <li
                  key={index}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                  }}
                >
                  {/* Información del Archivo */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i
                      className={`bi ${icon}`}
                      style={{
                        color: color,
                        fontSize: "1.5rem",
                        marginRight: "10px",
                      }}
                    ></i>
                    <a
                      href={archivo.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      {archivo.nombre}
                    </a>
                  </div>

                  {/* Acciones */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <a href={archivo.url} download={archivo.nombre}>
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          color: "#007bff",
                          cursor: "pointer",
                          fontSize: "20px",
                        }}
                        title="Descargar archivo"
                      >
                        <i className="bi bi-download"></i>
                      </button>
                    </a>
                    <button
                      onClick={() => handleDeleteArchivo(archivo)}
                      style={{
                        background: "none",
                        border: "none",
                        color: "#dc3545",
                        cursor: "pointer",
                        marginRight: "10px",
                        fontSize: "20px",
                      }}
                      title="Eliminar archivo"
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        </Modal>

        <div
          style={{
            height: "600px",
            width: "100%",
            marginTop: "-20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <div className="loader2"></div> // Spinner visible mientras se carga
          ) : (
            pdfBlobUrl && (
              <iframe
                src={`${pdfBlobUrl}#toolbar=0`}
                style={{ width: "100%", height: "100%" }}
                frameBorder="0"
                title="Vista previa del PDF"
              />
            )
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default ResumenCotizacion;