// Nav.js
import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SearchBar from "./SearchBar";
import Notificaciones from "./Notificaciones";
import { updateProfile } from "firebase/auth";
import FullScreen from "./FullScreen";
import { useAuth } from "./AuthContext";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebaseConfig";

const resizeImage = (file) => {
  // Tu función de redimensionamiento permanece igual
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 800;

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Error al redimensionar la imagen"));
            }
          },
          "image/jpeg",
          0.7
        );
      };

      img.onerror = () => {
        reject(new Error("Error al cargar la imagen"));
      };
    };
    reader.onerror = (error) => reject(error);
  });
};

const Nav = ({ proximasAVencer, proximosEventos, handleSearch }) => {
  const {
    user,
    logout,
    userRole,
    avatarUrl,
    firstName,
    lastName,
    setAvatarUrl,
  } = useAuth();

  const [menuVisible, setMenuVisible] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [crop, setCrop] = useState({ x: 50, y: 50, width: 300, height: 300 });
  const [uploading, setUploading] = useState(false);
  const cropBoxRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleSearchWidgetClick = () => {
    setSearchOpen(!searchOpen);
  };

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!file.type.startsWith("image/")) {
      Swal.fire({
        icon: "error",
        title: "Archivo no válido",
        text: "Por favor, selecciona un archivo de imagen.",
        confirmButtonColor: "#007bff",
      });
      return;
    }

    setSelectedFile(file);
    setCropModalOpen(true);
  };

  const handleCropMouseDown = (e) => {
    e.preventDefault();
    const startX = e.clientX;
    const startY = e.clientY;
    const initialCrop = { ...crop };

    const handleMouseMove = (moveEvent) => {
      const diffX = moveEvent.clientX - startX;
      const diffY = moveEvent.clientY - startY;

      setCrop((prevCrop) => ({
        ...prevCrop,
        x: Math.max(0, Math.min(initialCrop.x + diffX, 800 - prevCrop.width)),
        y: Math.max(0, Math.min(initialCrop.y + diffY, 800 - prevCrop.height)),
      }));
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleConfirmImage = async () => {
    if (!selectedFile) return;

    setUploading(true);

    try {
      const resizedFile = await resizeImage(selectedFile);
      const storageRef = ref(
        storage,
        `avatars/${user.uid}/${selectedFile.name}`
      );

      const uploadTask = uploadBytesResumable(storageRef, resizedFile);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Error al subir la imagen:", error);
          setUploading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Hubo un problema al subir la imagen. Por favor, intenta nuevamente.",
            confirmButtonColor: "#007bff",
          });
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          // Actualizar avatar en AuthContext y Firestore
          await updateProfile(user, { photoURL: downloadURL });
          await setAvatarUrl(downloadURL); // Llama a setAvatarUrl desde el contexto

          setCropModalOpen(false);
          setUploading(false);

          Swal.fire({
            icon: "success",
            title: "Imagen subida correctamente",
            text: "Tu imagen se ha subido y guardado exitosamente",
            confirmButtonColor: "#007bff",
          });
        }
      );
    } catch (error) {
      console.error("Error al procesar la imagen:", error);
      setUploading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al procesar la imagen. Por favor, intenta nuevamente.",
        confirmButtonColor: "#007bff",
      });
    }
  };

  const pageTitles = {
    "/inicio": "Dashboard",
    "/tareas": "Tareas",
    "/calendario": "Calendario",
    "/cotizacion": "Cotizaciones",
    "/remisiones": "Remisiones",
    "/orden": "Órdenes de Compra",
    "/articulos-venta": "Artículos de venta",
    "/articulos-compra": "Artículos de compra",
    "/todos": "Contactos",
    "/clientes": "Clientes",
    "/proveedores": "Proveedores",
    "/usuarios": "Usuarios",
  };

  const currentTitle = pageTitles[location.pathname] || "Dashboard";

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        padding: "5px",
        zIndex: 100,
        boxShadow: "0 1px 5px rgba(0,0,0,0.1)",
        backgroundColor: "#FFF",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px 0",
        }}
      >
        <div style={{ marginLeft: "10px", flex: 1 }}>
          <h3
            style={{
              fontWeight: "bold",
              margin: 0,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              letterSpacing: "0.5px",
              color: "#333",
            }}
          >
            {currentTitle}
          </h3>
        </div>
        <div style={{ position: "relative", marginRight: "20px" }}>
          <div
            className={`search-widget ${searchOpen ? "open" : ""}`}
            onClick={handleSearchWidgetClick}
          >
            <i className="bi bi-search"></i>
          </div>
          <div className={`search-input-wrapper ${searchOpen ? "open" : ""}`}>
            <SearchBar handleSearch={handleSearch} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "30px",
            marginRight: "20px",
          }}
        >
          <Notificaciones
            proximasAVencer={proximasAVencer}
            proximosEventos={proximosEventos}
          />
          <i
            className="bi bi-moon"
            style={{ cursor: "pointer", fontSize: "1.5rem" }}
          ></i>
          <FullScreen />
          <div style={{ position: "relative" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f7f9f9",
                width: "200px",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <label htmlFor="avatarUpload" style={{ cursor: "pointer" }}>
                {avatarUrl ? (
                  <img
                    src={avatarUrl}
                    alt="Avatar"
                    style={{
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <img
                    src="/img/avatar2.jpg"
                    alt="Avatar por defecto"
                    style={{
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </label>
              <input
                id="avatarUpload"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
              <div style={{ margin: "0 auto" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {firstName} {lastName}
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {userRole}
                </div>
              </div>
              <i
                className="bi bi-chevron-down"
                onClick={handleClick}
                style={{ fontSize: "1rem", cursor: "pointer" }}
              ></i>
            </div>
            {menuVisible && (
              <div
                style={{
                  position: "absolute",
                  top: "60px",
                  right: "0",
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  zIndex: 1000,
                }}
              >
                <div
                  onClick={handleLogout}
                  className="logout-container"
                  style={{
                    padding: "10px 20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span className="logout-icon" style={{ marginRight: "10px" }}>
                    <i className="bi bi-box-arrow-right"></i>
                  </span>
                  <span className="logout-text">Cerrar sesión</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {cropModalOpen && (
        <div
          className="crop-container"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="crop-modal"
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "10px",
              position: "relative",
              width: "400px",
              maxWidth: "90%",
            }}
          >
            <div
              style={{ position: "relative", width: "100%", height: "auto" }}
            >
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Cropped Preview"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  userSelect: "none",
                }}
              />
              <div
                ref={cropBoxRef}
                style={{
                  position: "absolute",
                  border: "2px solid blue",
                  width: `${crop.width}px`,
                  height: `${crop.height}px`,
                  left: `${crop.x}px`,
                  top: `${crop.y}px`,
                  cursor: "move",
                }}
                onMouseDown={handleCropMouseDown}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <button
                onClick={() => setCropModalOpen(false)}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#6c757d",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Cancelar
              </button>
              <button
                onClick={handleConfirmImage}
                disabled={uploading}
                style={{
                  padding: "10px 20px",
                  backgroundColor: uploading ? "#999" : "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: uploading ? "not-allowed" : "pointer",
                }}
              >
                {uploading ? "Subiendo..." : "OK"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nav;