// context/UsuariosContext.js
import React, { createContext, useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, onSnapshot } from 'firebase/firestore';

export const UsuariosContext = createContext();

export const UsuariosProvider = ({ children }) => {
  // Inicializar el estado con los datos de localStorage
  const [usuarios, setUsuarios] = useState(() => {
    const storedUsuarios = localStorage.getItem('usuarios');
    return storedUsuarios ? JSON.parse(storedUsuarios) : [];
  });

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'usuarios'), (snapshot) => {
      const usuariosData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsuarios(usuariosData);
      // Actualizar localStorage con los nuevos datos
      localStorage.setItem('usuarios', JSON.stringify(usuariosData));
    });

    return () => unsubscribe();
  }, []);

  return (
    <UsuariosContext.Provider value={usuarios}>
      {children}
    </UsuariosContext.Provider>
  );
};
