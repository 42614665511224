import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  collection,
  getDocs,
  serverTimestamp,
  updateDoc,
  doc,
  getFirestore,
  addDoc,
  onSnapshot,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebaseConfig";
import TablaCotizaciones from "./TablaCotizaciones";
import Nav from "./Nav";
import Sidebar from "./Sidebar";
import Modal from "react-modal";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

moment.locale("es");
registerLocale("es", es);
// Establece la función de inicialización de react-modal para evitar un aviso de desenfoque de accesibilidad
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    maxWidth: "800px",
    width: "100%",
    height: "500px",
    maxHeight: "90vh",
    overflow: "auto",
    fontFamily: "Roboto, sans-serif", // Aplica la fuente Roboto
    "::WebkitScrollbar": {
      width: "none",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
  label: {
    display: "block",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  button: {
    width: "50%", // Centra el botón de agregar
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    fontWeight: "700",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "500px",
    padding: "20px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    border: "none",
    fontFamily: "Roboto, sans-serif",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  h2: {
    fontSize: "1.5rem",
    color: "#333",
    marginBottom: "20px",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  label: {
    fontSize: "1rem",
    color: "#666",
    marginBottom: "5px",
  },
  input: {
    padding: "10px",
    fontSize: "1rem",
    borderRadius: "5px",
    border: "1px solid #ddd",
    outline: "none",
    transition: "border-color 0.3s ease",
  },
  inputFocus: {
    borderColor: "#007bff",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    borderRadius: "5px",
    border: "none",
    color: "#fff",
    backgroundColor: "#007bff",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    flex: "1", // Make buttons take equal space
    margin: "0 5px", // Space between buttons
  },
  buttonCancel: {
    backgroundColor: "#6c757d",
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
  buttonCancelHover: {
    backgroundColor: "#5a6268",
  },
};

const modalNoCotizacion = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "500px",
    padding: "20px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    border: "none",
    fontFamily: "Roboto, sans-serif",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  h2: {
    fontSize: "20px",
    color: "#333",
    marginBottom: "15px",
    textAlign: "left",
  },
  description: {
    fontSize: "14px",
    color: "#666",
    marginBottom: "20px",
    textAlign: "justify",
    lineHeight: "1.5",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  radioLabel: {
    fontSize: "1rem",
    color: "#666",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  infoIcon: {
    fontSize: "1rem",
    color: "#007bff",
  },
  inputGroup: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginLeft: "45px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  inlineLabel: {
    fontSize: "0.9rem",
    color: "#666",
  },
  smallInput: {
    width: "100px",
    padding: "5px",
    fontSize: "0.9rem",
    borderRadius: "5px",
    border: "1px solid #ddd",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center", // Alinea los botones a la izquierda
    marginTop: "20px",
    gap: "10px", // Espaciado entre botones
  },
  button: {
    padding: "8px 15px", // Reduce el tamaño de los botones
    fontSize: "0.9rem", // Tamaño de texto más pequeño
    borderRadius: "5px",
    border: "none",
    color: "#fff",
    backgroundColor: "#007bff",
    cursor: "pointer",
  },
  buttonCancel: {
    backgroundColor: "#6c757d",
  },
};

function CotizacionForm(props) {
  const [numeroCotizacion, setNumeroCotizacion] = useState("");
  const [cliente, setCliente] = useState("");
  const [clientes, setClientes] = useState([]);
  const [productos, setProductos] = useState([]);
  const [asunto, setAsunto] = useState("");
  const [fechaCotizacion, setFechaCotizacion] = useState(new Date());
  const [fechaVencimiento, setFechaVencimiento] = useState(null);
  const [atencion, setAtencion] = useState("");
  const [planta, setPlanta] = useState("");
  const [divisa, setDivisa] = useState("MXN");
  const [area, setArea] = useState("");
  const [referencia, setReferencia] = useState("");
  const [terminos, setTerminos] = useState("");
  const [notas, setNotas] = useState("");
  const [vendedores, setVendedores] = useState([]);
  const [vendedorNombre, setVendedorNombre] = useState("");
  const [vendedorCorreo, setVendedorCorreo] = useState("");
  const [nuevoVendedorNombre, setNuevoVendedorNombre] = useState("");
  const [nuevoVendedorCorreo, setNuevoVendedorCorreo] = useState("");
  const [modalVendedorIsOpen, setModalVendedorIsOpen] = useState(false);
  const [datosSeleccionados, setDatosSeleccionados] = useState([
    {
      id: uuidv4(),
      nombre: "",
      modelo: "",
      cantidad: 1,
      tarifa: 0,
      iva: 0,
      descuento: 0, // Nuevo campo
      tipoDescuento: "%", // Nuevo campo
      importe: 0,
    },
  ]);
  const [descuento, setDescuento] = useState(0);
  const [productosSeleccionados, setProductosSeleccionados] = useState([]);
  const [mostrarTabla, setMostrarTabla] = useState(false);
  const [mostrarPrevia, setMostrarPrevia] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { cotizacion, cotizaciones, setCotizaciones, onFormDirtyChange } =
    props;
  const [modoEdicion, setModoEdicion] = useState(false);
  const [proximasAVencer, setProximasAVencer] = useState([]);
  const [proximosEventos, setProximosEventos] = useState([]);
  const [formDirty, setFormDirty] = useState(false);
  const [events, setEvents] = useState([]); // Array para los eventos del calendario
  const [archivos, setArchivos] = useState([]);
  const [modalConfigIsOpen, setModalConfigIsOpen] = useState(false);
  const [prefijo, setPrefijo] = useState("IC-");
  const [siguienteNumero, setSiguienteNumero] = useState("");
  const [modoNumeroCotizacion, setModoNumeroCotizacion] = useState("auto"); // Valores: "auto" o "manual"

  const handleModoCambio = (e) => {
    const nuevoModo = e.target.value;
    setModoNumeroCotizacion(nuevoModo);

    if (nuevoModo === "auto") {
      setNumeroCotizacion(`${prefijo}${siguienteNumero}`);
      // Alerta para modo automático
      Swal.fire({
        icon: "success",
        title: "Modo Automático Activado",
        text: "Ahora los números de cotización se actualizarán automáticamente.",
        confirmButtonText: "Entendido",
      });
    } else {
      setNumeroCotizacion(""); // Permitir al usuario escribir manualmente
      // Alerta para modo manual
      Swal.fire({
        icon: "info",
        title: "Modo Manual Activado",
        text: "Ahora puedes agregar manualmente un número de cotización.",
        confirmButtonText: "Entendido",
      });
    }
  };

  const fetchNumeroCotizacion = async () => {
    try {
      const docRef = doc(db, "configuracion", "numeroCotizacion");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setPrefijo(data.prefijo || "IC-");
        setSiguienteNumero(data.siguienteNumero || ""); // Usar un valor predeterminado lógico
      } else {
        // Si no existe, inicializa con valores predeterminados
        await setDoc(docRef, { prefijo: "IC-", siguienteNumero: "" });
        setPrefijo("IC-");
        setSiguienteNumero("");
      }
    } catch (error) {
      console.error("Error al obtener número de cotización:", error);
    }
  };

  useEffect(() => {
    setNumeroCotizacion(`${prefijo}${siguienteNumero}`);
  }, [siguienteNumero, prefijo]);

  const actualizarNumeroCotizacion = async (nuevoNumero) => {
    try {
      const docRef = doc(db, "configuracion", "numeroCotizacion");
      await setDoc(
        docRef,
        { prefijo, siguienteNumero: nuevoNumero },
        { merge: true }
      );
    } catch (error) {
      console.error("Error al actualizar número de cotización:", error);
    }
  };

  const guardarConfiguracionNumeroCotizacion = async () => {
    try {
      await actualizarNumeroCotizacion(parseInt(siguienteNumero, 10));
      setModalConfigIsOpen(false);

      // Mostrar mensaje de éxito
      Swal.fire({
        icon: "success",
        title: "Configuración actualizada",
        text: "La configuración del número de cotización se ha actualizado correctamente.",
        timer: 2000, // Tiempo en milisegundos antes de que desaparezca
        showConfirmButton: false, // Ocultar el botón de confirmación
      });
    } catch (error) {
      console.error("Error al guardar configuración:", error);

      // Mostrar mensaje de error
      Swal.fire({
        icon: "error",
        title: "Error al guardar",
        text: "Hubo un problema al guardar la configuración. Por favor, inténtalo de nuevo.",
      });
    }
  };

  const handleDescargarArchivo = (url, nombre) => {
    try {
      const link = document.createElement("a");
      link.href = url;
      link.download = nombre; // Establecer nombre para la descarga
      link.target = "_self"; // Forzar la descarga en lugar de abrir en otra pestaña
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Limpiar el DOM después de la descarga
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error al descargar",
        text: `No se pudo descargar el archivo ${nombre}.`,
      });
      console.error("Error al descargar el archivo:", error);
    }
  };

  useEffect(() => {
    fetchNumeroCotizacion();
  }, []);

  const obtenerIconoArchivo = (extension) => {
    switch (extension) {
      case "xls":
      case "xlsx":
      case "csv":
        return { icon: "bi-file-earmark-excel", color: "green" };
      case "doc":
      case "docx":
        return { icon: "bi-file-earmark-word", color: "blue" };
      case "pdf":
        return { icon: "bi-file-earmark-pdf", color: "red" };
      case "jpg":
      case "jpeg":
      case "png":
      case "svg":
        return { icon: "bi-image", color: "blue" };
      default:
        return { icon: "bi-file-earmark", color: "gray" }; // Ícono genérico
    }
  };

  const eliminarArchivo = (nombreArchivo) => {
    Swal.fire({
      title: "¿Eliminar archivo?",
      text: `El archivo ${nombreArchivo} será eliminado.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setArchivos((prevArchivos) =>
          prevArchivos.filter((archivo) => archivo.nombre !== nombreArchivo)
        );
        Swal.fire("¡Eliminado!", "El archivo ha sido eliminado.", "success");
      }
    });
  };

  const subirArchivosYObtenerURLs = async (nuevosArchivos) => {
    const storage = getStorage();
    const urlsArchivos = [];

    for (const archivo of nuevosArchivos) {
      const archivoRef = ref(storage, `cotizaciones/${archivo.name}`);
      await uploadBytes(archivoRef, archivo); // Sube el archivo al almacenamiento
      const url = await getDownloadURL(archivoRef); // Obtén la URL de descarga
      urlsArchivos.push(url);
    }

    return urlsArchivos;
  };

  const manejarArchivos = async (event) => {
    const nuevosArchivos = Array.from(event.target.files);

    // Filtra archivos duplicados
    const archivosSinDuplicados = nuevosArchivos.filter(
      (archivo) => !archivos.some((a) => a.nombre === archivo.name)
    );

    if (archivosSinDuplicados.length === 0) {
      Swal.fire({
        icon: "info",
        title: "Archivos duplicados",
        text: "Los archivos ya están agregados.",
      });
      return;
    }

    // Verifica si se excede el límite de 10 archivos
    const totalArchivos = archivos.length + archivosSinDuplicados.length;
    if (totalArchivos > 10) {
      Swal.fire({
        icon: "error",
        title: "Se excedió el número de archivos",
        text: "Solo se permiten un máximo de 10 archivos.",
      });
      return;
    }

    // Subir archivos y obtener URLs
    const urlsNuevas = await subirArchivosYObtenerURLs(archivosSinDuplicados);

    // Actualiza el estado con los nuevos archivos (sin duplicados)
    setArchivos((prevArchivos) => [
      ...prevArchivos,
      ...urlsNuevas.map((url, index) => ({
        nombre: archivosSinDuplicados[index].name, // Usa el nombre real del archivo
        url,
      })),
    ]);

    setFormDirty(true);
  };

  useEffect(() => {
    if (onFormDirtyChange) {
      onFormDirtyChange(formDirty);
    }
  }, [formDirty, onFormDirtyChange]);

  useEffect(() => {
    // Definir obtenerNumeroCotizacion dentro del componente para que no sea una dependencia externa
    const obtenerNumeroCotizacion = () => {
      setNumeroCotizacion(`${prefijo}${siguienteNumero}`);
    };

    if (cotizacion) {
      setModoEdicion(true);
      // Llenar el formulario con los datos de la cotización, incluyendo el número de cotización
      setArchivos(cotizacion.archivos || []);
      setCliente(cotizacion.cliente || "");
      setReferencia(cotizacion.referencia || "");
      setFechaCotizacion(
        cotizacion.fechaCotizacion
          ? new Date(cotizacion.fechaCotizacion)
          : new Date()
      );
      setFechaVencimiento(
        cotizacion.fechaVencimiento
          ? new Date(cotizacion.fechaVencimiento)
          : null
      );
      setVendedorNombre(cotizacion.vendedorNombre || "");
      setVendedorCorreo(cotizacion.vendedorCorreo || "");
      setVendedores((prevVendedores) => {
        const vendedorExiste = prevVendedores.some(
          (vendedor) => vendedor.nombre === cotizacion.vendedorNombre
        );
        if (!vendedorExiste && cotizacion.vendedorNombre) {
          return [
            ...prevVendedores,
            {
              nombre: cotizacion.vendedorNombre,
              correo: cotizacion.vendedorCorreo,
            },
          ];
        }
        return prevVendedores;
      });
      setDatosSeleccionados(
        cotizacion.productosSeleccionados
          ? cotizacion.productosSeleccionados.map((producto) => ({
              id: uuidv4(), // Asigna un ID único
              ...producto,
              numeroDeParte: producto.numeroDeParte || "",
              nombre: producto.nombre || "",
              cantidad: producto.cantidad || 1,
              tarifa: producto.tarifa || 0,
              iva: producto.iva || 0,
              importe: producto.importe || 0,
            }))
          : []
      );
      setAtencion(cotizacion.atencion || "");
      setPlanta(cotizacion.planta || "");
      setArea(cotizacion.area || "");
      setAsunto(cotizacion.asunto || "");
      setDivisa(cotizacion.divisa || "");
      setProductosSeleccionados(cotizacion.productosSeleccionados || []);
      setDatosSeleccionados(cotizacion.productosSeleccionados || []);
      setDescuento(parseFloat(cotizacion.descuento) || 0);
      setNumeroCotizacion(cotizacion.numeroCotizacion || "");
      setNotas(cotizacion.notas || "");
      setTerminos(cotizacion.terminos || "");
      // Resto de los campos...
    } else {
      obtenerNumeroCotizacion();
    }
  }, [cotizacion, prefijo, siguienteNumero]); // Agregar las dependencias necesarias

  // Función para cargar productos reales desde Firestore
  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const productosSnapshot = await getDocs(collection(db, "productos"));
        const productosList = productosSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProductos(productosList);
      } catch (error) {
        console.error("Error al cargar los productos desde Firestore", error);
      }
    };

    fetchProductos();
  }, []);

  const handleCancel = () => {
    if (formDirty) {
      Swal.fire({
        title: "¿Seguro que quieres salir?",
        text: "Se perderán los cambios realizados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, salir",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          // Recargar la página al confirmar la salida
          window.location.reload();
        }
      });
    } else {
      // Si no hay cambios, salir sin confirmación
      window.location.reload(); // Recargar la página directamente si no hay cambios
    }
  };

  const handleInputChange = (e, productoId) => {
    const inputValue = e.target.value;
    setFormDirty(true); // Marcar que el formulario ha sido modificado

    // Encuentra el producto en la lista de productos reales desde Firestore
    const productoEncontrado = productos.find(
      (prod) => prod.nombre === inputValue
    );

    if (productoEncontrado) {
      setDatosSeleccionados((prevDatos) => {
        // Verifica si el producto ya existe en otra fila distinta a la que se está editando
        const existingItem = prevDatos.find(
          (item) =>
            item.nombre === productoEncontrado.nombre && item.id !== productoId
        );

        if (existingItem) {
          // Si el producto ya existe, sumamos las cantidades
          const currentItem = prevDatos.find((item) => item.id === productoId);
          const nuevaCantidad =
            (existingItem.cantidad || 0) + (currentItem.cantidad || 1);

          // Actualizamos el producto existente con la nueva cantidad e importe
          const nuevosDatos = prevDatos.map((item) => {
            if (item.id === existingItem.id) {
              return {
                ...item,
                cantidad: nuevaCantidad,
                importe: productoEncontrado.costo * nuevaCantidad,
              };
            }
            return item;
          });

          // Eliminamos la fila actual (para no duplicar)
          return nuevosDatos.filter((item) => item.id !== productoId);
        } else {
          // Si el producto no existe en otra fila, simplemente actualizamos la fila actual
          return prevDatos.map((item) =>
            item.id === productoId
              ? {
                  ...item,
                  nombre: productoEncontrado.nombre,
                  descripcion: productoEncontrado["Item Desc"] || "",
                  numeroDeParte: productoEncontrado.numeroDeParte,
                  tarifa: productoEncontrado.costo,
                  iva: 0.16,
                  importe: productoEncontrado.costo * item.cantidad,
                }
              : item
          );
        }
      });
    } else {
      // Producto ingresado manualmente (no encontrado en Firestore)
      setDatosSeleccionados((prevDatos) => {
        // Verificar si ya existe un producto con el mismo nombre manualmente
        const existingManualItem = prevDatos.find(
          (item) => item.nombre === inputValue && item.id !== productoId
        );

        if (existingManualItem) {
          // Si ya existe el producto manual, sumamos las cantidades
          const currentItem = prevDatos.find((item) => item.id === productoId);
          const nuevaCantidad =
            (existingManualItem.cantidad || 0) + (currentItem.cantidad || 1);

          // Actualizamos el item existente con la nueva cantidad
          const nuevosDatos = prevDatos.map((item) => {
            if (item.id === existingManualItem.id) {
              return {
                ...item,
                cantidad: nuevaCantidad,
                // Suponemos que el item existente ya tiene una tarifa definida (manual).
                importe: (item.tarifa || 0) * nuevaCantidad,
              };
            }
            return item;
          });

          // Eliminamos la fila actual (para no duplicar)
          return nuevosDatos.filter((item) => item.id !== productoId);
        } else {
          // Si no existe ningún otro producto manual con ese nombre, solo actualizamos el campo nombre
          return prevDatos.map((item) =>
            item.id === productoId ? { ...item, nombre: inputValue } : item
          );
        }
      });
    }
  };

  useEffect(() => {
    const fetchCotizaciones = async () => {
      const firestore = getFirestore();
      const cotizacionesRef = collection(firestore, "cotizaciones");
      const unsubscribeCotizaciones = onSnapshot(
        cotizacionesRef,
        (snapshot) => {
          const cotizaciones = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Filtrar y ordenar cotizaciones
          const proximas = cotizaciones
            .filter((cotizacion) =>
              moment(cotizacion.fechaVencimiento).isSameOrAfter(
                moment().startOf("day")
              )
            )
            .sort(
              (a, b) => moment(a.fechaVencimiento) - moment(b.fechaVencimiento)
            );

          setProximasAVencer(proximas.slice(0, 6)); // Limitar la lista a 6 fechas próximas

          // Crear eventos para cotizaciones
          const cotizacionesEvents = proximas.map((cotizacion) => ({
            id: `cotizacion-${cotizacion.id}`,
            title: `Cotización #${cotizacion.numeroCotizacion}`,
            start: moment(cotizacion.fechaVencimiento).startOf("day").toDate(),
            end: moment(cotizacion.fechaVencimiento).endOf("day").toDate(),
            allDay: true,
            resource: "cotizacion",
            style: {
              backgroundColor: "blue",
              padding: "auto",
              fontSize: "12px",
              width: "80%",
              margin: "0 auto",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "auto",
            },
          }));

          // Actualizar el estado de eventos
          setEvents((prevEvents) => {
            const eventMap = new Map(
              prevEvents
                .filter((event) => !event.id.startsWith("cotizacion-"))
                .map((event) => [event.id, event])
            );
            cotizacionesEvents.forEach((event) =>
              eventMap.set(event.id, event)
            );
            return Array.from(eventMap.values());
          });
        }
      );

      return () => unsubscribeCotizaciones();
    };

    const fetchEventos = async () => {
      const firestore = getFirestore();
      const eventosRef = collection(firestore, "eventos");
      const unsubscribeEventos = onSnapshot(eventosRef, (snapshot) => {
        const eventos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filtrar y ordenar eventos
        const proximos = eventos
          .filter((evento) =>
            moment(evento.to).isSameOrAfter(moment().startOf("day"))
          )
          .sort((a, b) => moment(a.to) - moment(b.to));

        setProximosEventos(proximos.slice(0, 6)); // Limitar la lista a 6 eventos próximos

        // Crear eventos para el calendario
        const eventosCalendario = proximos.map((evento) => ({
          id: `evento-${evento.id}`,
          title: evento.title,
          start: moment(evento.to).startOf("day").toDate(),
          end: moment(evento.to).endOf("day").toDate(),
          allDay: true,
          resource: "evento",
          style: {
            backgroundColor: "#229954",
            padding: "auto",
            fontSize: "12px",
            width: "80%",
            margin: "0 auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: "auto",
          },
        }));

        // Actualizar el estado de eventos
        setEvents((prevEvents) => {
          const eventMap = new Map(
            prevEvents
              .filter((event) => !event.id.startsWith("evento-"))
              .map((event) => [event.id, event])
          );
          eventosCalendario.forEach((event) => eventMap.set(event.id, event));
          return Array.from(eventMap.values());
        });
      });

      return () => unsubscribeEventos();
    };

    // Obtener fechas festivas
    const getFechasFestivas = () => {
      const fechasFestivasBase = [
        { title: "Año Nuevo", month: "01", day: "01", color: "#de2e03" },
        {
          title: "Día de la Constitución",
          month: "02",
          day: "05",
          color: "#de2e03",
        },
        {
          title: "Natalicio de Benito Juárez",
          month: "03",
          day: "21",
          color: "#de2e03",
        },
        { title: "Día del Trabajo", month: "05", day: "01", color: "#de2e03" },
        {
          title: "Independencia de México",
          month: "09",
          day: "16",
          color: "#de2e03",
        },
        {
          title: "Transición del Poder Ejecutivo",
          month: "10",
          day: "01",
          color: "#de2e03",
        },
        {
          title: "Revolución Mexicana",
          month: "11",
          day: "20",
          color: "#de2e03",
        },
        { title: "Navidad", month: "12", day: "25", color: "#de2e03" },
      ];

      const generarFechasFestivas = (años) => {
        const fechasFestivas = [];
        años.forEach((año) => {
          fechasFestivasBase.forEach((festivo) => {
            const fecha = moment(
              `${año}-${festivo.month}-${festivo.day}`,
              "YYYY-MM-DD"
            ).toDate();
            fechasFestivas.push({
              id: `festivo-${festivo.title}-${año}`,
              title: festivo.title,
              start: fecha,
              end: fecha,
              allDay: true,
              resource: "festivo",
              style: {
                backgroundColor: "#de2e03",
                padding: "auto",
                fontSize: "12px",
                width: "80%",
                margin: "0 auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "auto",
              },
            });
          });
        });
        return fechasFestivas;
      };

      const años = Array.from(
        { length: 6 },
        (_, i) => new Date().getFullYear() + i
      );
      return generarFechasFestivas(años);
    };

    // Obtener fechas festivas y fusionarlas con los eventos existentes
    const fechasFestivas = getFechasFestivas();
    setEvents((prevEvents) => [
      ...prevEvents.filter((event) => !event.id.startsWith("festivo-")),
      ...fechasFestivas,
    ]);

    // Ejecutar las funciones de carga de cotizaciones y eventos
    fetchCotizaciones();
    fetchEventos();
  }, []);

  useEffect(() => {
    obtenerClientes();
    obtenerProductos();
  }, []);

  const handleSelectChange = (e) => {
    if (e.target.value === "agregar-nuevo") {
      setVendedorNombre("");
      setVendedorCorreo("");
      openModalVendedor();
    } else {
      const selectedVendedor = vendedores.find(
        (vendedor) => vendedor.nombre === e.target.value
      );
      if (selectedVendedor) {
        setVendedorNombre(selectedVendedor.nombre);
        setVendedorCorreo(selectedVendedor.correo);
      } else {
        setVendedorNombre(e.target.value);
        setVendedorCorreo("");
      }
    }
  };

  const openModalVendedor = () => {
    if (vendedorNombre && vendedorNombre !== "agregar-nuevo") {
      // Editar vendedor existente
      setNuevoVendedorNombre(vendedorNombre);
      setNuevoVendedorCorreo(vendedorCorreo);
    } else {
      // Agregar nuevo vendedor
      setNuevoVendedorNombre("");
      setNuevoVendedorCorreo("");
    }
    setModalVendedorIsOpen(true);
  };

  const handleAgregarVendedor = () => {
    if (nuevoVendedorNombre.trim() !== "") {
      const existingVendedorIndex = vendedores.findIndex(
        (vendedor) => vendedor.nombre === vendedorNombre
      );

      if (existingVendedorIndex !== -1) {
        // Actualizar vendedor existente
        const updatedVendedor = {
          nombre: nuevoVendedorNombre,
          correo: nuevoVendedorCorreo,
        };

        const updatedVendedores = [...vendedores];
        updatedVendedores[existingVendedorIndex] = updatedVendedor;
        setVendedores(updatedVendedores);

        // Actualizar el vendedor actual
        setVendedorNombre(nuevoVendedorNombre);
        setVendedorCorreo(nuevoVendedorCorreo);

        Swal.fire({
          icon: "success",
          title: "Vendedor actualizado correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        // Agregar nuevo vendedor
        const nuevoVendedor = {
          nombre: nuevoVendedorNombre,
          correo: nuevoVendedorCorreo,
        };

        setVendedores([...vendedores, nuevoVendedor]);

        // Establecer el vendedor actual
        setVendedorNombre(nuevoVendedorNombre);
        setVendedorCorreo(nuevoVendedorCorreo);

        Swal.fire({
          icon: "success",
          title: "Vendedor guardado correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      // Limpiar y cerrar el modal
      setNuevoVendedorNombre("");
      setNuevoVendedorCorreo("");
      setModalVendedorIsOpen(false);
    }
  };

  const closeModalVendedor = () => {
    setModalVendedorIsOpen(false);
    setNuevoVendedorNombre("");
    setNuevoVendedorCorreo("");
  };

  const cerrarModalPrevia = () => {
    setMostrarPrevia(false);
    setModalIsOpen(false);
  };

  const calcularTotal = () => {
    const subtotalConDescuento = calcularSubtotalConDescuento();
    const ivaCalculado = calcularIVA();
    return subtotalConDescuento + ivaCalculado;
  };

  const obtenerIVA = () => {
    // Valor predeterminado de IVA
    const VALOR_IVA_DEFAULT = 0.16;

    // Retornar el IVA del primer elemento si está disponible; de lo contrario, usar el predeterminado
    if (datosSeleccionados.length > 0 && datosSeleccionados[0]?.iva) {
      return datosSeleccionados[0].iva;
    }

    return VALOR_IVA_DEFAULT;
  };

  const manejarCambioDescuento = (e) => {
    let valor = e.target.value;

    // Permitir solo valores numéricos y entre 0 y 100
    if (/^\d*\.?\d*$/.test(valor)) {
      let valorNumerico = parseFloat(valor);
      if (isNaN(valorNumerico)) {
        setDescuento(0);
      } else {
        if (valorNumerico > 100) valorNumerico = 100;
        setDescuento(valorNumerico);
      }
    }
  };

  const obtenerClientes = async () => {
    try {
      const clientesSnapshot = await getDocs(collection(db, "clientes"));
      const listaClientes = clientesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClientes(listaClientes);
    } catch (error) {
      console.error("Error al obtener clientes:", error);
    }
  };

  const obtenerProductos = async () => {
    try {
      const productosSnapshot = await getDocs(collection(db, "productos"));
      const listaProductos = productosSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        tipoDescuento: "cantidad",
        valorDescuento: 0,
      }));
      setProductos(listaProductos);
    } catch (error) {
      console.error("Error al obtener los productos:", error);
    }
  };

  const continuarDesdePrevia = () => {
    setMostrarTabla(true);
  };

  const calcularSubtotalConDescuento = () => {
    const subtotal = calcularSubtotal();
    return subtotal > 0 ? subtotal - (subtotal * descuento) / 100 : 0;
  };

  const eliminarProducto = (id) => {
    setDatosSeleccionados((prevDatos) => {
      const nuevosDatos = prevDatos.filter((producto) => producto.id !== id);
      return nuevosDatos.length > 0 ? nuevosDatos : [];
    });
  };

  const actualizarCantidad = (productoId, nuevaCantidad) => {
    setDatosSeleccionados((prevDatos) =>
      prevDatos.map((item) =>
        item.id === productoId
          ? {
              ...item,
              cantidad: nuevaCantidad,
              importe: item.tarifa * nuevaCantidad, // Solo calcula el importe sin aplicar IVA aquí
            }
          : item
      )
    );
  };

  const actualizarCotizacionExistente = async (
    cotizacionId,
    cotizacionData
  ) => {
    try {
      // Aquí debes realizar la lógica para actualizar la cotización existente en tu base de datos
      // Por ejemplo, podrías utilizar Firebase Firestore para actualizar el documento de la cotización

      // Primero, obtén la referencia al documento de la cotización que deseas actualizar
      const cotizacionRef = doc(db, "cotizaciones", cotizacionId);

      // Luego, utiliza la función `updateDoc` para actualizar los datos de la cotización
      await updateDoc(cotizacionRef, cotizacionData);

      // Si la actualización se realiza correctamente, no es necesario hacer nada más
    } catch (error) {
      // Manejo de errores
      console.error("Error al actualizar la cotización:", error);
      throw error; // Puedes lanzar el error para que sea manejado por la función `guardar`
    }
  };

  const guardar = async (e) => {
    e.preventDefault();

    // Verifica que los campos obligatorios estén llenos
    if (!cliente || !numeroCotizacion || !fechaCotizacion || !atencion) {
      Swal.fire({
        icon: "error",
        title: "Campos obligatorios faltantes",
        text: "Por favor completa los campos: Nombre del cliente, No. Cotización, Fecha de cotización, y Atención.",
      });
      return; // No continuar con la función si los campos requeridos no están completos
    }

    try {
      // Verificar si el número de cotización ya existe
      const cotizacionesSnapshot = await getDocs(
        collection(db, "cotizaciones")
      );
      const cotizacionExistente = cotizacionesSnapshot.docs.find(
        (doc) => doc.data().numeroCotizacion === numeroCotizacion
      );

      if (
        cotizacionExistente &&
        (!modoEdicion || cotizacion.id !== cotizacionExistente.id)
      ) {
        Swal.fire({
          icon: "error",
          title: "Número de cotización duplicado",
          text: `El número de cotización "${numeroCotizacion}" ya existe. Por favor, utiliza otro número.`,
        });
        return; // Detén la ejecución si el número ya existe
      }

      const clienteSeleccionado = clientes.find((c) => c.id === cliente);
      const nombreCliente = clienteSeleccionado?.empresa || "";

      let archivosURLs = [...archivos];
      if (archivos.length > 0) {
        const nuevosArchivos = archivos.filter((archivo) => !archivo.url);
        const urlsNuevosArchivos = await subirArchivosYObtenerURLs(
          nuevosArchivos
        );
        archivosURLs = [
          ...archivosURLs,
          ...urlsNuevosArchivos.map((archivo, index) => ({
            nombre: nuevosArchivos[index]?.name || archivo.nombre,
            url: archivo,
          })),
        ];
      }

      const cotizacionData = {
        cliente,
        numeroCotizacion,
        nombreCliente,
        referencia,
        fechaCotizacion: fechaCotizacion ? fechaCotizacion.toISOString() : null,
        fechaVencimiento: fechaVencimiento
          ? fechaVencimiento.toISOString()
          : null,
        vendedorNombre,
        vendedorCorreo,
        atencion,
        planta,
        area,
        divisa,
        asunto,
        productosSeleccionados: datosSeleccionados,
        total: calcularTotal(),
        notas,
        terminos,
        descuento,
        createdAt: serverTimestamp(),
        estado: "Pendiente",
        archivos: archivosURLs,
      };

      if (modoEdicion && cotizacion && cotizacion.id) {
        // Actualizar cotización existente
        await actualizarCotizacionExistente(cotizacion.id, cotizacionData);

        // Actualiza la lista de cotizaciones localmente
        if (typeof setCotizaciones === "function") {
          setCotizaciones((prev) =>
            prev.map((c) =>
              c.id === cotizacion.id
                ? { id: cotizacion.id, ...cotizacionData }
                : c
            )
          );
        }

        Swal.fire({
          icon: "success",
          title: "Cotización actualizada correctamente",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      } else {
        // Crear nueva cotización
        const docRef = await addDoc(
          collection(db, "cotizaciones"),
          cotizacionData
        );

        // Incrementar el número de cotización y actualizar Firestore
        const nuevoNumero = parseInt(siguienteNumero, 10) + 1;
        await actualizarNumeroCotizacion(nuevoNumero);
        setSiguienteNumero(nuevoNumero); // Actualiza localmente

        if (typeof setCotizaciones === "function") {
          setCotizaciones((prev) => [
            ...prev,
            { id: docRef.id, ...cotizacionData },
          ]);
        }

        Swal.fire({
          icon: "success",
          title: "Cotización guardada correctamente",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      console.error("Error al guardar la cotización:", error);
      Swal.fire({
        icon: "error",
        title: "Error al guardar la cotización",
        text: error.message,
      });
    }
  };

  const calcularIVA = () => {
    const subtotalConDescuento = calcularSubtotalConDescuento();
    return subtotalConDescuento > 0 ? subtotalConDescuento * obtenerIVA() : 0;
  };

  const añadirFila = (e) => {
    e.preventDefault(); // Evita el comportamiento por defecto del botón
    const nuevaFila = {
      id: uuidv4(),
      nombre: "",
      numeroDeParte: "",
      cantidad: 1,
      tarifa: 0,
      iva: 0.16, // Asegura que el IVA tenga un valor predeterminado
      descuento: 0, // Asegura que el descuento tenga un valor inicial
      tipoDescuento: "%", // Tipo de descuento inicial
      importe: 0, // Importe inicial en 0
    };

    setDatosSeleccionados((prevDatos) => [...prevDatos, nuevaFila]);
  };

  const agregarArticulosAGranel = (articulos) => {
    // Lógica para agregar artículos a granel
    console.log("Agregando artículos a granel:", articulos);
  };

  useEffect(() => {
    if (cotizacion && clientes.length > 0) {
      const clienteEncontrado = clientes.find(
        (c) => c.empresa === cotizacion.nombreCliente
      );
      if (clienteEncontrado) {
        setCliente(clienteEncontrado.id);
      }
    }
  }, [cotizacion, clientes]);

  const calcularSubtotal = () => {
    if (!datosSeleccionados || datosSeleccionados.length === 0) return 0;

    return datosSeleccionados.reduce((total, producto) => {
      const tarifa = parseFloat(producto.tarifa) || 0;
      const cantidad = parseInt(producto.cantidad) || 0;
      const descuento = parseFloat(producto.descuento) || 0;

      const descuentoAplicado =
        producto.tipoDescuento === "%"
          ? tarifa * cantidad * (descuento / 100)
          : descuento;

      return total + tarifa * cantidad - descuentoAplicado;
    }, 0);
  };

  return (
    <>
      <Nav
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
        events={events}
      />

      <Sidebar />

      <div className="cotizacion-form">
        <form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <div>
            <div className="group apartado">
              <label htmlFor="cliente" className="required">
                Nombre del cliente*
              </label>
              <select
                id="cliente"
                name="cliente"
                value={cliente}
                onChange={(e) => {
                  setCliente(e.target.value);
                  setFormDirty(true); // Marcar que el formulario ha sido modificado
                }}
              >
                <option value="">Seleccionar cliente</option>
                {clientes.map((cliente) => (
                  <option key={cliente.id} value={cliente.id}>
                    {cliente.empresa}
                  </option>
                ))}
              </select>
            </div>

            <br />

            <div className="group">
              <label htmlFor="numeroCotizacion" className="required">
                No. Cotización#*
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  id="numeroCotizacion"
                  name="numeroCotizacion"
                  value={numeroCotizacion}
                  onChange={(e) => {
                    if (modoNumeroCotizacion === "manual") {
                      setNumeroCotizacion(e.target.value);
                    }
                  }}
                  disabled={modoNumeroCotizacion === "auto"} // Deshabilitar en modo automático
                />
                <button
                  type="button"
                  className="icon-button"
                  onClick={() => setModalConfigIsOpen(true)}
                >
                  <i className="bi bi-gear-fill"></i>
                </button>
              </div>
            </div>

            <div className="group">
              <label htmlFor="referencia">Referencia</label>
              <input
                type="text"
                id="referencia"
                name="referencia"
                value={referencia}
                onChange={(e) => {
                  setReferencia(e.target.value);
                  setFormDirty(true); // Marcar que el formulario ha sido modificado
                }}
              />
            </div>

            <div className="group fechas">
              <div className="group">
                <label htmlFor="fecha-cotizacion" className="required">
                  Fecha de cotización*
                </label>
                <DatePicker
                  selected={fechaCotizacion}
                  onChange={(date) => {
                    setFechaCotizacion(date);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                  dateFormat="dd MMM yyyy"
                  placeholderText="dd MMM yyyy"
                  id="fecha-cotizacion"
                  locale="es"
                />
              </div>
              <div className="group">
                <label htmlFor="fecha-vencimiento">Fecha de vencimiento</label>
                <div>
                  <DatePicker
                    selected={fechaVencimiento}
                    onChange={(date) => {
                      setFechaVencimiento(date);
                      setFormDirty(true); // Marcar el formulario como modificado
                    }}
                    dateFormat="dd MMM yyyy"
                    placeholderText="dd MMM yyyy"
                    id="fecha-vencimiento"
                    locale="es"
                  />
                </div>
              </div>
            </div>

            <hr
              style={{
                marginBottom: "30px",
                marginTop: "20px",
                border: "1px solid #f9f9fb",
              }}
            />

            <div className="group">
              <label htmlFor="vendedorNombre">Vendedor</label>
              <select
                id="vendedorNombre"
                name="vendedorNombre"
                value={vendedorNombre}
                onChange={(e) => {
                  handleSelectChange(e);
                  setFormDirty(true); // Marcar el formulario como modificado
                }}
              >
                <option value="">Seleccionar o agregar vendedor</option>
                {vendedores.map((vendedor, index) => (
                  <option key={index} value={vendedor.nombre}>
                    {vendedor.nombre}
                  </option>
                ))}
                <option value="agregar-nuevo">Agregar nuevo vendedor</option>
              </select>
              <br />

              {/* Modal para agregar o editar vendedor */}
              <Modal
                isOpen={modalVendedorIsOpen}
                onRequestClose={closeModalVendedor}
                contentLabel={
                  vendedorNombre && vendedorNombre !== "agregar-nuevo"
                    ? "Editar Vendedor"
                    : "Agregar Nuevo Vendedor"
                }
                style={customStyles2}
              >
                <h2 style={customStyles2.h2}>
                  {vendedorNombre && vendedorNombre !== "agregar-nuevo"
                    ? "Editar Vendedor"
                    : "Agregar Nuevo Vendedor"}
                </h2>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAgregarVendedor();
                  }}
                  style={customStyles2.form}
                >
                  <label
                    htmlFor="nuevoVendedorNombre"
                    style={customStyles2.label}
                  >
                    Nombre (obligatorio)
                  </label>
                  <input
                    type="text"
                    id="nuevoVendedorNombre"
                    name="nuevoVendedorNombre"
                    value={nuevoVendedorNombre}
                    onChange={(e) => setNuevoVendedorNombre(e.target.value)}
                    required
                    style={customStyles2.input}
                  />
                  <br />
                  <label
                    htmlFor="nuevoVendedorCorreo"
                    style={customStyles2.label}
                  >
                    Correo
                  </label>
                  <input
                    type="email"
                    id="nuevoVendedorCorreo"
                    name="nuevoVendedorCorreo"
                    value={nuevoVendedorCorreo}
                    onChange={(e) => setNuevoVendedorCorreo(e.target.value)}
                    style={customStyles2.input}
                  />
                  <br />
                  <div style={customStyles2.buttonContainer}>
                    <button
                      type="button"
                      onClick={closeModalVendedor}
                      style={{
                        ...customStyles2.button,
                        ...customStyles2.buttonCancel,
                      }}
                    >
                      Cancelar
                    </button>
                    <button type="submit" style={customStyles2.button}>
                      {vendedorNombre && vendedorNombre !== "agregar-nuevo"
                        ? "Actualizar"
                        : "Guardar"}
                    </button>
                  </div>
                </form>
              </Modal>
            </div>

            <div className="group fechas">
              <div className="group">
                <label htmlFor="atencion" className="required">
                  Atención*
                </label>
                <input
                  type="text"
                  id="atencion"
                  name="atencion"
                  value={atencion}
                  onChange={(e) => {
                    setAtencion(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                />
              </div>
              <div className="group">
                <label htmlFor="planta">Planta</label>
                <input
                  type="text"
                  id="planta"
                  name="planta"
                  value={planta}
                  onChange={(e) => {
                    setPlanta(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                />
              </div>
            </div>

            <div className="group fechas">
              <div className="group">
                <label htmlFor="area">Área</label>
                <input
                  type="text"
                  id="area"
                  name="area"
                  value={area}
                  onChange={(e) => {
                    setArea(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                />
              </div>
              <div className="group">
                <label htmlFor="divisa">Divisa</label>
                <select
                  id="divisa"
                  name="divisa"
                  value={divisa}
                  onChange={(e) => {
                    setDivisa(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                >
                  <option value="MXN">MXN</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>

            <br />

            <div className="group">
              <label htmlFor="asunto">Asunto</label>
              <input
                type="text"
                id="asunto"
                name="asunto"
                value={asunto}
                onChange={(e) => {
                  setAsunto(e.target.value);
                  setFormDirty(true); // Marcar el formulario como modificado
                }}
              />
            </div>

            <br />

            <div className="apartado2">Tabla de productos</div>

            <div>
              {/* Contenedor para la tabla y los botones */}
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {/* Tabla general */}
                <div>
                  <div>
                    <table className="productos-table">
                      <thead>
                        <tr>
                          <th>Detalles del Artículo</th>
                          <th style={{ textAlign: "center" }}>Modelo</th>
                          <th id="cantidad-col">Cantidad</th>
                          <th id="precio-col">Precio</th>
                          <th id="descuento-col">Descuento</th>
                          <th id="importe-col">Importe</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datosSeleccionados.map((producto) => (
                          <tr key={producto.id}>
                            <td>
                              <input
                                id="list-products"
                                list="productos-list"
                                value={producto.descripcion || producto.nombre}
                                onChange={(e) => {
                                  handleInputChange(e, producto.id);
                                  setFormDirty(true); // Marcar el formulario como modificado
                                }}
                                placeholder="Escriba o haga clic para seleccionar un artículo"
                                style={{ width: "600px", height: "100px" }}
                                autoComplete="off"
                              />
                              <datalist id="productos-list">
                                {productos.map((prod) => (
                                  <option key={prod.id} value={prod.nombre} />
                                ))}
                              </datalist>
                            </td>
                            <td>
                              <input
                                type="text"
                                value={
                                  producto.modelo ||
                                  producto.numeroDeParte ||
                                  ""
                                }
                                onChange={(e) => {
                                  const nuevoModelo = e.target.value;
                                  setDatosSeleccionados((prevDatos) =>
                                    prevDatos.map((item) =>
                                      item.id === producto.id
                                        ? { ...item, modelo: nuevoModelo }
                                        : item
                                    )
                                  );
                                  setFormDirty(true); // Marcar el formulario como modificado
                                }}
                              />
                            </td>

                            <td id="cantidad-col">
                              <input
                                type="number"
                                value={producto.cantidad || 1}
                                onChange={(e) => {
                                  actualizarCantidad(
                                    producto.id,
                                    parseInt(e.target.value) || 0
                                  );
                                  setFormDirty(true); // Marcar el formulario como modificado
                                }}
                              />
                            </td>
                            <td id="precio-col">
                              <input
                                type="text"
                                value={
                                  producto.tarifa !== undefined
                                    ? producto.tarifa
                                    : ""
                                }
                                onChange={(e) => {
                                  const nuevoValor = e.target.value;
                                  // Permitir solo números y punto decimal
                                  if (/^\d*\.?\d*$/.test(nuevoValor)) {
                                    setDatosSeleccionados((prevDatos) =>
                                      prevDatos.map((item) =>
                                        item.id === producto.id
                                          ? {
                                              ...item,
                                              tarifa: nuevoValor, // Guardar como cadena para evitar problemas de cursor
                                              importe:
                                                parseFloat(nuevoValor) *
                                                  (item.cantidad || 0) || 0,
                                            }
                                          : item
                                      )
                                    );
                                    setFormDirty(true); // Marcar el formulario como modificado
                                  }
                                }}
                                onBlur={(e) => {
                                  const tarifaNumerica = parseFloat(
                                    e.target.value.replace(/,/g, "")
                                  );
                                  if (!isNaN(tarifaNumerica)) {
                                    setDatosSeleccionados((prevDatos) =>
                                      prevDatos.map((item) =>
                                        item.id === producto.id
                                          ? {
                                              ...item,
                                              tarifa: tarifaNumerica.toFixed(2), // Formatear a dos decimales
                                              importe: (
                                                tarifaNumerica *
                                                (item.cantidad || 0)
                                              ).toFixed(2),
                                            }
                                          : item
                                      )
                                    );
                                  } else {
                                    setDatosSeleccionados((prevDatos) =>
                                      prevDatos.map((item) =>
                                        item.id === producto.id
                                          ? {
                                              ...item,
                                              tarifa: "0.00",
                                              importe: "0.00",
                                            }
                                          : item
                                      )
                                    );
                                  }
                                }}
                                onFocus={(e) => {
                                  // Opcional: Limpiar el campo al enfocarlo si está formateado
                                  if (e.target.value === "0.00") {
                                    setDatosSeleccionados((prevDatos) =>
                                      prevDatos.map((item) =>
                                        item.id === producto.id
                                          ? { ...item, tarifa: "" }
                                          : item
                                      )
                                    );
                                  }
                                }}
                                style={{
                                  width: "100%",
                                  textAlign: "right",
                                  padding: "8px",
                                  fontSize: "14px",
                                  borderRadius: "4px",
                                }}
                              />
                            </td>

                            <td id="descuento-col">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  overflow: "hidden",
                                  margin: "0 auto",
                                  width: "100%", // El contenedor ocupa todo el ancho de la celda
                                }}
                              >
                                {/* Input para el descuento */}
                                <input
                                  type="text"
                                  min="0"
                                  step="1"
                                  value={producto.descuento || 0}
                                  onChange={(e) => {
                                    const valor = e.target.value;
                                    if (/^\d*\.?\d*$/.test(valor)) {
                                      let nuevoDescuento = parseFloat(valor);
                                      if (isNaN(nuevoDescuento)) {
                                        nuevoDescuento = 0;
                                      } else if (nuevoDescuento > 100) {
                                        nuevoDescuento = 100;
                                      }
                                      setDatosSeleccionados((prevDatos) =>
                                        prevDatos.map((item) =>
                                          item.id === producto.id
                                            ? {
                                                ...item,
                                                descuento: nuevoDescuento,
                                                importe:
                                                  item.tipoDescuento === "%"
                                                    ? item.tarifa *
                                                      item.cantidad *
                                                      (1 - nuevoDescuento / 100)
                                                    : item.tarifa *
                                                        item.cantidad -
                                                      nuevoDescuento,
                                              }
                                            : item
                                        )
                                      );
                                      setFormDirty(true);
                                    }
                                  }}
                                  style={{
                                    flex: 1, // El input se expande todo lo posible
                                    outline: "none",
                                    textAlign: "right",
                                    padding: "8px",
                                    fontSize: "14px",
                                    backgroundColor: "#fff",
                                    color: "#333",
                                    borderRadius: "0",
                                  }}
                                />

                                {/* Select para el tipo de descuento */}
                                <select
                                  value={producto.tipoDescuento || "%"}
                                  onChange={(e) => {
                                    const nuevoTipo = e.target.value;
                                    setDatosSeleccionados((prevDatos) =>
                                      prevDatos.map((item) =>
                                        item.id === producto.id
                                          ? {
                                              ...item,
                                              tipoDescuento: nuevoTipo,
                                              importe:
                                                nuevoTipo === "%"
                                                  ? item.tarifa *
                                                    item.cantidad *
                                                    (1 - item.descuento / 100)
                                                  : item.tarifa *
                                                      item.cantidad -
                                                    item.descuento,
                                            }
                                          : item
                                      )
                                    );
                                    setFormDirty(true);
                                  }}
                                  style={{
                                    flex: "0 0 50px", // Select con ancho fijo más estrecho
                                    border: "1px solid #ccc",
                                    outline: "none",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    padding: "7.5px",
                                    cursor: "pointer",
                                    backgroundColor: "#f4f4f4",
                                    color: "#333",
                                    borderRadius: "0",
                                    borderLeft: "none", // Quita el borde izquierdo para que se vea unido al input
                                  }}
                                >
                                  <option value="%">%</option>
                                  <option value="$">$</option>
                                </select>
                              </div>
                            </td>

                            <td
                              id="importe-col"
                              style={{
                                textAlign: "right",
                                paddingRight: "10px",
                              }}
                            >
                              $
                              {producto.importe.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || "0.00"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Contenedor para los botones */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                    marginLeft: "10px",
                  }}
                >
                  {datosSeleccionados.map((producto) => (
                    <div
                      key={producto.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "100px",
                      }}
                    >
                      {/* Botón de más opciones */}
                      <button
                        type="button"
                        style={{
                          background: "none",
                          border: "none",
                          padding: "0",
                          cursor: "pointer",
                          color: "#000",
                        }}
                      >
                        <i
                          className="bi bi-three-dots-vertical"
                          style={{ fontSize: "1.2rem" }}
                        ></i>
                      </button>

                      {/* Botón de eliminar */}
                      <button
                        type="button"
                        style={{
                          background: "none",
                          border: "none",
                          padding: "0",
                          cursor: "pointer",
                          color: "#dc3545", // Color rojo para el ícono
                        }}
                        onClick={() => eliminarProducto(producto.id)}
                      >
                        <i
                          className="bi bi-x-lg"
                          style={{ fontSize: "1.2rem" }}
                        ></i>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="acciones-tabla">
              <button type="button" onClick={añadirFila}>
                <i
                  style={{ color: "#007bff", fontSize: "16px" }}
                  className="bi bi-plus-circle-fill"
                ></i>
                Añadir nueva fila
              </button>
              <button type="button" onClick={agregarArticulosAGranel}>
                <i
                  style={{ color: "#007bff", fontSize: "16px" }}
                  className="bi bi-plus-circle-fill"
                ></i>
                Agregar artículos a granel
              </button>
            </div>

            <div className="data-container">
              <div className="notes-container">
                <label htmlFor="customer-notes">Notas del Cliente</label>
                <textarea
                  id="customer-notes"
                  placeholder="Escribe tus notas aquí..."
                  value={notas}
                  onChange={(e) => {
                    setNotas(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                ></textarea>
              </div>

              <div className="totales-container">
                <div className="row">
                  <span className="label">Subtotal</span>
                  <span>
                    $
                    {calcularSubtotal().toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>

                <div className="row descuento">
                  <span className="label">Descuento</span>
                  <div className="descuento-input-container">
                    <input
                      type="text"
                      className="descuento-input"
                      value={descuento}
                      onChange={manejarCambioDescuento}
                    />
                    <span className="percentage-sign">%</span>
                  </div>
                  <span className="discount-percentage">
                    - $
                    {((calcularSubtotal() * descuento) / 100).toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </span>
                </div>

                <div className="row iva">
                  <span className="label">
                    Tipo estándar [{(obtenerIVA() * 100).toFixed(0)}%]
                  </span>
                  <span>
                    $
                    {(
                      (calcularSubtotal() -
                        calcularSubtotal() * (descuento / 100)) *
                      obtenerIVA()
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>

                <hr />

                <div className="row total">
                  <span>Total ({divisa})</span>
                  <span className="total-amount">
                    $
                    {calcularTotal().toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                backgroundColor: "#f9f9fb",
                padding: "8px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "10px",
                width: "94%",
                marginTop: "30px",
                paddingBottom: "30px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Left Section: Términos y Condiciones */}
              <div className="terminos-container">
                <h4>Términos y condiciones</h4>
                <textarea
                  value={terminos}
                  onChange={(e) => {
                    setTerminos(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                />
              </div>

              {/* Right Section: File Upload */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                  flex: "0 0 530px",
                  marginTop: "30px",
                }}
              >
                <label style={{ fontSize: "14px" }}>
                  Adjuntar archivo(s) a Estimación
                </label>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px dashed #ccc",
                    borderRadius: "5px",
                    overflow: "hidden", // Eliminar cualquier contenido que se salga del borde
                  }}
                >
                  <label
                    style={{
                      padding: "8px 10px",
                      backgroundColor: "#fff",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      cursor: "pointer",
                      whiteSpace: "nowrap", // Evitar que el contenido del botón se ajuste en varias líneas
                    }}
                  >
                    <i style={{ color: "#000" }} className="bi bi-upload"></i>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                        fontWeight: "lighter",
                      }}
                    >
                      Cargar archivo
                    </span>
                    <input
                      type="file"
                      style={{ display: "none" }} // Ocultar el input de archivo
                      accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.jpg,.jpeg,.png,.svg"
                      onChange={manejarArchivos}
                    />
                  </label>

                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "#fff",
                      borderLeft: "1px dashed #ccc", // Borde que conecta con el input
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      borderTopRightRadius: "auto", // Bordes redondeados en la esquina derecha
                      borderBottomRightRadius: "auto",
                      minWidth: "20px", // Evitar que el botón se haga demasiado pequeño
                      margin: "0", // Eliminar cualquier margen que agregue espacio extra
                    }}
                  >
                    <i
                      style={{
                        color: "#000",
                        fontSize: "14px", // Ajustar el tamaño del ícono
                      }}
                      className="bi bi-chevron-down"
                    ></i>
                  </div>
                </div>

                <p style={{ fontSize: "12px", color: "#888" }}>
                  Puede cargar un máximo de 3 archivos, cada uno de 10 MB
                </p>

                {archivos.length > 0 && (
                  <div style={{ marginTop: "20px", width: "100%" }}>
                    <strong>Archivos cargados:</strong>
                    <ul
                      style={{
                        listStyle: "none",
                        padding: 0,
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {archivos.map((archivo, index) => {
                        const extension = archivo.nombre
                          .split(".")
                          .pop()
                          .toLowerCase();
                        const { icon, color } = obtenerIconoArchivo(extension);

                        return (
                          <li
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              padding: "10px",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              backgroundColor: "#f9f9fb",
                            }}
                          >
                            <i
                              className={`bi ${icon}`}
                              style={{
                                color,
                                fontSize: "1.5rem",
                                flexShrink: 0,
                              }}
                            ></i>

                            <span style={{ flexGrow: 1 }}>
                              {archivo.nombre}
                            </span>

                            {/* Botón de descarga */}
                            <button
                              onClick={(e) => {
                                e.preventDefault(); // Evitar el comportamiento predeterminado del botón
                                handleDescargarArchivo(
                                  archivo.url,
                                  archivo.nombre
                                );
                              }}
                              style={{
                                color: "#007bff",
                                background: "none",
                                border: "none",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                            >
                              <i
                                style={{ fontSize: "20px" }}
                                className="bi bi-download"
                              ></i>
                            </button>

                            {/* Botón de eliminar */}
                            <button
                              type="button"
                              onClick={() => eliminarArchivo(archivo.nombre)}
                              style={{
                                border: "none",
                                background: "transparent",
                                color: "#dc3545",
                                cursor: "pointer",
                              }}
                            >
                              <i
                                className="bi bi-trash"
                                style={{ fontSize: "20px" }}
                              ></i>
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Grupo de botones: Guardar como borrador, Guardar y enviar, Cancelar */}
          <div className="cotizacion-toolbar">
            <div className="btn-group">
              <button
                id="guardar-enviar"
                className="btn btn-primary"
                type="submit"
                onClick={guardar}
              >
                {modoEdicion ? "Actualizar" : "Guardar y enviar"}
              </button>
              <button
                id="cancelar"
                className="btn btn-secondary"
                type="button"
                onClick={handleCancel}
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={cerrarModalPrevia}
          contentLabel="Vista Previa"
          style={customStyles}
        >
          {mostrarPrevia && (
            <PreviaCotizacion
              cliente={cliente}
              clientes={clientes}
              referencia={referencia}
              asunto={asunto}
              fechaCotizacion={fechaCotizacion}
              productosSeleccionados={productosSeleccionados}
              continuarDesdePrevia={continuarDesdePrevia}
              numeroCotizacion={props.numeroCotizacion}
            />
          )}
          <div className="modal-buttons">
            <button onClick={cerrarModalPrevia} className="eliminarBtnModal">
              Cerrar
            </button>
          </div>
        </Modal>

        {mostrarTabla && (
          <TablaCotizaciones
            numeroCotizacion={numeroCotizacion}
            cotizaciones={cotizaciones}
          />
        )}
      </div>

      <Modal
        isOpen={modalConfigIsOpen}
        onRequestClose={() => setModalConfigIsOpen(false)}
        style={modalNoCotizacion}
      >
        <h2 style={modalNoCotizacion.h2}>
          Configurar preferencias de Número de Cotización
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            guardarConfiguracionNumeroCotizacion();
          }}
          style={modalNoCotizacion.form}
        >
          <div style={modalNoCotizacion.radioGroup}>
            <label style={modalNoCotizacion.radioLabel}>
              <input
                type="radio"
                name="modoNumeroCotizacion"
                value="auto"
                checked={modoNumeroCotizacion === "auto"}
                onChange={handleModoCambio}
                style={modalNoCotizacion.radioInput}
              />
              Continuar generando automáticamente números de cotización
            </label>
            {modoNumeroCotizacion === "auto" && (
              <div style={modalNoCotizacion.inputGroup}>
                <label htmlFor="prefijo" style={modalNoCotizacion.inlineLabel}>
                  Prefijo
                </label>
                <input
                  type="text"
                  id="prefijo"
                  value={prefijo}
                  onChange={(e) => setPrefijo(e.target.value)}
                  style={modalNoCotizacion.smallInput}
                />
                <label
                  htmlFor="siguienteNumero"
                  style={modalNoCotizacion.inlineLabel}
                >
                  Siguiente número
                </label>
                <input
                  type="number"
                  id="siguienteNumero"
                  value={siguienteNumero}
                  onChange={(e) => setSiguienteNumero(e.target.value)}
                  style={modalNoCotizacion.smallInput}
                />
              </div>
            )}
          </div>
          <label style={modalNoCotizacion.radioLabel}>
            <input
              type="radio"
              name="modoNumeroCotizacion"
              value="manual"
              checked={modoNumeroCotizacion === "manual"}
              onChange={handleModoCambio}
              style={modalNoCotizacion.radioInput}
            />
            Introduzca manualmente los números de cotización
          </label>
          <div style={modalNoCotizacion.buttonContainer}>
            <button
              type="button"
              onClick={() => setModalConfigIsOpen(false)}
              style={{
                ...modalNoCotizacion.button,
                ...modalNoCotizacion.buttonCancel,
              }}
            >
              Cancelar
            </button>
            <button type="submit" style={modalNoCotizacion.button}>
              Guardar
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

function PreviaCotizacion({
  productosSeleccionados, // Aquí no asignamos un valor por defecto, pero lo manejamos luego
}) {
  // Validación para asegurarse de que productosSeleccionados sea un arreglo
  if (!Array.isArray(productosSeleccionados)) {
    console.error(
      "productosSeleccionados está indefinido o no es un arreglo:",
      productosSeleccionados
    );
    productosSeleccionados = []; // Establece un arreglo vacío como respaldo
  }
}

export default CotizacionForm;
