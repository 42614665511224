import React, { useState, useEffect, useCallback } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "bootstrap-icons/font/bootstrap-icons.css";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openMenus, setOpenMenus] = useState({});
  const { userRole } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const handlePlusIconClick = (e) => {
    e.preventDefault(); // Evita la navegación predeterminada del enlace
    navigate("/cotizacion", { state: { openModal: true } });
    closeSidebar(); // Cierra el Sidebar
  };

  const handleLinkClick = (e) => {
    if (e.target.closest(".bi-plus-circle")) {
      e.preventDefault(); // Evita la navegación si se hace clic en el ícono
    } else {
      closeSidebar();
    }
  };

  const handlePlusIconClickRemisiones = (e) => {
    e.preventDefault(); // Evita la navegación predeterminada del enlace
    navigate("/remisiones", { state: { openModal: true } });
    closeSidebar(); // Cierra el Sidebar
  };

  const handleLinkClickRemisiones = (e) => {
    if (e.target.closest(".bi-plus-circle")) {
      e.preventDefault(); // Evita la navegación si se hace clic en el ícono
    } else {
      closeSidebar();
    }
  };

  const handlePlusIconClickOrdenes = (e) => {
    e.preventDefault(); // Evita la navegación predeterminada del enlace
    navigate("/orden", { state: { openModal: true } });
    closeSidebar(); // Cierra el Sidebar
  };

  const handleLinkClickOrdenes = (e) => {
    if (e.target.closest(".bi-plus-circle")) {
      e.preventDefault(); // Evita la navegación si se hace clic en el ícono
    } else {
      closeSidebar();
    }
  };

  //const getYear = () => new Date().getFullYear();

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };

  const toggleMenu = useCallback((menu) => {
    setIsOpen(true);
    setOpenMenus((prev) => ({ ...prev, [menu]: !prev[menu] }));
  }, []);

  const closeSidebar = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setOpenMenus({});
    }
  }, [isOpen]);

  const isMenuOpen = (menu) => openMenus[menu] || false;

  const { logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    localStorage.removeItem("userRole"); // Eliminar el rol de localStorage al cerrar sesión
    navigate("/login");
  };

  document.querySelectorAll(".sidebar-item").forEach((item) => {
    item.addEventListener("mouseenter", (event) => {
      // Busca un tooltip existente y reutilízalo
      let tooltip = document.querySelector(".tooltip");
      if (!tooltip) {
        tooltip = document.createElement("div");
        tooltip.className = "tooltip";
        document.body.appendChild(tooltip);
      }

      // Actualiza el contenido y la posición del tooltip
      tooltip.textContent = item.getAttribute("data-tooltip");

      const rect = item.getBoundingClientRect();
      tooltip.style.left = `${rect.right + 10}px`; // Ajusta la distancia a la derecha del elemento
      tooltip.style.top = `${rect.top + window.scrollY}px`; // Ajusta la posición vertical
      tooltip.style.opacity = 1;
      tooltip.style.visibility = "visible";
    });

    item.addEventListener("mouseleave", () => {
      // Oculta el tooltip al salir del elemento
      const tooltip = document.querySelector(".tooltip");
      if (tooltip) {
        tooltip.style.opacity = 0;
        tooltip.style.visibility = "hidden";
      }
    });

    item.addEventListener("click", () => {
      // Oculta el tooltip al hacer clic en un elemento del menú
      const tooltip = document.querySelector(".tooltip");
      if (tooltip) {
        tooltip.style.opacity = 0;
        tooltip.style.visibility = "hidden";
      }
    });
  });

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="sidebar-header">
        {isOpen ? (
          <div className="menu-toggle-btn" onClick={toggleSidebar}>
            <i className="bi bi-chevron-left"></i>
          </div>
        ) : (
          <>
            <div className="menu-icon">
              <Link to="/">
                <img
                  to="/"
                  src="/img/ic.svg"
                  alt="Menu Icon"
                />
              </Link>
            </div>
            {/* Nuevo ícono para abrir el sidebar cuando está cerrado */}
            <div className="sidebar-open-btn" onClick={toggleSidebar}>
              <i className="bi bi-chevron-right"></i>
            </div>
          </>
        )}
      </div>

      <div className="sidebar-content">
        <nav id="primary-menu" className="navbar">
          <ul className="nav navbar-nav">
            {isOpen && <span className="main-text">Principal</span>}
            <li
              className={`sidebar-item ${
                location.pathname === "/" ? "border-bottom-active" : ""
              }`}
              data-tooltip="Inicio"
            >
              <Link
                className={location.pathname === "/" ? "active" : ""}
                to="/"
                onClick={closeSidebar}
              >
                <span className="icon">
                  <i className="bi bi-house"></i>
                </span>
                <span className="text">Inicio</span>
              </Link>
            </li>
            {userRole === "Ventas" && (
              <>
                <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
                  <div className="sidebar-header">
                    {isOpen && (
                      <Link to="/">
                        <img
                          className="logo"
                          src="/img/logo-iciamex.png"
                          alt="ICIAMEX logo"
                          style={{ display: isOpen ? 'block' : 'none' }}
                        />
                      </Link>
                    )}
                    <Link to="/">
                      <img
                        className="menu-icon"
                        src="/img/ic.svg"
                        alt="Menu Icon"
                      />
                    </Link>
                  </div>
                  <div className="sidebar-content">
                    <nav id="primary-menu" className="navbar">
                      <ul className="nav navbar-nav">
                        {isOpen && <span className="main-text">Principal</span>}
                        <li
                          className={`sidebar-item ${
                            location.pathname === "/"
                              ? "border-bottom-active"
                              : ""
                          }`}
                          data-tooltip="Inicio"
                        >
                          <Link
                            className={
                              location.pathname === "/" ? "active" : ""
                            }
                            to="/"
                            onClick={closeSidebar}
                          >
                            <span className="icon">
                              <i className="bi bi-house"></i>
                            </span>
                            <span className="text">Inicio</span>
                          </Link>
                        </li>
                        <li
                          className={`sidebar-item ${
                            location.pathname === "/tareas"
                              ? "border-bottom-active"
                              : ""
                          }`}
                          data-tooltip="Tareas"
                        >
                          <Link
                            className={
                              location.pathname === "/tareas" ? "active" : ""
                            }
                            to="/tareas"
                            onClick={closeSidebar}
                          >
                            <span className="icon">
                              <i className="bi bi-check2-square"></i>
                            </span>
                            <span className="text">Tareas</span>
                          </Link>
                        </li>
                        <li
                          className={`sidebar-item ${
                            location.pathname === "/calendario"
                              ? "border-bottom-active"
                              : ""
                          }`}
                          data-tooltip="Calendario"
                        >
                          <Link
                            className={
                              location.pathname === "/calendario"
                                ? "active"
                                : ""
                            }
                            to="/calendario"
                            onClick={closeSidebar}
                          >
                            <span className="icon">
                              <i className="bi bi-calendar2-day"></i>
                            </span>
                            <span className="text">Calendario</span>
                          </Link>
                        </li>

                        <br />

                        {isOpen && (
                          <span className="main-text">Utilidades</span>
                        )}
                        <li
                          data-tooltip="Ingresos"
                          className={isMenuOpen("Ingresos") ? "open" : ""}
                        >
                          <Link
                            className={`sidebar-item ${
                              isMenuOpen("Ingresos")
                                ? "border-bottom-active"
                                : ""
                            }`}
                            to="#"
                            onClick={() => toggleMenu("Ingresos")}
                            data-tooltip="Ingresos"
                          >
                            <span className="icon">
                              <i className="bi bi-graph-up-arrow"></i>
                            </span>
                            <span className="text">Ingresos</span>
                            <div className="dropdown-arrow-container">
                              <span
                                className={`dropdown-arrow ${
                                  isOpen && isMenuOpen("Ingresos")
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {isOpen ? (
                                  isMenuOpen("Ingresos") ? (
                                    <i className="bi bi-chevron-up"></i>
                                  ) : (
                                    <i className="bi bi-chevron-right"></i>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </Link>
                          {isMenuOpen("Ingresos") && (
                            <ul
                              className={`sub-menu ${
                                isMenuOpen("Ingresos") ? "open" : "closed"
                              }`}
                              data-tooltip="Ingresos"
                            >
                              <li>
                                <Link
                                  className="text"
                                  to="/cotizacion"
                                  onClick={handleLinkClick}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>Cotizaciones</span>
                                  <span onClick={handlePlusIconClick}>
                                    <i className="bi bi-plus-circle"></i>
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="/remisiones"
                                  onClick={handleLinkClickRemisiones}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>Remisiones</span>
                                  <span onClick={handlePlusIconClickRemisiones}>
                                    <i className="bi bi-plus-circle"></i>
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Órdenes de Compra</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Pagos Recibidos</span>
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>
                      </ul>
                    </nav>

                    {isOpen && (
                      <div
                        style={{
                          position: "absolute", // Fija el botón en el contenedor del sidebar
                          bottom: "20px", // Espacio desde el fondo del sidebar
                          width: "100%", // Asegura que el botón esté centrado en el sidebar
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          onClick={handleLogout}
                          style={{
                            padding: "15px",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            backgroundColor: "#4C5165",
                            color: "#e0e0e0",
                            borderRadius: "8px",
                            fontSize: "14px",
                            margin: "0 auto",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#626879")
                          } // Efecto hover más claro
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "#4C5165")
                          }
                        >
                          <i
                            style={{ marginRight: "10px", fontSize: "16px" }}
                            className="bi bi-box-arrow-in-right"
                          ></i>
                          Cerrar Sesión
                        </button>
                      </div>
                    )}
                    <Outlet />
                  </div>

                  {/*<div style={{ textAlign: "center" }}>
                    <div className="sidebar-footer" style={{
                      display: "inline-block",
                      borderBottom: isOpen ? "1px solid #9e9e9e" : "none",
                      width: isOpen ? "65%" : "auto",
                    }}>
                      <div className="sidebar-copyright">
                        &copy; {getYear()} ICIAMEX
                      </div>
                    </div>
                  </div> */}
                </div>
              </>
            )}

            {userRole === "Administrador" && (
              <>
                <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
                  <div className="sidebar-header">
                    {isOpen && (
                      <Link to="/">
                        <img
                          className="logo"
                          src="/img/logo-iciamex.png"
                          alt="ICIAMEX logo"
                          style={{ display: isOpen ? 'block' : 'none' }}
                        />
                      </Link>
                    )}
                    <Link to="/">
                      <img
                        className="menu-icon"
                        src="/img/ic.svg"
                        alt="Menu Icon"
                      />
                    </Link>
                  </div>
                  <div className="sidebar-content">
                    <nav id="primary-menu" className="navbar">
                      <ul className="nav navbar-nav">
                        {isOpen && <span className="main-text">Principal</span>}
                        <li
                          className={`sidebar-item ${
                            location.pathname === "/"
                              ? "border-bottom-active"
                              : ""
                          }`}
                          data-tooltip="Inicio"
                        >
                          <Link
                            className={
                              location.pathname === "/" ? "active" : ""
                            }
                            to="/"
                            onClick={closeSidebar}
                          >
                            <span className="icon">
                              <i className="bi bi-house"></i>
                            </span>
                            <span className="text">Inicio</span>
                          </Link>
                        </li>
                        <li
                          className={`sidebar-item ${
                            location.pathname === "/tareas"
                              ? "border-bottom-active"
                              : ""
                          }`}
                          data-tooltip="Tareas"
                        >
                          <Link
                            className={
                              location.pathname === "/tareas" ? "active" : ""
                            }
                            to="/tareas"
                            onClick={closeSidebar}
                          >
                            <span className="icon">
                              <i className="bi bi-check2-square"></i>
                            </span>
                            <span className="text">Tareas</span>
                          </Link>
                        </li>
                        <li
                          className={`sidebar-item ${
                            location.pathname === "/calendario"
                              ? "border-bottom-active"
                              : ""
                          }`}
                          data-tooltip="Calendario"
                        >
                          <Link
                            className={
                              location.pathname === "/calendario"
                                ? "active"
                                : ""
                            }
                            to="/calendario"
                            onClick={closeSidebar}
                          >
                            <span className="icon">
                              <i className="bi bi-calendar2-day"></i>
                            </span>
                            <span className="text">Calendario</span>
                          </Link>
                        </li>

                        <br />

                        {isOpen && (
                          <span className="main-text">Utilidades</span>
                        )}
                        <li
                          data-tooltip="Ingresos"
                          className={isMenuOpen("Ingresos") ? "open" : ""}
                        >
                          <Link
                            className={`sidebar-item ${
                              isMenuOpen("Ingresos")
                                ? "border-bottom-active"
                                : ""
                            }`}
                            data-tooltip="Ingresos"
                            to="#"
                            onClick={() => toggleMenu("Ingresos")}
                          >
                            <span className="icon">
                              <i className="bi bi-graph-up-arrow"></i>
                            </span>
                            <span className="text">Ingresos</span>
                            <div className="dropdown-arrow-container">
                              <span
                                className={`dropdown-arrow ${
                                  isOpen && isMenuOpen("Ingresos")
                                    ? "active"
                                    : ""
                                }`}
                                data-tooltip="Ingresos"
                              >
                                {isOpen ? (
                                  isMenuOpen("Ingresos") ? (
                                    <i className="bi bi-chevron-up"></i>
                                  ) : (
                                    <i className="bi bi-chevron-right"></i>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </Link>
                          {isMenuOpen("Ingresos") && (
                            <ul
                              className={`sub-menu ${
                                isMenuOpen("Ingresos") ? "open" : "closed"
                              }`}
                            >
                              <li>
                                <Link
                                  className="text"
                                  to="/cotizacion"
                                  onClick={handleLinkClick} // Puedes manejar otras acciones aquí
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>Cotizaciones</span>
                                  <span onClick={handlePlusIconClick}>
                                    <i className="bi bi-plus-circle"></i>
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="/remisiones"
                                  onClick={handleLinkClickRemisiones}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>Remisiones</span>
                                  <span onClick={handlePlusIconClickRemisiones}>
                                    <i className="bi bi-plus-circle"></i>
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Órdenes de Compra</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Pagos Recibidos</span>
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>

                        <li
                          data-tooltip="Gastos"
                          className={isMenuOpen("Gastos") ? "open" : ""}
                        >
                          <Link
                            className={`sidebar-item ${
                              isMenuOpen("Gastos") ? "border-bottom-active" : ""
                            }`}
                            to="#"
                            onClick={() => toggleMenu("Gastos")}
                            data-tooltip="Gastos"
                          >
                            <span className="icon">
                              <i className="bi bi-currency-dollar"></i>
                            </span>
                            <span className="text">Gastos</span>
                            <div className="dropdown-arrow-container">
                              <span
                                className={`dropdown-arrow ${
                                  isOpen && isMenuOpen("Gastos") ? "active" : ""
                                }`}
                              >
                                {isOpen ? (
                                  isMenuOpen("Gastos") ? (
                                    <i className="bi bi-chevron-up"></i>
                                  ) : (
                                    <i className="bi bi-chevron-right"></i>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </Link>
                          {isMenuOpen("Gastos") && (
                            <ul
                              className={`sub-menu ${
                                isMenuOpen("Gastos") ? "open" : "closed"
                              }`}
                            >
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Facturas de Proveedores</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Pagos</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Notas de Débito</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="/orden"
                                  onClick={handleLinkClickOrdenes}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>Órdenes de Compra</span>
                                  <span onClick={handlePlusIconClickOrdenes}>
                                    <i className="bi bi-plus-circle"></i>
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>

                        <li
                          data-tooltip="Inventario"
                          className={isMenuOpen("Inventario") ? "open" : ""}
                        >
                          <Link
                            className={`sidebar-item ${
                              isMenuOpen("Inventario")
                                ? "border-bottom-active"
                                : ""
                            }`}
                            to="#"
                            onClick={() => toggleMenu("Inventario")}
                            data-tooltip="Inventario"
                          >
                            <span className="icon">
                              <i className="bi bi-box"></i>
                            </span>
                            <span className="text">Inventario</span>
                            <div className="dropdown-arrow-container">
                              <span
                                className={`dropdown-arrow ${
                                  isOpen && isMenuOpen("Inventario")
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {isOpen ? (
                                  isMenuOpen("Inventario") ? (
                                    <i className="bi bi-chevron-up"></i>
                                  ) : (
                                    <i className="bi bi-chevron-right"></i>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </Link>
                          {isMenuOpen("Inventario") && (
                            <ul
                              className={`sub-menu ${
                                isMenuOpen("Inventario") ? "open" : "closed"
                              }`}
                            >
                              <li>
                                <Link
                                  className="text"
                                  to="/articulos-venta"
                                  onClick={closeSidebar}
                                >
                                  <span>Productos de Venta</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="/articulos-compra"
                                  onClick={closeSidebar}
                                >
                                  <span>Productos de Compra</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Ajustes de Inventario</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Gestión de Productos</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Categorías</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Almacenes</span>
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>

                        <li
                          data-tooltip="Reportes"
                          className={isMenuOpen("Reportes") ? "open" : ""}
                        >
                          <Link
                            className={`sidebar-item ${
                              isMenuOpen("Reportes")
                                ? "border-bottom-active"
                                : ""
                            }`}
                            data-tooltip="Reportes"
                            to="#"
                            onClick={() => toggleMenu("Reportes")}
                          >
                            <span className="icon">
                              <i className="bi bi-file-earmark-bar-graph"></i>
                            </span>
                            <span className="text">Reportes</span>
                            <div className="dropdown-arrow-container">
                              <span
                                className={`dropdown-arrow ${
                                  isOpen && isMenuOpen("Reportes")
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {isOpen ? (
                                  isMenuOpen("Reportes") ? (
                                    <i className="bi bi-chevron-up"></i>
                                  ) : (
                                    <i className="bi bi-chevron-right"></i>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </Link>
                          {isMenuOpen("Reportes") && (
                            <ul
                              className={`sub-menu ${
                                isMenuOpen("Reportes") ? "open" : "closed"
                              }`}
                            >
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Opción 1</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="#"
                                  onClick={closeSidebar}
                                >
                                  <span>Opción 2</span>
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>

                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              bottom: "0",
                              left: "50%",
                              transform: "translateX(-50%)",
                              borderBottom: isOpen
                                ? "1px solid #4C5165"
                                : "none",
                              width: isOpen ? "85%" : "auto",
                            }}
                          ></div>
                        </div>

                        <br />

                        {isOpen && <span className="main-text">Registros</span>}
                        <li
                          data-tooltip="Contactos"
                          className={isMenuOpen("Contactos") ? "open" : ""}
                        >
                          <Link
                            className={`sidebar-item ${
                              isMenuOpen("Contactos")
                                ? "border-bottom-active"
                                : ""
                            }`}
                            data-tooltip="Contactos"
                            to="#"
                            onClick={() => toggleMenu("Contactos")}
                          >
                            <span className="icon">
                              <i className="bi bi-telephone"></i>
                            </span>
                            <span className="text">Contactos</span>
                            <div className="dropdown-arrow-container">
                              <span
                                className={`dropdown-arrow ${
                                  isOpen && isMenuOpen("Contactos")
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {isOpen ? (
                                  isMenuOpen("Contactos") ? (
                                    <i className="bi bi-chevron-up"></i>
                                  ) : (
                                    <i className="bi bi-chevron-right"></i>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </Link>
                          {isMenuOpen("Contactos") && (
                            <ul
                              className={`sub-menu ${
                                isMenuOpen("Contactos") ? "open" : "closed"
                              }`}
                            >
                              <li>
                                <Link
                                  className="text"
                                  to="/todos"
                                  onClick={closeSidebar}
                                >
                                  <span>Todos</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="/clientes"
                                  onClick={closeSidebar}
                                >
                                  <span>Clientes</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="/proveedores"
                                  onClick={closeSidebar}
                                >
                                  <span>Proveedores</span>
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>

                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            textAlign: "center", // Centra horizontalmente el contenido
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              bottom: "0",
                              left: "50%",
                              transform: "translateX(-50%)",
                              borderBottom: isOpen
                                ? "1px solid #4C5165"
                                : "none",
                              width: isOpen ? "85%" : "auto",
                            }}
                          ></div>
                        </div>

                        <br />

                        {isOpen && <span className="main-text">Soporte</span>}
                        <li
                          data-tooltip="Configuración"
                          className={isMenuOpen("Configuracion") ? "open" : ""}
                        >
                          <Link
                            className={`sidebar-item ${
                              isMenuOpen("Configuracion")
                                ? "border-bottom-active"
                                : ""
                            }`}
                            data-tooltip="Configuración"
                            to="#"
                            onClick={() => toggleMenu("Configuracion")}
                          >
                            <span className="icon">
                              <i className="bi bi-gear"></i>
                            </span>
                            <span className="text">Configuración</span>
                            <div className="dropdown-arrow-container">
                              <span
                                className={`dropdown-arrow ${
                                  isOpen && isMenuOpen("Configuracion")
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {isOpen ? (
                                  isMenuOpen("Configuracion") ? (
                                    <i className="bi bi-chevron-up"></i>
                                  ) : (
                                    <i className="bi bi-chevron-right"></i>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </Link>
                          {isMenuOpen("Configuracion") && (
                            <ul
                              className={`sub-menu ${
                                isMenuOpen("Configuracion") ? "open" : "closed"
                              }`}
                            >
                              <li>
                                <Link
                                  className="text"
                                  to="/usuarios"
                                  onClick={closeSidebar}
                                >
                                  <span>Usuarios</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text"
                                  to="/plantillas"
                                  onClick={closeSidebar}
                                >
                                  <span>Plantillas</span>
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>
                      </ul>
                    </nav>

                    {isOpen && (
                      <div style={{ marginTop: "20px" }}>
                        <button
                          onClick={handleLogout}
                          style={{
                            padding: "15px",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            backgroundColor: "#4C5165",
                            color: "#e0e0e0",
                            borderRadius: "8px",
                            fontSize: "14px",
                            margin: "0 auto",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#626879")
                          } // Efecto hover más claro
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "#4C5165")
                          }
                        >
                          <i
                            style={{ marginRight: "10px", fontSize: "16px" }}
                            className="bi bi-box-arrow-in-right"
                          ></i>
                          Cerrar Sesión
                        </button>
                      </div>
                    )}
                    <Outlet />
                  </div>

                  {/*<div style={{ textAlign: "center" }}>
                    <div className="sidebar-footer" style={{
                      display: "inline-block",
                      borderBottom: isOpen ? "1px solid #9e9e9e" : "none",
                      width: isOpen ? "65%" : "auto",
                    }}>
                      <div className="sidebar-copyright">
                        &copy; {getYear()} ICIAMEX
                      </div>
                    </div>
                  </div> */}
                </div>
              </>
            )}
          </ul>
        </nav>
        {isOpen && (
          <div style={{ marginTop: "20px" }}>
            <button
              onClick={handleLogout}
              style={{
                padding: "15px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                backgroundColor: "#4C5165",
                color: "#e0e0e0",
                borderRadius: "8px",
                fontSize: "14px",
                margin: "0 auto",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#626879")} // Efecto hover más claro
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#4C5165")}
            >
              <i
                style={{ marginRight: "10px", fontSize: "16px" }}
                className="bi bi-box-arrow-in-right"
              ></i>
              Cerrar Sesión
            </button>
          </div>
        )}

        <Outlet />
      </div>
    </div>
  );
};

export default Sidebar;