import React, { useState, useEffect, useRef } from "react";
import PreviaRemision from "./PreviaRemision";
import { useLocation } from "react-router-dom";
import ResumenRemision from "./ResumenRemision";
import BandejaRemisiones from "./BandejaRemisiones";
import Nav from "./Nav";
import RemisionForm from "./RemisionForm";
import {
  collection,
  deleteDoc,
  getFirestore,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import Swal from "sweetalert2";
import Modal from "react-modal";
import moment from "moment";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";

moment.locale("es");

const styleForm = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingLeft: 0,
    padding: 0,
    fontFamily: "Roboto, sans-serif",
    "::WebkitScrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "800px",
    width: "100%",
    height: "550px",
    maxHeight: "90vh",
    overflow: "auto",
    fontFamily: "Roboto, sans-serif", // Aplica la fuente Roboto
  },
  label: {
    display: "block",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  button: {
    width: "50%", // Centra el botón de agregar
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    fontWeight: "700",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function TablaRemisiones({
  remisiones,
  clientes,
  setRemisiones,
  guardarRemision,
  modoEdicion,
  remision,
  numeroRemision,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [remisionSeleccionada, setRemisionSeleccionada] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingRemisiones, setLoadingRemisiones] = useState(false);
  const [selectedRemisiones, setSelectedRemisiones] = useState([]);
  const [ordenamiento, setOrdenamiento] = useState({
    campo: "fechaRemision",
    ascendente: true,
  });
  /*const [showOptions, setShowOptions] = useState(false);*/
  const [selectedRemisionId, setSelectedRemisionId] = useState(null);
  const [resumenVisible, setResumenVisible] = useState(false);
  const [showBandeja, setShowBandeja] = useState(false);
  const location = useLocation();
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [showNuevoButton, setShowNuevoButton] = useState(true);
  const [proximasAVencer, setProximasAVencer] = useState([]);
  const [proximosEventos, setProximosEventos] = useState([]);
  const setRemisionesRef = useRef(setRemisiones);

  // Estado para almacenar el estado de cada remisión
  const [estadosCeldas, setEstadosCeldas] = useState({});

  useEffect(() => {
    if (location.state && location.state.openModal) {
      setMostrarFormulario(true);
    }
  }, [location]);

  // Al cargar la página, obtenemos las remisiones y los estados desde Firestore
  useEffect(() => {
    setLoadingRemisiones(true);
    const firestore = getFirestore();
    const remisionesRef = collection(firestore, "remisiones");

    const unsubscribe = onSnapshot(remisionesRef, (snapshot) => {
      const updatedRemisiones = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Extraemos los estados de cada remisión desde Firestore
      const estados = {};
      updatedRemisiones.forEach((remision) => {
        estados[remision.id] = remision.estado || "Pendiente"; // Asignar 'Pendiente' si no hay estado
      });
      setEstadosCeldas(estados);

      setRemisionesRef.current(updatedRemisiones);
      setLoadingRemisiones(false);
    });

    return () => unsubscribe();
  }, [setRemisiones]);

  // Función para alternar entre los estados de cada celda seleccionada y guardarlo en Firestore
  const toggleEstado = async (remisionId) => {
    const newEstado =
      estadosCeldas[remisionId] === "Pendiente"
        ? "En Proceso"
        : estadosCeldas[remisionId] === "En Proceso"
        ? "Pagada"
        : "Pendiente";

    // Actualizamos el estado localmente
    setEstadosCeldas((prevEstados) => ({
      ...prevEstados,
      [remisionId]: newEstado,
    }));

    // Guardamos el nuevo estado en Firestore
    const firestore = getFirestore();
    const remisionDocRef = doc(firestore, "remisiones", remisionId);
    await updateDoc(remisionDocRef, { estado: newEstado });
  };

  useEffect(() => {
    const fetchCotizaciones = async () => {
      const firestore = getFirestore();
      const cotizacionesRef = collection(firestore, "cotizaciones");
      const unsubscribeCotizaciones = onSnapshot(
        cotizacionesRef,
        (snapshot) => {
          const cotizaciones = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Filtrar las cotizaciones que tienen fecha de vencimiento a partir de hoy y ordenarlas
          const proximas = cotizaciones
            .filter(
              (cotizacion) =>
                moment(cotizacion.fechaVencimiento) >= moment().startOf("day")
            )
            .sort(
              (a, b) => moment(a.fechaVencimiento) - moment(b.fechaVencimiento)
            );

          setProximasAVencer(proximas.slice(0, 6)); // Limitar la lista a 6 fechas próximas
        }
      );

      return () => unsubscribeCotizaciones();
    };

    const fetchEventos = async () => {
      const firestore = getFirestore();
      const eventosRef = collection(firestore, "eventos");
      const unsubscribeEventos = onSnapshot(eventosRef, (snapshot) => {
        const eventos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filtrar eventos que ocurren a partir de hoy y ordenarlos
        const proximos = eventos
          .filter((evento) => moment(evento.to) >= moment().startOf("day"))
          .sort((a, b) => moment(a.to) - moment(b.to));

        setProximosEventos(proximos.slice(0, 6)); // Limitar la lista a 6 eventos próximos

        // Filtrar fechas festivas que están a menos de una semana
        const fechasFestivasProximas = [];
        const fechasFestivasBase = [
          { title: "Año Nuevo", month: "01", day: "01", color: "#de2e03" },
          {
            title: "Día de la Constitución",
            month: "02",
            day: "05",
            color: "#de2e03",
          },
          {
            title: "Natalicio de Benito Juárez",
            month: "03",
            day: "21",
            color: "#de2e03",
          },
          {
            title: "Día del Trabajo",
            month: "05",
            day: "01",
            color: "#de2e03",
          },
          {
            title: "Independencia de México",
            month: "09",
            day: "16",
            color: "#de2e03",
          },
          {
            title: "Transición del Poder Ejecutivo",
            month: "10",
            day: "01",
            color: "#de2e03",
          },
          {
            title: "Revolución Mexicana",
            month: "11",
            day: "20",
            color: "#de2e03",
          },
          { title: "Navidad", month: "12", day: "25", color: "#de2e03" },
        ];

        const today = moment().startOf("day");
        const oneWeekFromNow = moment().add(7, "days").startOf("day");

        fechasFestivasBase.forEach((festivo) => {
          const festivoDate = moment(
            `${today.year()}-${festivo.month}-${festivo.day}`,
            "YYYY-MM-DD"
          );
          if (festivoDate.isBetween(today, oneWeekFromNow, null, "[]")) {
            fechasFestivasProximas.push({
              title: festivo.title,
              start: festivoDate.toDate(),
              end: festivoDate.toDate(),
              allDay: true,
              resource: "festivo",
              style: { backgroundColor: festivo.color },
            });
          }
        });

        if (fechasFestivasProximas.length > 0) {
          // Notificar sobre fechas festivas próximas
          console.log(
            `Fechas festivas próximas: ${fechasFestivasProximas
              .map(
                (festivo) =>
                  `${festivo.title} el ${moment(festivo.start).format("LL")}`
              )
              .join(", ")}`
          );
        }
      });

      return () => unsubscribeEventos();
    };

    // Ejecutar las funciones de carga de cotizaciones y eventos
    fetchCotizaciones();
    fetchEventos();
  }, []);

  function formatDate(dateString) {
    const date = dateString ? new Date(dateString) : new Date(); // Usa la fecha actual si dateString no está definida
    const day = date.getDate().toString().padStart(2, "0");

    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()];

    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const openFormulario = () => {
    setMostrarFormulario(true);
    setShowNuevoButton(false); // Oculta el botón + Nuevo al abrir el formulario
  };

  const closeModal = () => {
    setMostrarFormulario(false);
    setShowNuevoButton(true); // Vuelve a mostrar el botón + Nuevo al cerrar el formulario
  };

  useEffect(() => {
    setRemisionesRef.current = setRemisiones;
  }, [setRemisiones]);

  useEffect(() => {
    setLoadingRemisiones(true);
    const firestore = getFirestore();
    const remisionesRef = collection(firestore, "remisiones");

    const unsubscribe = onSnapshot(remisionesRef, (snapshot) => {
      const updatedRemisiones = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRemisionesRef.current(updatedRemisiones);
      setLoadingRemisiones(false);
    });

    return () => unsubscribe();
  }, []);

  const handleDeleteSelected = async () => {
    try {
      const confirmed = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción eliminará las remisiones seleccionadas.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (confirmed.isConfirmed) {
        const firestore = getFirestore();
        const remisionesEliminadas = [];
        const remisionesRef = collection(firestore, "remisiones");
        for (const remisionId of selectedRemisiones) {
          const remisionDocRef = doc(remisionesRef, remisionId);
          await deleteDoc(remisionDocRef);
          remisionesEliminadas.push(remisionId);
        }
        const remisionesRestantes = remisiones.filter(
          (remision) => !remisionesEliminadas.includes(remision.id)
        );
        setRemisiones(remisionesRestantes);
        setSelectedRemisiones([]);
      }
    } catch (error) {
      console.error("Error al eliminar remisiones:", error);
    }
  };

  /*const abrirModalPrevia = (remision) => {
    setRemisionSeleccionada(remision);
    setModalIsOpen(true);
  };*/

  const cerrarModalPrevia = () => {
    setModalIsOpen(false);
    setRemisionSeleccionada(null)
  };

  const handleRowClick = (remisionId) => {
    setSelectedRemisionId(remisionId);
    setResumenVisible(true);
    setShowBandeja(true);
  };

  const handleSelectRemision = (remisionId) => {
    setSelectedRemisiones((prevSelected) => {
      if (prevSelected.includes(remisionId)) {
        return prevSelected.filter((id) => id !== remisionId);
      } else {
        return [...prevSelected, remisionId];
      }
    });
  };

  const handleOrdenamientoChange = (campo) => {
    setOrdenamiento((prevOrdenamiento) => ({
      campo,
      ascendente:
        campo === prevOrdenamiento.campo ? !prevOrdenamiento.ascendente : true,
    }));
  };

  const ordenarRemisiones = (remisiones, { campo, ascendente }) => {
    if (!remisiones || remisiones.length === 0) {
      return [];
    }

    const sortedRemisiones = [...remisiones];
    switch (campo) {
      case "fechaRemision":
        sortedRemisiones.sort(
          (a, b) =>
            (ascendente ? 1 : -1) *
            (new Date(a.fechaRemision) - new Date(b.fechaRemision))
        );
        break;
      case "numeroRemision":
        sortedRemisiones.sort(
          (a, b) =>
            (ascendente ? 1 : -1) * (a.numeroRemision - b.numeroRemision)
        );
        break;
      case "asunto":
        sortedRemisiones.sort(
          (a, b) => (ascendente ? 1 : -1) * a.asunto.localeCompare(b.asunto)
        );
        break;
      case "nombreCliente":
        sortedRemisiones.sort(
          (a, b) =>
            (ascendente ? 1 : -1) *
            a.nombreCliente.localeCompare(b.nombreCliente)
        );
        break;
      case "total":
        sortedRemisiones.sort(
          (a, b) => (ascendente ? 1 : -1) * (a.total - b.total)
        );
        break;
      case "fechaVencimiento":
        sortedRemisiones.sort(
          (a, b) =>
            (ascendente ? 1 : -1) *
            (new Date(a.fechaVencimiento) - new Date(b.fechaVencimiento))
        );
        break;
      default:
        break;
    }
    return sortedRemisiones;
  };

  const filterRemisiones = () => {
    const remisionesFiltradas =
      remisiones &&
      remisiones.filter((remision) => {
        const searchableFields = [
          remision.fechaRemision,
          remision.numeroRemision?.toString().padStart(4, "0"),
          remision.asunto?.toLowerCase(),
          remision.nombreCliente?.toLowerCase(),
          remision.total?.toString(),
          remision.referencia?.toString(),
        ];
        return searchableFields.some(
          (field) => field && field.includes(searchTerm.toLowerCase())
        );
      });
    return remisionesFiltradas || []; // Devuelve un array vacío si cotizacionesFiltradas es undefined
  };

  // Función para seleccionar/deseleccionar todas las remisiones
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Si se marca el checkbox de "seleccionar todos", selecciona todas las cotizaciones
      const allRemisionesIds = filterRemisiones().map(
        (remision) => remision.id
      );
      setSelectedRemisiones(allRemisionesIds);
    } else {
      // Si se desmarca, vacía la lista de seleccionados
      setSelectedRemisiones([]);
    }
  };

  return (
    <>
      {!showBandeja && !resumenVisible && (
        <>
          <Nav
            handleSearch={setSearchTerm}
            proximasAVencer={proximasAVencer}
            proximosEventos={proximosEventos}
          />

          <div className="task-filters">
            <div className="task-filters-search">
              <i className="bi bi-search search-icon"></i>
              <input
                type="text"
                className="search-input2"
                placeholder="Buscar remisiones"
              />
            </div>
            <div className="task-filters-right">
              <div className="task-filter filter-control">
                <Link href="#" className="filter-link control-link">
                  <i className="bi bi-sliders2-vertical"></i> Filter
                </Link>
              </div>
              <div className="view-buttons-container">
                <button className="view-button grid-view">
                  <i className="bi bi-grid"></i>
                </button>
                <button className="view-button list-view">
                  <i className="bi bi-list"></i>
                </button>
              </div>
              <div>
                {showNuevoButton && (
                  <button className="new-task-button" onClick={openFormulario}>
                    + Nuevo
                  </button>
                )}
              </div>

              <div style={{ marginLeft: "-10px" }}>
                {selectedRemisiones.length > 0 && (
                  <div className="delete-button-container">
                    <button
                      type="button"
                      className="delete-button"
                      onClick={handleDeleteSelected}
                    >
                      Eliminar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="cotizaciones-table">
        {showBandeja && (
          <BandejaRemisiones
            remisiones={remisiones}
            onRowClick={handleRowClick}
            clientes={clientes}
            guardarRemision={guardarRemision}
            remision={remision}
          />
        )}
        {!showBandeja && (
          <div>
            {/*     <i className="bi bi-arrow-down-up image-button" onClick={handleToggleOptions}></i>
            {showOptions && (
              <div className="filtro-options">
                <button onClick={handleSelectAll}>Seleccionar Todos</button>
                <button onClick={handleDeselectAll}>Deseleccionar Todos</button>
              </div>
            )} */}

            {loadingRemisiones ? (
              <Spinner />
            ) : (
              <>
                {filterRemisiones().length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={handleSelectAll} // Controla la selección/desselección de todos los checkboxes
                            className="checkbox-select-all check"
                            checked={
                              selectedRemisiones.length ===
                              filterRemisiones().length
                            } // Checkbox marcado si todas están seleccionadas
                          />
                        </th>
                        <th
                          className="fecha"
                          onClick={() =>
                            handleOrdenamientoChange("fechaRemision")
                          }
                        >
                          Fecha
                        </th>
                        <th
                          className="fecha"
                          onClick={() =>
                            handleOrdenamientoChange("fechaVencimiento")
                          }
                        >
                          Vencimiento
                        </th>
                        <th
                          className="no"
                          onClick={() =>
                            handleOrdenamientoChange("numeroRemision")
                          }
                        >
                          No.
                        </th>
                        {/*     <th onClick={() => handleOrdenamientoChange("asunto")}>
                          Asunto
                        </th>   */}
                        <th
                          className="cliente"
                          onClick={() =>
                            handleOrdenamientoChange("nombreCliente")
                          }
                        >
                          Cliente
                        </th>
                        <th
                          className="estado"
                          onClick={() => handleOrdenamientoChange("estado")}
                        >
                          Estado
                        </th>
                        <th
                          className="importe"
                          onClick={() => handleOrdenamientoChange("total")}
                        >
                          Importe
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordenarRemisiones(filterRemisiones(), ordenamiento).map(
                        (remision, index) => (
                          <tr key={index}>
                            <td className="check">
                              <input
                                type="checkbox"
                                checked={selectedRemisiones.includes(
                                  remision.id
                                )}
                                onChange={() =>
                                  handleSelectRemision(remision.id)
                                }
                                className="checkbox"
                              />
                            </td>
                            <td
                              onClick={() => handleRowClick(remision.id)}
                              className="fecha"
                            >
                              {formatDate(remision.fechaRemision)}
                            </td>
                            <td
                              onClick={() => handleRowClick(remision.id)}
                              className="fecha"
                            >
                              {formatDate(remision.fechaVencimiento)}
                            </td>
                            <td
                              onClick={() => handleRowClick(remision.id)}
                              className="no"
                            >
                              {remision.numeroRemision
                                ?.toString()
                                .padStart(4, "0")}
                            </td>
                            {/*    <td>{remision.asunto}</td>  */}
                            <td
                              onClick={() => handleRowClick(remision.id)}
                              className="cliente"
                            >
                              {remision.nombreCliente}
                            </td>
                            <td>
                              <div
                                className={`status2 ${
                                  estadosCeldas[remision.id] === "Pendiente"
                                    ? "pendiente2"
                                    : estadosCeldas[remision.id] ===
                                      "En Proceso"
                                    ? "en-proceso2"
                                    : "pagada2"
                                }`}
                                onClick={() => toggleEstado(remision.id)} // Usamos el ID único
                              >
                                {estadosCeldas[remision.id]}
                              </div>
                            </td>
                            <td
                              onClick={() => handleRowClick(remision.id)}
                              className="importe"
                            >
                              $
                              {parseFloat(remision.total)
                                ?.toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : (
                  <p style={{ textAlign: "center" }}>
                    No hay resultados disponibles
                  </p>
                )}
              </>
            )}
            <Modal
              isOpen={mostrarFormulario}
              onRequestClose={closeModal}
              contentLabel="Nuevo Cotización"
              style={styleForm}
            >
              <button onClick={closeModal} className="cerrar-button">
                <i className="bi bi-x-lg"></i>
              </button>
              <RemisionForm
                clientes={clientes}
                guardarRemision={guardarRemision}
                modoEdicion={modoEdicion}
                remision={remision}
              />
            </Modal>

            {/*   {showNuevoButton && (
              <button className="action-button2" onClick={openFormulario}>
                + Nuevo
              </button>
            )}   */}
          </div>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={cerrarModalPrevia}
          contentLabel="Vista Previa"
          style={customStyles}
        >
          {remisionSeleccionada && (
            <PreviaRemision
              remision={remisionSeleccionada}
              numeroRemision={remisionSeleccionada.numeroRemision}
              clientes={clientes}
              cerrarPrevia={cerrarModalPrevia}
            />
          )}
        </Modal>

        <div
          className={`resumen-container ${selectedRemisionId ? "active" : ""}`}
        >
          <ResumenRemision
            remision={
              remisiones &&
              remisiones.find((remision) => remision.id === selectedRemisionId)
            }
            isOpen={resumenVisible}
            onClose={() => {
              setResumenVisible(false);
              setSelectedRemisionId(null);
              setShowBandeja(false);
            }}
            numeroRemision={
              remisionSeleccionada ? remisionSeleccionada.numeroRemision : null
            }
            clientes={clientes}
            mostrarBotonNuevo={false}
          />
        </div>
      </div>
    </>
  );
}

export default TablaRemisiones;