import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import OrdenForm from "./OrdenForm";

Modal.setAppElement("#root");

const BandejaOrdenes = ({
  ordenes,
  onRowClick,
  guardarOrden,
  modoEdicion,
  selectedOrdenId,
  orden,
  proveedores,
}) => {
  const itemsPerPageOptions = [10, 25, 50, 100];
  const [filteredOrdenes, setFilteredOrdenes] = useState(ordenes);
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showBulkActionsOptions, setShowBulkActionsOptions] = useState(false);
  const [selectedOrdenes, setSelectedOrdenes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(filteredOrdenes.length / itemsPerPage);

  const paginatedOrdenes = filteredOrdenes.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    const filtered = ordenes.filter((orden) => {
      const formattedImporte = parseFloat(orden?.total)
        ?.toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return (
        orden.nombreProveedor
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        orden.fechaOrden.includes(searchTerm) ||
        orden.numeroOrden.toString().padStart(4, "0").includes(searchTerm) ||
        formattedImporte.includes(searchTerm)
      );
    });
    setFilteredOrdenes(filtered);
    setCurrentPage(1);
  };

  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month} ${year}`;
  }

  const openFormulario = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const toggleBulkActionsOptions = () => {
    setShowBulkActionsOptions(!showBulkActionsOptions);
  };

  const handleSelectOrden = (ordenId) => {
    setSelectedOrdenes((prevSelected) =>
      prevSelected.includes(ordenId)
        ? prevSelected.filter((id) => id !== ordenId)
        : [...prevSelected, ordenId]
    );
  };

  // Checkbox genérico - seleccionar todos
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedOrdenes([]); // Deselecciona todo
    } else {
      setSelectedOrdenes(ordenes.map((orden) => orden.id)); // Selecciona todo
    }
    setSelectAll(!selectAll);
  };

  const showBulkActions = selectedOrdenes.length > 0;

  const exportarPDF = () => {
    console.log("Exportar como PDF");
  };

  const imprimir = () => {
    console.log("Imprimir orden");
  };

  const eliminar = () => {
    console.log("Eliminar orden");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".bulk-actions-right")) {
        setShowBulkActionsOptions(false);
      }
    };
    if (showBulkActionsOptions) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => document.removeEventListener("click", handleClickOutside);
  }, [showBulkActionsOptions]);

  return (
    <div className="cotizaciones-bandeja">
      <div className="bandeja-search-form">
        <div className="bandeja-search-input-wrapper">
          <i className="bi bi-search"></i>
          <input
            type="text"
            className="bandeja-search-input"
            placeholder="Buscar..."
            onChange={handleSearch}
          />
        </div>
      </div>

      {showBulkActions && (
        <div className="bulk-actions">
          <div className="bulk-actions-left">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
              style={{ marginTop: "0", alignSelf: "center" }}
            />
            <p>{selectedOrdenes.length} seleccionados</p>
          </div>

          <div className="bulk-actions-right-container">
            <button className="boton-accion">Marcar como enviado</button>
            <div
              className="bulk-actions-right"
              style={{ position: "relative" }}
            >
              <i
                className="bi bi-three-dots-vertical"
                onClick={toggleBulkActionsOptions}
                style={{ cursor: "pointer" }}
              ></i>
              {showBulkActionsOptions && (
                <div className="dropdown-options-bandeja">
                  <p onClick={exportarPDF}>
                    <i
                      style={{ fontSize: "14px" }}
                      className="bi bi-box-arrow-up-right"
                    ></i>
                    Exportar como PDF
                  </p>
                  <p onClick={imprimir}>
                    <i
                      style={{ fontSize: "14px" }}
                      className="bi bi-printer"
                    ></i>
                    Imprimir
                  </p>
                  <p onClick={eliminar}>
                    <i style={{ fontSize: "14px" }} className="bi bi-trash"></i>
                    Eliminar
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="encabezado">
        <div className="categoria-filtro" onClick={toggleDropdown}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ marginLeft: "10px" }}>Todas las ordenes</p>
            <i
              className="bi bi-chevron-down"
              style={{ color: "#007bff", fontWeight: "bold" }}
            ></i>
          </div>
          {showDropdown && (
            <div className="dropdown-menu">
              <p>
                <i className="bi bi-envelope-check"></i>Ordenes enviadas
              </p>
              <p>
                <i className="bi bi-clock"></i>Ordenes pendientes
              </p>
            </div>
          )}
        </div>
        <div className="acciones-encabezado" style={{ position: "relative" }}>
          <button className="boton-accion" onClick={openFormulario}>
            + Nuevo
          </button>
          <div style={{ position: "relative", display: "inline-block" }}>
            <i
              className="bi bi-three-dots-vertical"
              onClick={toggleOptions}
              style={{ cursor: "pointer" }}
            ></i>
            {showOptions && (
              <div className="dropdown-options-bandeja">
                <p onClick={() => console.log("Ordenar")}>
                  <i className="bi bi-arrow-down-up"></i>Ordenar
                </p>
                <p onClick={() => console.log("Importar")}>
                  <i className="bi bi-box-arrow-in-down"></i>Importar
                </p>
                <p onClick={() => console.log("Exportar")}>
                  <i className="bi bi-box-arrow-up"></i>Exportar
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {paginatedOrdenes.length > 0 ? (
        paginatedOrdenes.map((orden) => (
          <div
            key={orden.id}
            className={`mensaje-cotizacion ${
              selectedOrdenId === orden.id
                ? "mensaje-seleccionado"
                : ""
            }`}
            onClick={() => onRowClick(orden.id)}
          >
            <div className="info-container">
              <input
                type="checkbox"
                onChange={() => handleSelectOrden(orden.id)}
                checked={selectedOrdenes.includes(orden.id)}
              />
              <div className="info-column">
                <p>
                  <span className="cliente-texto">PROVEEDOR:</span>{" "}
                  <span className="cliente-nombre">
                    {orden.nombreProveedor}
                  </span>
                </p>
                <div className="fecha-referencia">
                  <p>
                    No. Orden: {orden.numeroOrden.toString().padStart(4, "0")}
                  </p>
                  <span className="punto">•</span>
                  <p>Fecha: {formatDate(new Date(orden.fechaOrden))}</p>
                </div>
              </div>
              <div className="importe-column">
                <p>
                  Importe: $
                  {parseFloat(orden?.total)
                    ?.toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p style={{ position: "relative", right: "25%" }}>
          No existe ninguna orden que coincida con esta búsqueda.
        </p>
      )}

      <div className="paginador">
        <div className="paginador-opciones">
          <div className="paginador-config">
            <div className="paginador-select-wrapper">
              <i
                className="bi bi-gear-fill"
                style={{ marginRight: "10px" }}
              ></i>
              <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                {itemsPerPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option} por página
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={() => handlePageChange("prev")}
              disabled={currentPage === 1}
            >
              {"<"}
            </button>
            <span>
              {(currentPage - 1) * itemsPerPage + 1} -{" "}
              {Math.min(currentPage * itemsPerPage, filteredOrdenes.length)}
            </span>
            <button
              onClick={() => handlePageChange("next")}
              disabled={currentPage === totalPages}
            >
              {">"}
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Nueva Orden"
        style={{
          overlay: {
            zIndex: 2,
          },
          content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            paddingLeft: 0,
            padding: 0,
            fontFamily: "Roboto, sans-serif",
            "::WebkitScrollbar": {
              width: "0px",
              background: "transparent",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          },
        }}
      >
        <button onClick={closeModal} className="cerrar-button">
          <i className="bi bi-x-lg"></i>
        </button>

        <OrdenForm
          proveedores={proveedores}
          guardarOrden={guardarOrden}
          orden={orden}
        />
      </Modal>
    </div>
  );
};

export default BandejaOrdenes;