import React from "react";

function Paginador({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  onItemsPerPageChange, // Nueva función para manejar el cambio de items por página
}) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    onItemsPerPageChange(Number(e.target.value)); // Cambia el número de elementos por página
    onPageChange(1); // Reinicia a la primera página
  };

  return (
    <div className="paginador-container">
      <div className="paginador-select-container">
        <i className="bi bi-gear-fill" style={{ color: "#7D7E7E" }}></i>
        <select
          className="paginador-option"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          aria-label="Items por página"
        >
          <option value={10}>10 por página</option>
          <option value={25}>25 por página</option>
          <option value={50}>50 por página</option>
          <option value={100}>100 por página</option>
          <option value={200}>200 por página</option>
        </select>
      </div>
      <button
        className="paginador-button"
        onClick={handlePrevious}
        disabled={currentPage === 1}
      >
        <i className="bi bi-chevron-left"></i>
      </button>
      <span className="paginador-info">
        {`${Math.min(
          (currentPage - 1) * itemsPerPage + 1,
          totalItems
        )} - ${Math.min(
          currentPage * itemsPerPage,
          totalItems
        )} de ${totalItems}`}
      </span>
      <button
        className="paginador-button"
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        <i className="bi bi-chevron-right"></i>
      </button>
    </div>
  );
}

export default Paginador;
