import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  collection,
  getDocs,
  serverTimestamp,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import Nav from "./Nav";
import Sidebar from "./Sidebar";
import TablaOrdenes from "./TablaOrdenes";
import Modal from "react-modal";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

moment.locale("es");
registerLocale("es", es);

// Establece la función de inicialización de react-modal para evitar un aviso de desenfoque de accesibilidad
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    maxWidth: "800px",
    width: "100%",
    height: "500px",
    maxHeight: "90vh",
    overflow: "auto",
    fontFamily: "Roboto, sans-serif", // Aplica la fuente Roboto
  },
  label: {
    display: "block",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  button: {
    width: "50%", // Centra el botón de agregar
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    fontWeight: "700",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function OrdenForm(props) {
  const [comprador, setComprador] = useState("");
  const [incoterm, setInconterm] = useState("");
  const [divisas, setDivisas] = useState("");
  const [condicion, setCondicion] = useState("");
  const [proveedor, setProveedor] = useState("");
  const [proveedores, setProveedores] = useState([]);
  const [productos, setProductos] = useState([]);
  const [asunto, setAsunto] = useState("");
  const [fechaVencimiento, setFechaVencimiento] = useState("");
  const [productosSeleccionados, setProductosSeleccionados] = useState([]);
  const [mostrarTabla, setMostrarTabla] = useState(false);
  const [mostrarPrevia, setMostrarPrevia] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [estado, setEstado] = useState("pendiente");
  const { orden, guardarOrden, } = props;
  const [ordenes, setOrdenes] = useState([]);
  const [formDirty, setFormDirty] = useState(false);
  const [modoEdicion, setModoEdicion] = useState(false);
  const [numeroOrden, setNumeroOrden] = useState("");
  const [fechaOrden, setFechaOrden] = useState(new Date());
  const [divisa, setDivisa] = useState("MXN");
  const [notas, setNotas] = useState("");
  const [descuento, setDescuento] = useState(0);
  const [proximasAVencer, setProximasAVencer] = useState([]);
  const [proximosEventos, setProximosEventos] = useState([]);
  const [events, setEvents] = useState([]);
  const [datosSeleccionados, setDatosSeleccionados] = useState([
    {
      id: uuidv4(),
      nombre: "",
      modelo: "",
      cantidad: 1,
      tarifa: 0,
      iva: 0,
      descuento: 0, // Nuevo campo
      tipoDescuento: "%", // Nuevo campo
      importe: 0,
    },
  ]);
  const [terminos, setTerminos] = useState("");
  

  useEffect(() => {
    // Si recibimos una cotización para editar, llenamos el formulario con sus datos
    if (orden) {
      setModoEdicion(true);
      // Llenar el formulario con los datos de la cotización
      setFechaOrden(orden.fechaOrden ? new Date(orden.fechaOrden) : new Date());
      setProveedor(orden.proveedor);
      setComprador(orden.comprador);
      setAsunto(orden.asunto);
      setFechaVencimiento(orden.fechaVencimiento);
      setEstado(orden.estado);
      setInconterm(orden.incoterm);
      setDivisas(orden.divisas);
      setCondicion(orden.condicion);
      setProductosSeleccionados(orden.productosSeleccionados);
      // Resto de los campos...
    }
  }, [orden]);

  useEffect(() => {
    const fetchCotizaciones = async () => {
      const firestore = getFirestore();
      const cotizacionesRef = collection(firestore, "cotizaciones");
      const unsubscribeCotizaciones = onSnapshot(
        cotizacionesRef,
        (snapshot) => {
          const cotizaciones = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Filtrar y ordenar cotizaciones
          const proximas = cotizaciones
            .filter((cotizacion) =>
              moment(cotizacion.fechaVencimiento).isSameOrAfter(
                moment().startOf("day")
              )
            )
            .sort(
              (a, b) => moment(a.fechaVencimiento) - moment(b.fechaVencimiento)
            );

          setProximasAVencer(proximas.slice(0, 6)); // Limitar la lista a 6 fechas próximas

          // Crear eventos para cotizaciones
          const cotizacionesEvents = proximas.map((cotizacion) => ({
            id: `cotizacion-${cotizacion.id}`,
            title: `Cotización #${cotizacion.numeroCotizacion}`,
            start: moment(cotizacion.fechaVencimiento).startOf("day").toDate(),
            end: moment(cotizacion.fechaVencimiento).endOf("day").toDate(),
            allDay: true,
            resource: "cotizacion",
            style: {
              backgroundColor: "blue",
              padding: "auto",
              fontSize: "12px",
              width: "80%",
              margin: "0 auto",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "auto",
            },
          }));

          // Actualizar el estado de eventos
          setEvents((prevEvents) => {
            const eventMap = new Map(
              prevEvents
                .filter((event) => !event.id.startsWith("cotizacion-"))
                .map((event) => [event.id, event])
            );
            cotizacionesEvents.forEach((event) =>
              eventMap.set(event.id, event)
            );
            return Array.from(eventMap.values());
          });
        }
      );

      return () => unsubscribeCotizaciones();
    };

    const fetchEventos = async () => {
      const firestore = getFirestore();
      const eventosRef = collection(firestore, "eventos");
      const unsubscribeEventos = onSnapshot(eventosRef, (snapshot) => {
        const eventos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filtrar y ordenar eventos
        const proximos = eventos
          .filter((evento) =>
            moment(evento.to).isSameOrAfter(moment().startOf("day"))
          )
          .sort((a, b) => moment(a.to) - moment(b.to));

        setProximosEventos(proximos.slice(0, 6)); // Limitar la lista a 6 eventos próximos

        // Crear eventos para el calendario
        const eventosCalendario = proximos.map((evento) => ({
          id: `evento-${evento.id}`,
          title: evento.title,
          start: moment(evento.to).startOf("day").toDate(),
          end: moment(evento.to).endOf("day").toDate(),
          allDay: true,
          resource: "evento",
          style: {
            backgroundColor: "#229954",
            padding: "auto",
            fontSize: "12px",
            width: "80%",
            margin: "0 auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: "auto",
          },
        }));

        // Actualizar el estado de eventos
        setEvents((prevEvents) => {
          const eventMap = new Map(
            prevEvents
              .filter((event) => !event.id.startsWith("evento-"))
              .map((event) => [event.id, event])
          );
          eventosCalendario.forEach((event) => eventMap.set(event.id, event));
          return Array.from(eventMap.values());
        });
      });

      return () => unsubscribeEventos();
    };

    // Obtener fechas festivas
    const getFechasFestivas = () => {
      const fechasFestivasBase = [
        { title: "Año Nuevo", month: "01", day: "01", color: "#de2e03" },
        {
          title: "Día de la Constitución",
          month: "02",
          day: "05",
          color: "#de2e03",
        },
        {
          title: "Natalicio de Benito Juárez",
          month: "03",
          day: "21",
          color: "#de2e03",
        },
        { title: "Día del Trabajo", month: "05", day: "01", color: "#de2e03" },
        {
          title: "Independencia de México",
          month: "09",
          day: "16",
          color: "#de2e03",
        },
        {
          title: "Transición del Poder Ejecutivo",
          month: "10",
          day: "01",
          color: "#de2e03",
        },
        {
          title: "Revolución Mexicana",
          month: "11",
          day: "20",
          color: "#de2e03",
        },
        { title: "Navidad", month: "12", day: "25", color: "#de2e03" },
      ];

      const generarFechasFestivas = (años) => {
        const fechasFestivas = [];
        años.forEach((año) => {
          fechasFestivasBase.forEach((festivo) => {
            const fecha = moment(
              `${año}-${festivo.month}-${festivo.day}`,
              "YYYY-MM-DD"
            ).toDate();
            fechasFestivas.push({
              id: `festivo-${festivo.title}-${año}`,
              title: festivo.title,
              start: fecha,
              end: fecha,
              allDay: true,
              resource: "festivo",
              style: {
                backgroundColor: "#de2e03",
                padding: "auto",
                fontSize: "12px",
                width: "80%",
                margin: "0 auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "auto",
              },
            });
          });
        });
        return fechasFestivas;
      };

      const años = Array.from(
        { length: 6 },
        (_, i) => new Date().getFullYear() + i
      );
      return generarFechasFestivas(años);
    };

    // Obtener fechas festivas y fusionarlas con los eventos existentes
    const fechasFestivas = getFechasFestivas();
    setEvents((prevEvents) => [
      ...prevEvents.filter((event) => !event.id.startsWith("festivo-")),
      ...fechasFestivas,
    ]);

    // Ejecutar las funciones de carga de cotizaciones y eventos
    fetchCotizaciones();
    fetchEventos();
  }, []);

  const manejarCambioDescuento = (e) => {
    let valor = e.target.value;

    // Permitir solo valores numéricos y entre 0 y 100
    if (/^\d*\.?\d*$/.test(valor)) {
      let valorNumerico = parseFloat(valor);
      if (isNaN(valorNumerico)) {
        setDescuento(0);
      } else {
        if (valorNumerico > 100) valorNumerico = 100;
        setDescuento(valorNumerico);
      }
    }
  };

  const handleCancel = () => {
    if (formDirty) {
      Swal.fire({
        title: "¿Seguro que quieres salir?",
        text: "Se perderán los cambios realizados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, salir",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          // Recargar la página al confirmar la salida
          window.location.reload();
        }
      });
    } else {
      // Si no hay cambios, salir sin confirmación
      window.location.reload(); // Recargar la página directamente si no hay cambios
    }
  };

  useEffect(() => {
    obtenerProveedores();
    obtenerProductos();
  }, []);

  const calcularSubtotal = () => {
    if (!datosSeleccionados || datosSeleccionados.length === 0) return 0;

    return datosSeleccionados.reduce((total, producto) => {
      const tarifa = parseFloat(producto.tarifa) || 0;
      const cantidad = parseInt(producto.cantidad) || 0;
      const descuento = parseFloat(producto.descuento) || 0;

      const descuentoAplicado =
        producto.tipoDescuento === "%"
          ? tarifa * cantidad * (descuento / 100)
          : descuento;

      return total + tarifa * cantidad - descuentoAplicado;
    }, 0);
  };

  const calcularDescuentoGlobal = () => {
    return (calcularSubtotal() * (descuento || 0)) / 100;
  };

  const calcularIVA = () => {
    const montoDespuesDescuento =
      calcularSubtotal() - calcularDescuentoGlobal();
    return montoDespuesDescuento * obtenerIVA();
  };

  const cerrarModalPrevia = () => {
    setMostrarPrevia(false);
    setModalIsOpen(false);
  };

  const calcularSubtotalConDescuento = () => {
    const subtotal = calcularSubtotal();
    return subtotal > 0 ? subtotal - (subtotal * descuento) / 100 : 0;
  };

  const calcularTotal = () => {
    const subtotalConDescuento = calcularSubtotalConDescuento();
    const ivaCalculado = calcularIVA();
    return subtotalConDescuento + ivaCalculado;
  };

  const actualizarCantidad = (productoId, nuevaCantidad) => {
    setDatosSeleccionados((prevDatos) =>
      prevDatos.map((item) =>
        item.id === productoId
          ? {
              ...item,
              cantidad: nuevaCantidad,
              importe: item.tarifa * nuevaCantidad, // Solo calcula el importe sin aplicar IVA aquí
            }
          : item
      )
    );
  };

  const obtenerProveedores = async () => {
    try {
      const proveedoresSnapshot = await getDocs(collection(db, "proveedores"));
      const listaProveedores = proveedoresSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProveedores(listaProveedores);
    } catch (error) {
      console.error("Error al obtener proveedores:", error);
    }
  };

  const obtenerProductos = async () => {
    try {
      const productosSnapshot = await getDocs(collection(db, "productos"));
      const listaProductos = productosSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        tipoDescuento: "cantidad",
        valorDescuento: 0,
      }));
      setProductos(listaProductos);
    } catch (error) {
      console.error("Error al obtener los productos:", error);
    }
  };

  const continuarDesdePrevia = () => {
    setMostrarTabla(true);
  };

  const eliminarProducto = (id) => {
    setDatosSeleccionados((prevDatos) => {
      const nuevosDatos = prevDatos.filter((producto) => producto.id !== id);
      return nuevosDatos.length > 0 ? nuevosDatos : [];
    });
  };


  const guardar = async () => {
    try {
      // Validación de campos obligatorios
      if (!proveedor || !fechaOrden || !numeroOrden) {
        Swal.fire({
          icon: "warning",
          title: "Campos Incompletos",
          text: "Por favor, complete los campos obligatorios: Proveedor, Fecha de Orden y Número de Orden.",
          confirmButtonColor: "#007bff",
          confirmButtonText: "Aceptar",
        });
        return;
      }
  
      // Obtener el nombre del proveedor
      const proveedorSeleccionado = proveedores.find((p) => p.id === proveedor);
      const nombreProveedor = proveedorSeleccionado ? proveedorSeleccionado.empresa : "";
  
      // Preparar datos de la orden
      const ordenData = {
        proveedor,
        nombreProveedor,
        numeroOrden,
        comprador,
        fechaOrden: fechaOrden ? fechaOrden.toISOString().split("T")[0] : "",
        fechaVencimiento: fechaVencimiento ? fechaVencimiento.toISOString().split("T")[0] : "",
        estado,
        incoterm,
        divisas,
        condicion,
        notas,
        terminos,
        productosSeleccionados: datosSeleccionados,
        descuento,
        total: calcularTotal(),
        createdAt: serverTimestamp(),
      };
  
      // Llamar a la función guardarOrden del padre, pasando si es modoEdicion y ordenId
      await guardarOrden(ordenData, modoEdicion, orden && orden.id);
  
      Swal.fire({
        icon: "success",
        title: modoEdicion ? "Orden actualizada correctamente" : "Orden guardada correctamente",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.reload();
      });
  
      // Mostrar la tabla con la nueva orden
      setMostrarTabla(true);
  
    } catch (error) {
      console.error("Error al guardar la orden:", error);
      Swal.fire({
        icon: "error",
        title: "Error al guardar la orden",
        text: error.message,
      });
    }
  };

  const handleInputChange = (e, productoId) => {
    const inputValue = e.target.value;
    setFormDirty(true); // Marcar que el formulario ha sido modificado

    // Encuentra el producto en la lista de productos reales desde Firestore
    const productoEncontrado = productos.find(
      (prod) => prod.nombre === inputValue
    );

    if (productoEncontrado) {
      setDatosSeleccionados((prevDatos) => {
        // Verifica si el producto ya existe en otra fila distinta a la que se está editando
        const existingItem = prevDatos.find(
          (item) =>
            item.nombre === productoEncontrado.nombre && item.id !== productoId
        );

        if (existingItem) {
          // Si el producto ya existe, sumamos las cantidades
          const currentItem = prevDatos.find((item) => item.id === productoId);
          const nuevaCantidad =
            (existingItem.cantidad || 0) + (currentItem.cantidad || 1);

          // Actualizamos el producto existente con la nueva cantidad e importe
          const nuevosDatos = prevDatos.map((item) => {
            if (item.id === existingItem.id) {
              return {
                ...item,
                cantidad: nuevaCantidad,
                importe: productoEncontrado.costo * nuevaCantidad,
              };
            }
            return item;
          });

          // Eliminamos la fila actual (para no duplicar)
          return nuevosDatos.filter((item) => item.id !== productoId);
        } else {
          // Si el producto no existe en otra fila, simplemente actualizamos la fila actual
          return prevDatos.map((item) =>
            item.id === productoId
              ? {
                  ...item,
                  nombre: productoEncontrado.nombre,
                  descripcion: productoEncontrado["Item Desc"] || "",
                  numeroDeParte: productoEncontrado.numeroDeParte,
                  tarifa: productoEncontrado.costo,
                  iva: 0.16,
                  importe: productoEncontrado.costo * item.cantidad,
                }
              : item
          );
        }
      });
    } else {
      // Producto ingresado manualmente (no encontrado en Firestore)
      setDatosSeleccionados((prevDatos) => {
        // Verificar si ya existe un producto con el mismo nombre manualmente
        const existingManualItem = prevDatos.find(
          (item) => item.nombre === inputValue && item.id !== productoId
        );

        if (existingManualItem) {
          // Si ya existe el producto manual, sumamos las cantidades
          const currentItem = prevDatos.find((item) => item.id === productoId);
          const nuevaCantidad =
            (existingManualItem.cantidad || 0) + (currentItem.cantidad || 1);

          // Actualizamos el item existente con la nueva cantidad
          const nuevosDatos = prevDatos.map((item) => {
            if (item.id === existingManualItem.id) {
              return {
                ...item,
                cantidad: nuevaCantidad,
                // Suponemos que el item existente ya tiene una tarifa definida (manual).
                importe: (item.tarifa || 0) * nuevaCantidad,
              };
            }
            return item;
          });

          // Eliminamos la fila actual (para no duplicar)
          return nuevosDatos.filter((item) => item.id !== productoId);
        } else {
          // Si no existe ningún otro producto manual con ese nombre, solo actualizamos el campo nombre
          return prevDatos.map((item) =>
            item.id === productoId ? { ...item, nombre: inputValue } : item
          );
        }
      });
    }
  };

  const añadirFila = (e) => {
    e.preventDefault(); // Evita el comportamiento por defecto del botón
    const nuevaFila = {
      id: uuidv4(),
      nombre: "",
      numeroDeParte: "",
      cantidad: 1,
      tarifa: 0,
      iva: 0.16, // Asegura que el IVA tenga un valor predeterminado
      descuento: 0, // Asegura que el descuento tenga un valor inicial
      tipoDescuento: "%", // Tipo de descuento inicial
      importe: 0, // Importe inicial en 0
    };

    setDatosSeleccionados((prevDatos) => [...prevDatos, nuevaFila]);
  };

  const agregarArticulosAGranel = (articulos) => {
    // Lógica para agregar artículos a granel
    console.log("Agregando artículos a granel:", articulos);
  };

  const obtenerIVA = () => {
    // Valor predeterminado de IVA
    const VALOR_IVA_DEFAULT = 0.16;

    // Retornar el IVA del primer elemento si está disponible; de lo contrario, usar el predeterminado
    if (datosSeleccionados.length > 0 && datosSeleccionados[0]?.iva) {
      return datosSeleccionados[0].iva;
    }

    return VALOR_IVA_DEFAULT;
  };

  return (
    <>
      <Nav
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
        events={events}
      />

      <Sidebar />

      <div className="cotizacion-form">
        <form>
          <div>
            <div className="group apartado">
              <label htmlFor="proveedor" className="required">
                Proveedor*
              </label>
              <select
                id="proveedor"
                name="proveedor"
                value={proveedor}
                onChange={(e) => {
                  setProveedor(e.target.value);
                  setFormDirty(true); // Marcar que el formulario ha sido modificado
                }}
              >
                <option value="">Seleccionar Proveedor</option>
                {proveedores.map((proveedor) => (
                  <option key={proveedor.id} value={proveedor.id}>
                    {proveedor.empresa}
                  </option>
                ))}
              </select>
            </div>

            <br />

            <div className="group">
              <label htmlFor="numeroOrden" className="required">
                No. Orden#*
              </label>
              <input
                type="text"
                id="numeroOrden"
                name="numeroOrden"
                value={numeroOrden}
                onChange={(e) => {
                  setNumeroOrden(e.target.value);
                  setFormDirty(true); // Marcar que el formulario ha sido modificado
                }}
              />
            </div>

            <div className="group">
              <label htmlFor="comprador">Comprador:</label>
              <input
                type="text"
                id="comprador"
                name="comprador"
                value={comprador}
                onChange={(e) => {
                  setComprador(e.target.value);
                  setFormDirty(true); // Marcar que el formulario ha sido modificado
                }}
              />
            </div>

            <div className="group fechas">
              <div className="group">
                <label htmlFor="fecha-orden" className="required">
                  Fecha de orden*
                </label>
                <DatePicker
                  selected={fechaOrden}
                  onChange={(date) => {
                    setFechaOrden(date);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                  dateFormat="dd MMM yyyy"
                  placeholderText="dd MMM yyyy"
                  id="fecha-cotizacion"
                  locale="es"
                />
              </div>
              <div className="group">
                <label htmlFor="fecha-vencimiento">Fecha de vencimiento</label>
                <div>
                  <DatePicker
                    selected={fechaVencimiento}
                    onChange={(date) => {
                      setFechaVencimiento(date);
                      setFormDirty(true); // Marcar el formulario como modificado
                    }}
                    dateFormat="dd MMM yyyy"
                    placeholderText="dd MMM yyyy"
                    id="fecha-vencimiento"
                    locale="es"
                  />
                </div>
              </div>
            </div>

            <hr
              style={{
                marginBottom: "30px",
                marginTop: "20px",
                border: "1px solid #f9f9fb",
              }}
            />

            <div className="group fechas">
              <div className="group">
                <label htmlFor="incoterm">Incoterms:</label>
                <select
                  id="incoterm"
                  name="incoterm"
                  value={incoterm}
                  onChange={(e) => setInconterm(e.target.value)}
                  style={{ width: "42%" }}
                >
                  <option value="exw">EXW</option>
                  <option value="fob">FOB</option>
                  <option value="cif">CIF</option>
                  <option value="dap">DAP</option>
                  <option value="ddp">DDP</option>
                </select>
              </div>

              <div className="group">
                <label htmlFor="divisa">Divisa</label>
                <select
                  id="divisa"
                  name="divisa"
                  value={divisa}
                  onChange={(e) => {
                    setDivisa(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                  style={{ width: "41%" }}
                >
                  <option value="MXN">MXN</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>

            <div className="group fechas">
              <div className="group">
                <label htmlFor="condicion">Condición de Pago:</label>
                <input
                  type="text"
                  id="condicion"
                  name="condicion"
                  value={condicion}
                  onChange={(e) => setCondicion(e.target.value)}
                />
                <br />
              </div>
            </div>

            <br />

            <div className="apartado2">Tabla de productos</div>

            <div>
              {/* Contenedor para la tabla y los botones */}
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {/* Tabla general */}
                <div>
                  <div>
                    <table className="productos-table">
                      <thead>
                        <tr>
                          <th>Detalles del Artículo</th>
                          <th style={{ textAlign: "center" }}>Modelo</th>
                          <th id="cantidad-col">Cantidad</th>
                          <th id="precio-col">Precio</th>
                          <th id="descuento-col">Descuento</th>
                          <th id="importe-col">Importe</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datosSeleccionados.map((producto) => (
                          <tr key={producto.id}>
                            <td>
                              <input
                                id="list-products"
                                list="productos-list"
                                value={producto.descripcion || producto.nombre}
                                onChange={(e) => {
                                  handleInputChange(e, producto.id);
                                  setFormDirty(true); // Marcar el formulario como modificado
                                }}
                                placeholder="Escriba o haga clic para seleccionar un artículo"
                                style={{ width: "600px", height: "100px" }}
                                autoComplete="off"
                              />
                              <datalist id="productos-list">
                                {productos.map((prod) => (
                                  <option key={prod.id} value={prod.nombre} />
                                ))}
                              </datalist>
                            </td>
                            <td>
                              <input
                                type="text"
                                value={
                                  producto.modelo ||
                                  producto.numeroDeParte ||
                                  ""
                                }
                                onChange={(e) => {
                                  const nuevoModelo = e.target.value;
                                  setDatosSeleccionados((prevDatos) =>
                                    prevDatos.map((item) =>
                                      item.id === producto.id
                                        ? { ...item, modelo: nuevoModelo }
                                        : item
                                    )
                                  );
                                  setFormDirty(true); // Marcar el formulario como modificado
                                }}
                              />
                            </td>

                            <td id="cantidad-col">
                              <input
                                type="number"
                                value={producto.cantidad || 1}
                                onChange={(e) => {
                                  actualizarCantidad(
                                    producto.id,
                                    parseInt(e.target.value) || 0
                                  );
                                  setFormDirty(true); // Marcar el formulario como modificado
                                }}
                              />
                            </td>
                            <td id="precio-col">
                              <input
                                type="text"
                                value={
                                  producto.tarifa !== undefined
                                    ? producto.tarifa
                                    : ""
                                }
                                onChange={(e) => {
                                  const nuevoValor = e.target.value;
                                  // Permitir solo números y punto decimal
                                  if (/^\d*\.?\d*$/.test(nuevoValor)) {
                                    setDatosSeleccionados((prevDatos) =>
                                      prevDatos.map((item) =>
                                        item.id === producto.id
                                          ? {
                                              ...item,
                                              tarifa: nuevoValor, // Guardar como cadena para evitar problemas de cursor
                                              importe:
                                                parseFloat(nuevoValor) *
                                                  (item.cantidad || 0) || 0,
                                            }
                                          : item
                                      )
                                    );
                                    setFormDirty(true); // Marcar el formulario como modificado
                                  }
                                }}
                                onBlur={(e) => {
                                  const tarifaNumerica = parseFloat(
                                    e.target.value.replace(/,/g, "")
                                  );
                                  if (!isNaN(tarifaNumerica)) {
                                    setDatosSeleccionados((prevDatos) =>
                                      prevDatos.map((item) =>
                                        item.id === producto.id
                                          ? {
                                              ...item,
                                              tarifa: tarifaNumerica.toFixed(2), // Formatear a dos decimales
                                              importe: (
                                                tarifaNumerica *
                                                (item.cantidad || 0)
                                              ).toFixed(2),
                                            }
                                          : item
                                      )
                                    );
                                  } else {
                                    setDatosSeleccionados((prevDatos) =>
                                      prevDatos.map((item) =>
                                        item.id === producto.id
                                          ? {
                                              ...item,
                                              tarifa: "0.00",
                                              importe: "0.00",
                                            }
                                          : item
                                      )
                                    );
                                  }
                                }}
                                onFocus={(e) => {
                                  // Opcional: Limpiar el campo al enfocarlo si está formateado
                                  if (e.target.value === "0.00") {
                                    setDatosSeleccionados((prevDatos) =>
                                      prevDatos.map((item) =>
                                        item.id === producto.id
                                          ? { ...item, tarifa: "" }
                                          : item
                                      )
                                    );
                                  }
                                }}
                                style={{
                                  width: "100%",
                                  textAlign: "right",
                                  padding: "8px",
                                  fontSize: "14px",
                                  borderRadius: "4px",
                                }}
                              />
                            </td>

                            <td id="descuento-col">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  overflow: "hidden",
                                  margin: "0 auto",
                                  width: "100%", // El contenedor ocupa todo el ancho de la celda
                                }}
                              >
                                {/* Input para el descuento */}
                                <input
                                  type="text"
                                  min="0"
                                  step="1"
                                  value={producto.descuento || 0}
                                  onChange={(e) => {
                                    const valor = e.target.value;
                                    if (/^\d*\.?\d*$/.test(valor)) {
                                      let nuevoDescuento = parseFloat(valor);
                                      if (isNaN(nuevoDescuento)) {
                                        nuevoDescuento = 0;
                                      } else if (nuevoDescuento > 100) {
                                        nuevoDescuento = 100;
                                      }
                                      setDatosSeleccionados((prevDatos) =>
                                        prevDatos.map((item) =>
                                          item.id === producto.id
                                            ? {
                                                ...item,
                                                descuento: nuevoDescuento,
                                                importe:
                                                  item.tipoDescuento === "%"
                                                    ? item.tarifa *
                                                      item.cantidad *
                                                      (1 - nuevoDescuento / 100)
                                                    : item.tarifa *
                                                        item.cantidad -
                                                      nuevoDescuento,
                                              }
                                            : item
                                        )
                                      );
                                      setFormDirty(true);
                                    }
                                  }}
                                  style={{
                                    flex: 1, // El input se expande todo lo posible
                                    outline: "none",
                                    textAlign: "right",
                                    padding: "8px",
                                    fontSize: "14px",
                                    backgroundColor: "#fff",
                                    color: "#333",
                                    borderRadius: "0",
                                  }}
                                />

                                {/* Select para el tipo de descuento */}
                                <select
                                  value={producto.tipoDescuento || "%"}
                                  onChange={(e) => {
                                    const nuevoTipo = e.target.value;
                                    setDatosSeleccionados((prevDatos) =>
                                      prevDatos.map((item) =>
                                        item.id === producto.id
                                          ? {
                                              ...item,
                                              tipoDescuento: nuevoTipo,
                                              importe:
                                                nuevoTipo === "%"
                                                  ? item.tarifa *
                                                    item.cantidad *
                                                    (1 - item.descuento / 100)
                                                  : item.tarifa *
                                                      item.cantidad -
                                                    item.descuento,
                                            }
                                          : item
                                      )
                                    );
                                    setFormDirty(true);
                                  }}
                                  style={{
                                    flex: "0 0 50px", // Select con ancho fijo más estrecho
                                    border: "1px solid #ccc",
                                    outline: "none",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    padding: "7.5px",
                                    cursor: "pointer",
                                    backgroundColor: "#f4f4f4",
                                    color: "#333",
                                    borderRadius: "0",
                                    borderLeft: "none", // Quita el borde izquierdo para que se vea unido al input
                                  }}
                                >
                                  <option value="%">%</option>
                                  <option value="$">$</option>
                                </select>
                              </div>
                            </td>

                            <td
                              id="importe-col"
                              style={{
                                textAlign: "right",
                                paddingRight: "10px",
                              }}
                            >
                              $
                              {producto.importe.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || "0.00"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Contenedor para los botones */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                    marginLeft: "10px",
                  }}
                >
                  {datosSeleccionados.map((producto) => (
                    <div
                      key={producto.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "100px",
                      }}
                    >
                      {/* Botón de más opciones */}
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          padding: "0",
                          cursor: "pointer",
                          color: "#000",
                        }}
                      >
                        <i
                          className="bi bi-three-dots-vertical"
                          style={{ fontSize: "1.2rem" }}
                        ></i>
                      </button>

                      {/* Botón de eliminar */}
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          padding: "0",
                          cursor: "pointer",
                          color: "#dc3545", // Color rojo para el ícono
                        }}
                        onClick={() => eliminarProducto(producto.id)}
                      >
                        <i
                          className="bi bi-x-lg"
                          style={{ fontSize: "1.2rem" }}
                        ></i>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="acciones-tabla">
              <button onClick={añadirFila}>
                <i
                  style={{ color: "#007bff", fontSize: "16px" }}
                  className="bi bi-plus-circle-fill"
                ></i>
                Añadir nueva fila
              </button>
              <button onClick={agregarArticulosAGranel}>
                <i
                  style={{ color: "#007bff", fontSize: "16px" }}
                  className="bi bi-plus-circle-fill"
                ></i>
                Agregar artículos a granel
              </button>
            </div>

            <div className="data-container">
              <div className="notes-container">
                <label htmlFor="customer-notes">Notas del Proveedor</label>
                <textarea
                  id="customer-notes"
                  placeholder="Escribe tus notas aquí..."
                  value={notas}
                  onChange={(e) => {
                    setNotas(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                ></textarea>
              </div>

              <div className="totales-container">
                <div className="row">
                  <span className="label">Subtotal</span>
                  <span>
                    $
                    {calcularSubtotal().toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>

                <div className="row descuento">
                  <span className="label">Descuento</span>
                  <div className="descuento-input-container">
                    <input
                      type="text"
                      className="descuento-input"
                      value={descuento}
                      onChange={manejarCambioDescuento}
                    />
                    <span className="percentage-sign">%</span>
                  </div>
                  <span className="discount-percentage">
                    - $
                    {((calcularSubtotal() * descuento) / 100).toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </span>
                </div>

                <div className="row iva">
                  <span className="label">
                    Tipo estándar [{(obtenerIVA() * 100).toFixed(0)}%]
                  </span>
                  <span>
                    $
                    {(
                      (calcularSubtotal() -
                        calcularSubtotal() * (descuento / 100)) *
                      obtenerIVA()
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>

                <hr />

                <div className="row total">
                  <span>Total ({divisa})</span>
                  <span className="total-amount">
                    $
                    {calcularTotal().toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                backgroundColor: "#f9f9fb",
                padding: "8px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "10px",
                width: "94%",
                marginTop: "30px",
                paddingBottom: "30px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Left Section: Términos y Condiciones */}
              <div className="terminos-container">
                <h4>Términos y condiciones</h4>
                <textarea
                  value={terminos}
                  onChange={(e) => {
                    setTerminos(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                />
              </div>

              {/* Right Section: File Upload */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                  flex: "0 0 530px",
                  marginTop: "30px",
                }}
              >
                <label style={{ fontSize: "14px" }}>
                  Adjuntar archivo(s) a Estimación
                </label>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px dashed #ccc",
                    borderRadius: "5px",
                    overflow: "hidden", // Eliminar cualquier contenido que se salga del borde
                  }}
                >
                  <label
                    style={{
                      padding: "8px 10px",
                      backgroundColor: "#fff",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      cursor: "pointer",
                      whiteSpace: "nowrap", // Evitar que el contenido del botón se ajuste en varias líneas
                    }}
                  >
                    <i style={{ color: "#000" }} className="bi bi-upload"></i>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                        fontWeight: "lighter",
                      }}
                    >
                      Cargar archivo
                    </span>
                    <input
                      type="file"
                      style={{ display: "none" }} // Ocultar el input de archivo
                      multiple
                    />
                  </label>

                  <button
                    style={{
                      padding: "10px",
                      backgroundColor: "#fff",
                      borderLeft: "1px dashed #ccc", // Borde que conecta con el input
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      borderTopRightRadius: "auto", // Bordes redondeados en la esquina derecha
                      borderBottomRightRadius: "auto",
                      width: "10px", // Dejar que el botón ajuste su tamaño
                      minWidth: "40px", // Evitar que el botón se haga demasiado pequeño
                      margin: "0", // Eliminar cualquier margen que agregue espacio extra
                    }}
                  >
                    <i
                      style={{
                        color: "#000",
                        fontSize: "14px", // Ajustar el tamaño del ícono
                      }}
                      className="bi bi-chevron-down"
                    ></i>
                  </button>
                </div>

                <p style={{ fontSize: "12px", color: "#888" }}>
                  Puede cargar un máximo de 3 archivos, cada uno de 10 MB
                </p>
              </div>
            </div>
          </div>

          {/* Grupo de botones: Guardar como borrador, Guardar y enviar, Cancelar */}
          <div className="cotizacion-toolbar">
            <div className="btn-group">
              <button
                id="guardar-enviar"
                className="btn btn-primary"
                type="button"
                onClick={guardar}
              >
                {modoEdicion ? "Actualizar" : "Guardar y enviar"}
              </button>
              <button
                id="cancelar"
                className="btn btn-secondary"
                type="button"
                onClick={handleCancel}
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={cerrarModalPrevia}
          contentLabel="Vista Previa"
          style={customStyles}
        >
          {mostrarPrevia && (
            <PreviaOrden
              proveedor={proveedor}
              proveedores={proveedores}
              asunto={asunto}
              fechaOrden={fechaOrden}
              productosSeleccionados={productosSeleccionados}
              continuarDesdePrevia={continuarDesdePrevia}
              numeroOrden={props.numeroOrden}
            />
          )}
          <div className="modal-buttons">
            <button onClick={cerrarModalPrevia} className="eliminarBtnModal">
              Cerrar
            </button>
          </div>
        </Modal>

        {mostrarTabla && <TablaOrdenes ordenes={ordenes} setOrdenes={setOrdenes}  />}
      </div>
    </>
  );
}

function PreviaOrden({
  proveedor,
  proveedores,
  asunto,
  fechaOrden,
  productosSeleccionados,
  numeroOrden,
}) {
  const nombreProveedor =
    proveedores.find((c) => c.id === proveedor)?.empresa || "";
  // Calcular subtotal
  const subtotal = productosSeleccionados.reduce(
    (acc, producto) => acc + parseFloat(producto.subtotal),
    0
  );
  // Calcular descuento total (suponiendo que hay un descuento en cada producto)
  const descuentoTotal = productosSeleccionados.reduce(
    (acc, producto) => acc + parseFloat(producto.descuento),
    0
  );
  // Calcular IVA
  const iva = subtotal * 0.16; // Suponiendo que el IVA es del 16%
  // Calcular total
  const total = subtotal - descuentoTotal + iva;

  return (
    <div className="previa-cotizacion">
      <div className="cotizacion-header">
        <img
          src="/img/logo-iciamex.png"
          alt="ICIAMEX"
          className="logoCotizacion"
        />
        <div className="border-right"></div>
        <h1 className="cotizacion-title">Orden de Compra</h1>
      </div>
      <h1>Previa</h1>
      <h2>Orden: {numeroOrden?.toString().padStart(4, "0")}</h2>
      <hr />
      <p>Fecha de orden: {fechaOrden}</p>
      <p>Asunto: {asunto}</p>
      <p>Proveedor: {nombreProveedor}</p>
      <h3>DESCRIPCIÓN</h3>
      <table className="productos-table">
        <thead>
          <tr>
            <th>Cantidad</th>
            <th>ID</th>
            <th>Descripción</th>
            <th>Precio</th>
            <th>Descuento</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {productosSeleccionados.map((producto) => (
            <tr key={producto.id}>
              <td>{producto.cantidad}</td>
              <td>{producto.productoIdEditado}</td>
              <td>{producto.nombre}</td>
              <td>
                $
                {parseFloat(producto.costo)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td>
                $
                {parseFloat(producto.descuento)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td>
                $
                {parseFloat(producto.subtotal)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3>
        Subtotal: $
        {parseFloat(subtotal)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </h3>
      <h3>
        Descuento: $
        {parseFloat(descuentoTotal)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </h3>
      <h3>
        IVA: $
        {parseFloat(iva)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </h3>
      <h3>
        Total: $
        {parseFloat(total)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </h3>
    </div>
  );
}

export default OrdenForm;