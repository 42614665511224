import React, { useState, useEffect, useRef } from "react";
import {
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  doc,
  getFirestore,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import Swal from "sweetalert2";
import Modal from "react-modal";
import * as XLSX from "xlsx";
import ClientsTable from "./ClientsTable";
import Nav from "./Nav";
import Sidebar from "./Sidebar";
import moment from "moment";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";

moment.locale("es");

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingLeft: 0,
    padding: 0,
    fontFamily: "Roboto, sans-serif",
    "::WebkitScrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
  label: {
    display: "block",
    width: "160px", // Fija el ancho de los labels
    textAlign: "right", // Alinea los labels a la derecha
    whiteSpace: "nowrap",
  },
  input: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  select: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 101,
  },
};

// Estilos adicionales para los pasos
const stepContainerStyle = {
  display: "flex",
  justifyContent: "left", // Para mantener los pasos más juntos
  marginLeft: "100px",
  gap: "50px", // Ajusta la separación entre los pasos
  marginBottom: "20px",
  cursor: "pointer",
};

const stepStyle = (isActive) => ({
  padding: "10px 15px", // Ajustamos un poco el padding
  borderBottom: isActive ? "4px solid #007bff" : "4px solid transparent",
  transition: "border-bottom 0.3s ease",
  fontWeight: isActive ? "bold" : "normal",
});

const ListaClientes = () => {
  const [clientes, setClientes] = useState([]);
  const [formData, setFormData] = useState({
    empresa: "",
    rfc: "",
    regimenFiscal: "",
    moneda: "",
    telefono: "",
    correo: "",
    imagenURL: null,
    calle: "",
    numeroExt: "",
    numeroInt: "",
    colonia: "",
    codigoPostal: "",
    ciudad: "",
    estado: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [editingClienteId, setEditingClienteId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [proximasAVencer, setProximasAVencer] = useState([]);
  const [proximosEventos, setProximosEventos] = useState([]);
  const [showNuevoButton] = useState(true);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const dropdownRef = useRef(null); // Referencia al menú desplegable
  const fileInputRef = useRef(null);

  const regimenFiscalTranslationMap = {
    601: "601 - General de Ley Personas Morales",
    612: "612 - Personas Físicas con Actividades Empresariales y Profesionales",
    616: "616 - Sin obligaciones fiscales",
    626: "626 - Régimen Simplificado de Confianza (RESICO)",
  };

  const translateRegimenFiscal = (value) => {
    const code = value.split(" ")[0]; // Extrae el código (primer parte antes del espacio)
    return regimenFiscalTranslationMap[code] || value; // Traduce usando el código o devuelve el valor original
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      Swal.fire({
        title: "Error",
        text: "No se seleccionó ningún archivo.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        if (!workbook.SheetNames.length) {
          throw new Error("El archivo no contiene hojas válidas.");
        }

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        if (!sheet) throw new Error("No se pudo leer la hoja seleccionada.");

        const jsonData = XLSX.utils.sheet_to_json(sheet, { defval: "" });
        if (!jsonData.length) {
          throw new Error("El archivo no contiene datos válidos.");
        }

        const clientesCollectionRef = collection(db, "clientes");

        let nuevosClientes = 0;

        // Crear un array para almacenar las promesas de actualización
        const updatePromises = [];

        // Procesar cada fila individualmente
        for (const row of jsonData) {
          const cliente = {
            empresa: row["Company Name"] || "Sin nombre",
            rfc: row["Tax Registration Number"] || "Sin RFC",
            regimenFiscal: translateRegimenFiscal(row["Tax Regime"] || ""),
            telefono: row["Phone"] || "",
            correo: row["EmailID"] || "",
            direccion: {
              calle: row["Billing Attention"] || "",
              ciudad: row["Billing City"] || "",
              estado: row["Billing State"] || "",
              pais: row["Billing Country"] || "",
              codigoPostal: row["Billing Code"] || "",
            },
            status: row["Status"] || "Activo",
            datosCompletos: row, // Guardar toda la fila completa
          };

          // Verificar si el cliente ya existe por su RFC
          const clienteQuery = query(
            clientesCollectionRef,
            where("rfc", "==", cliente.rfc)
          );
          const querySnapshot = await getDocs(clienteQuery);

          if (querySnapshot.empty) {
            // Crear un cliente nuevo
            await addDoc(clientesCollectionRef, cliente);
            nuevosClientes++;
          } else {
            // Agregar promesas de actualización al array
            querySnapshot.forEach((docSnapshot) => {
              const docRef = doc(db, "clientes", docSnapshot.id);
              updatePromises.push(updateDoc(docRef, cliente));
            });
          }
        }

        // Esperar a que todas las actualizaciones terminen
        const updateResults = await Promise.all(updatePromises);
        const actualizaciones = updateResults.length;

        // Mensaje de éxito
        Swal.fire({
          title: "Importación exitosa",
          text: `${jsonData.length} cliente(s) procesado(s): ${nuevosClientes} agregado(s), ${actualizaciones} actualizado(s).`,
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error("Error al procesar el archivo:", error);
        Swal.fire({
          title: "Error",
          text: `Error al procesar el archivo: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    reader.onerror = () => {
      Swal.fire({
        title: "Error",
        text: "Ocurrió un error al leer el archivo. Inténtalo nuevamente.",
        icon: "error",
        confirmButtonText: "OK",
      });
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptionsMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleImportClick = () => {
    fileInputRef.current.click(); // Simula el clic en el input de archivo
  };

  useEffect(() => {
    const fetchCotizaciones = async () => {
      const firestore = getFirestore();
      const cotizacionesRef = collection(firestore, "cotizaciones");
      const unsubscribeCotizaciones = onSnapshot(
        cotizacionesRef,
        (snapshot) => {
          const cotizaciones = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Filtrar las cotizaciones que tienen fecha de vencimiento a partir de hoy y ordenarlas
          const proximas = cotizaciones
            .filter(
              (cotizacion) =>
                moment(cotizacion.fechaVencimiento) >= moment().startOf("day")
            )
            .sort(
              (a, b) => moment(a.fechaVencimiento) - moment(b.fechaVencimiento)
            );

          setProximasAVencer(proximas.slice(0, 6)); // Limitar la lista a 6 fechas próximas
        }
      );

      return () => unsubscribeCotizaciones();
    };

    const fetchEventos = async () => {
      const firestore = getFirestore();
      const eventosRef = collection(firestore, "eventos");
      const unsubscribeEventos = onSnapshot(eventosRef, (snapshot) => {
        const eventos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filtrar eventos que ocurren a partir de hoy y ordenarlos
        const proximos = eventos
          .filter((evento) => moment(evento.to) >= moment().startOf("day"))
          .sort((a, b) => moment(a.to) - moment(b.to));

        setProximosEventos(proximos.slice(0, 6)); // Limitar la lista a 6 eventos próximos

        // Filtrar fechas festivas que están a menos de una semana
        const fechasFestivasProximas = [];
        const fechasFestivasBase = [
          { title: "Año Nuevo", month: "01", day: "01", color: "#de2e03" },
          {
            title: "Día de la Constitución",
            month: "02",
            day: "05",
            color: "#de2e03",
          },
          {
            title: "Natalicio de Benito Juárez",
            month: "03",
            day: "21",
            color: "#de2e03",
          },
          {
            title: "Día del Trabajo",
            month: "05",
            day: "01",
            color: "#de2e03",
          },
          {
            title: "Independencia de México",
            month: "09",
            day: "16",
            color: "#de2e03",
          },
          {
            title: "Transición del Poder Ejecutivo",
            month: "10",
            day: "01",
            color: "#de2e03",
          },
          {
            title: "Revolución Mexicana",
            month: "11",
            day: "20",
            color: "#de2e03",
          },
          { title: "Navidad", month: "12", day: "25", color: "#de2e03" },
        ];

        const today = moment().startOf("day");
        const oneWeekFromNow = moment().add(7, "days").startOf("day");

        fechasFestivasBase.forEach((festivo) => {
          const festivoDate = moment(
            `${today.year()}-${festivo.month}-${festivo.day}`,
            "YYYY-MM-DD"
          );
          if (festivoDate.isBetween(today, oneWeekFromNow, null, "[]")) {
            fechasFestivasProximas.push({
              title: festivo.title,
              start: festivoDate.toDate(),
              end: festivoDate.toDate(),
              allDay: true,
              resource: "festivo",
              style: { backgroundColor: festivo.color },
            });
          }
        });

        if (fechasFestivasProximas.length > 0) {
          // Notificar sobre fechas festivas próximas
          console.log(
            `Fechas festivas próximas: ${fechasFestivasProximas
              .map(
                (festivo) =>
                  `${festivo.title} el ${moment(festivo.start).format("LL")}`
              )
              .join(", ")}`
          );
        }
      });

      return () => unsubscribeEventos();
    };

    // Ejecutar las funciones de carga de cotizaciones y eventos
    fetchCotizaciones();
    fetchEventos();
  }, []);

  const searchClientes = (term) => {
    const lowerCaseTerm = term.toLowerCase();
    return clientes.filter((cliente) => {
      const empresa = cliente.empresa || ""; // Valor por defecto si es undefined
      const rfc = cliente.rfc || "";
      const regimenFiscal = cliente.regimenFiscal || "";
      return (
        empresa.toLowerCase().includes(lowerCaseTerm) ||
        rfc.toLowerCase().includes(lowerCaseTerm) ||
        regimenFiscal.toLowerCase().includes(lowerCaseTerm)
      );
    });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "clientes"), (snapshot) => {
      const listaClientes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClientes(listaClientes);
      setIsLoading(false);
    });

    return () => unsubscribe(); // Cancelar la suscripción al desmontar el componente
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar campos obligatorios para el primer paso
    if (step === 1 || step === 2) {
      // Validar siempre que no esté vacío el nombre de la empresa
      const requiredFields = ["empresa"];
      for (let field of requiredFields) {
        if (formData[field].trim() === "") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `El campo "Nombre de la Empresa" es obligatorio!`,
          });
          return;
        }
      }
    }
    // Procesar el envío del formulario
    try {
      const clienteData = { ...formData };
      if (editingClienteId) {
        await updateDoc(doc(db, "clientes", editingClienteId), clienteData);
        setEditingClienteId(null);
        Swal.fire({
          icon: "success",
          title: "¡Cliente actualizado con éxito!",
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        await addDoc(collection(db, "clientes"), clienteData);
        Swal.fire({
          icon: "success",
          title: "¡Cliente registrado con éxito!",
          showConfirmButton: false,
          timer: 1000,
        });
      }
      setFormData({
        empresa: "",
        rfc: "",
        regimenFiscal: "",
        moneda: "",
        telefono: "",
        correo: "",
        imagenURL: null,
        calle: "",
        numeroExt: "",
        numeroInt: "",
        colonia: "",
        codigoPostal: "",
        ciudad: "",
        estado: "",
      });
      setStep(1);
      setModalIsOpen(false);
    } catch (error) {
      console.error("Error al agregar cliente:", error);
    }
  };

  const handleEditClient = (id) => {
    const clientToEdit = clientes.find((cliente) => cliente.id === id);

    // Configurar todos los campos de formData, asegurando valores predeterminados si faltan datos
    setFormData({
      empresa: clientToEdit.empresa || "",
      rfc: clientToEdit.rfc || "",
      regimenFiscal: clientToEdit.regimenFiscal?.split(" ")[0] || "",
      moneda:
        clientToEdit.moneda ||
        clientToEdit.datosCompletos?.["Currency Code"] ||
        "",
      telefono: clientToEdit.telefono || "",
      correo: clientToEdit.correo || "",
      imagenURL: clientToEdit.imagenURL || null,
      // Mapear los datos de dirección
      calle:
        clientToEdit.direccion?.calle ||
        clientToEdit.datosCompletos?.["Billing Address"] ||
        "",
      numeroExt: clientToEdit.direccion?.numeroExt || "",
      numeroInt: clientToEdit.direccion?.numeroInt || "",
      colonia: clientToEdit.direccion?.colonia || "",
      codigoPostal: clientToEdit.direccion?.codigoPostal || "",
      ciudad:
        clientToEdit.direccion?.ciudad ||
        clientToEdit.datosCompletos?.["Billing City"] ||
        "",
      estado: clientToEdit.direccion?.estado || "",
    });

    setEditingClienteId(id); // Establece el ID del cliente que se está editando
    setStep(1); // Asegúrate de que el modal comience en el paso 1
    setModalIsOpen(true); // Abre el modal
  };

  const handleDeleteClient = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, "clientes", id));
          Swal.fire({
            icon: "success",
            title: "¡Cliente eliminado con éxito!",
            showConfirmButton: false,
            timer: 1000,
          });
        } catch (error) {
          console.error("Error al eliminar cliente:", error);
        }
      }
    });
  };

  const resetFormData = () => {
    setFormData({
      empresa: "",
      rfc: "",
      regimenFiscal: "",
      moneda: "",
      telefono: "",
      correo: "",
      imagenURL: null,
      calle: "",
      numeroExt: "",
      numeroInt: "",
      colonia: "",
      codigoPostal: "",
      ciudad: "",
      estado: "",
    });
  };

  const handleOpenModal = () => {
    resetFormData(); // Limpia los campos del formulario
    setEditingClienteId(null); // Restablece el estado de edición del proveedor
    setModalIsOpen(true); // Abre el modal
  };

  return (
    <>
      <Nav
        handleSearch={handleSearch}
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
      />

      <div className="task-filters">
        <div className="task-filters-search">
          <i className="bi bi-search search-icon"></i>
          <input
            type="text"
            className="search-input2"
            placeholder="Buscar clientes"
          />
        </div>
        <div className="task-filters-right">
          <div className="task-filter filter-control">
            <Link href="#" className="filter-link control-link">
              <i className="bi bi-sliders2-vertical"></i> Filter
            </Link>
          </div>
          <div className="view-buttons-container">
            <button className="view-button" onClick={setShowOptionsMenu}>
              <i className="bi bi-three-dots-vertical"></i>
            </button>

            <button className="view-button list-view">
              <i className="bi bi-list"></i>
            </button>
          </div>
          <div>
            {showNuevoButton && (
              <button className="new-task-button" onClick={handleOpenModal}>
                + Nuevo
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="cotizaciones-table">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          style={customStyles}
        >
          <div>
            <button
              className="cerrar-button"
              onClick={() => setModalIsOpen(false)}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>

          <Nav
            handleSearch={handleSearch}
            proximasAVencer={proximasAVencer}
            proximosEventos={proximosEventos}
          />

          <Sidebar />

          {step === 1 && (
            <>
              <h2 style={{ textAlign: "left", marginLeft: "100px" }}>
                {editingClienteId ? "Editar Cliente" : "Agregar nuevo cliente"}
              </h2>
              <div style={stepContainerStyle}>
                <div style={stepStyle(step === 1)} onClick={() => setStep(1)}>
                  Info
                </div>
                <div style={stepStyle(step === 2)} onClick={() => setStep(2)}>
                  Dirección
                </div>
              </div>
              <form onSubmit={handleSubmit} className="client-form">
                <div className="form-row">
                  <label className="required" style={customStyles.label}>
                    Nombre de la Empresa*
                  </label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="empresa"
                    placeholder="Nombre de la Empresa"
                    value={formData.empresa}
                    onChange={handleChange}
                  />
                  <label style={customStyles.label}>RFC</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="rfc"
                    placeholder="RFC"
                    value={formData.rfc}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-row">
                  <label style={customStyles.label}>Régimen Fiscal</label>
                  <select
                    style={customStyles.select}
                    id="regimen"
                    name="regimenFiscal"
                    value={formData.regimenFiscal}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Seleccione un régimen fiscal
                    </option>
                    <option value="601">
                      601 - General de Ley Personas Morales
                    </option>
                    <option value="612">
                      612 - Personas Físicas con Actividades Empresariales y
                      Profesionales
                    </option>
                    <option value="616">616 - Sin obligaciones fiscales</option>
                    <option value="626">
                      626 - Régimen Simplificado de Confianza (RESICO)
                    </option>
                  </select>
                  <label style={customStyles.label}>Moneda</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="moneda"
                    placeholder="Moneda"
                    value={formData.moneda}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-row">
                  <label style={customStyles.label}>Teléfono</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="telefono"
                    placeholder="Teléfono"
                    value={formData.telefono}
                    onChange={handleChange}
                  />
                  <label style={customStyles.label}>Email</label>
                  <input
                    style={customStyles.input}
                    type="email"
                    name="correo"
                    placeholder="Correo Electrónico"
                    value={formData.correo}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-row">
                  <label style={customStyles.label}>Imagen</label>
                  <input
                    type="file"
                    name="imagen"
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      display: "block",
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      boxSizing: "border-box",
                    }}
                  />
                </div>
              </form>
            </>
          )}

          {step === 2 && (
            <>
              <h2 style={{ textAlign: "left", marginLeft: "100px" }}>
                {editingClienteId
                  ? "Editar Dirección del Cliente"
                  : "Dirección del Cliente"}
              </h2>
              <div style={stepContainerStyle}>
                <div style={stepStyle(step === 1)} onClick={() => setStep(1)}>
                  Info
                </div>
                <div style={stepStyle(step === 2)} onClick={() => setStep(2)}>
                  Dirección
                </div>
              </div>
              <form onSubmit={handleSubmit} className="client-form">
                <div className="form-row">
                  <label style={customStyles.label}>Calle</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="calle"
                    placeholder="Calle"
                    value={formData.calle}
                    onChange={handleChange}
                  />
                  <label style={customStyles.label}>No. Exterior</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="numeroExt"
                    placeholder="No. Exterior"
                    value={formData.numeroExt}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-row">
                  <label style={customStyles.label}>No. Interior</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="numeroInt"
                    placeholder="No. Interior"
                    value={formData.numeroInt}
                    onChange={handleChange}
                  />
                  <label style={customStyles.label}>Colonia</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="colonia"
                    placeholder="Colonia"
                    value={formData.colonia}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-row">
                  <label style={customStyles.label}>Código Postal</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="codigoPostal"
                    placeholder="Código Postal"
                    value={formData.codigoPostal}
                    onChange={handleChange}
                  />
                  <label style={customStyles.label}>Ciudad</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="ciudad"
                    placeholder="Ciudad"
                    value={formData.ciudad}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-row">
                  <label style={customStyles.label}>Estado</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="estado"
                    placeholder="Estado"
                    value={formData.estado}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-toolbar">
                  <div className="leftBtn">
                    <button
                      id="guardar-enviar"
                      className="btn btn-primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      {editingClienteId ? "Actualizar" : "Guardar y enviar"}
                    </button>
                    <button
                      id="cancelar"
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => setModalIsOpen(false)}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </Modal>
        {isLoading ? (
          <Spinner />
        ) : searchTerm !== "" && searchClientes(searchTerm).length === 0 ? (
          <p>No hay búsquedas disponibles</p>
        ) : (
          <ClientsTable
            clientes={searchTerm ? searchClientes(searchTerm) : clientes}
            onEditClient={handleEditClient}
            onDeleteClient={handleDeleteClient}
          />
        )}
      </div>

      {showOptionsMenu && (
        <div className="dropdown-options" ref={dropdownRef}>
          <p onClick={handleImportClick}>
            <i
              className="bi bi-box-arrow-in-down"
              style={{ marginRight: "10px" }}
            ></i>
            Importar
          </p>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
          />
          <p>
            <i
              className="bi bi-box-arrow-up"
              style={{ marginRight: "10px" }}
            ></i>
            Exportar
          </p>
        </div>
      )}
    </>
  );
};

export default ListaClientes;