import React, { useState, useEffect } from "react";

const FloatingActionButton = () => {
  const [scrollDirection, setScrollDirection] = useState("down"); // Dirección inicial
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY); // Posición inicial
  const [isAtBottom, setIsAtBottom] = useState(false); // Detecta si está al final

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const atBottom =
        window.innerHeight + currentScrollPos >= document.body.offsetHeight - 5;

      setIsAtBottom(atBottom);

      // Detectar dirección del scroll
      if (currentScrollPos > prevScrollPos) {
        setScrollDirection("down");
      } else if (currentScrollPos < prevScrollPos) {
        setScrollDirection("up");
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const handleClick = () => {
    if (isAtBottom || scrollDirection === "up") {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Ir hacia arriba
    } else {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" }); // Ir hacia abajo
    }
  };

  return (
    <button
      className="floating-action-button"
      onClick={handleClick}
      aria-label={
        isAtBottom || scrollDirection === "up"
          ? "Scroll to Top"
          : "Scroll to Bottom"
      }
    >
      <i
        className={`bi ${
          isAtBottom || scrollDirection === "up"
            ? "bi-arrow-up-short"
            : "bi-arrow-down-short"
        }`}
      ></i>
    </button>
  );
};

export default FloatingActionButton;
