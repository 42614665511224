// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import {
  collection,
  onSnapshot,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../firebaseConfig";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribeUser;
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);

      if (currentUser) {
        try {
          const userQuery = query(
            collection(db, "usuarios"),
            where("email", "==", currentUser.email)
          );

          unsubscribeUser = onSnapshot(userQuery, (querySnapshot) => {
            if (!querySnapshot.empty) {
              const userData = querySnapshot.docs[0].data();
              setUserRole(userData.rol || "");
              setAvatarUrl(userData.avatarUrl || ""); // Ensure this updates in real-time
              setFirstName(userData.nombre || "");
              setLastName(userData.apellido || "");
            }
            setLoading(false);
          });
        } catch (error) {
          console.error("Error al obtener los datos del usuario:", error);
          setLoading(false);
        }
      } else {
        setUserRole("");
        setAvatarUrl("");
        setFirstName("");
        setLastName("");
        setLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
      if (unsubscribeUser) unsubscribeUser();
    };
  }, []);

  // Función para actualizar el avatar en Firestore y en el contexto
  const updateAvatarUrl = async (newAvatarUrl) => {
    if (user) {
      try {
        const userQuery = query(
          collection(db, "usuarios"),
          where("email", "==", user.email)
        );

        const querySnapshot = await getDocs(userQuery);
        if (!querySnapshot.empty) {
          const userDocRef = querySnapshot.docs[0].ref;
          await updateDoc(userDocRef, { avatarUrl: newAvatarUrl });
          setAvatarUrl(newAvatarUrl); // Actualiza el contexto inmediatamente
        } else {
          console.error("No se encontró el documento del usuario.");
        }
      } catch (error) {
        console.error("Error al actualizar el avatar en Firestore:", error);
      }
    }
  };

  const logout = async () => {
    await signOut(auth);
    setUser(null);
    setUserRole("");
    setAvatarUrl("");
    setFirstName("");
    setLastName("");
    setLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userRole,
        avatarUrl,
        firstName,
        lastName,
        loading,
        setUser,
        setAvatarUrl: updateAvatarUrl, // Usa la función actualizada
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);