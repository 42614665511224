import React, { useState } from 'react';

const ProductTable = ({ products, onEditProduct, onDeleteProduct }) => {
  const [selectedProducts, setSelectedProducts] = useState({});

  // Maneja el cambio en los checkboxes
  const handleCheckboxChange = (productId) => {
    setSelectedProducts(prevSelected => ({
      ...prevSelected,
      [productId]: !prevSelected[productId],
    }));
  };

  // Maneja el cambio en el checkbox de seleccionar todo
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    const newSelected = products.reduce((acc, product) => {
      acc[product.id] = isChecked;
      return acc;
    }, {});
    setSelectedProducts(newSelected);
  };

  return (
    <div className='cotizaciones-table'>
      <table className="tableProducts">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={Object.keys(selectedProducts).length === products.length && Object.values(selectedProducts).every(Boolean)}
                className="checkbox-select-all"
              />
            </th>
            <th></th>
            <th>Descripción</th>
            <th>Categoría</th>
            <th>ID</th>
            <th>Costo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => (
            <tr key={product.id}>
              <td>
                <input
                  type="checkbox"
                  checked={!!selectedProducts[product.id]}
                  onChange={() => handleCheckboxChange(product.id)}
                  className="checkbox"
                />
              </td>
              <td>
                <img src={product.imagen} alt={product.nombre} className="tableProducts-image" />
              </td>
              <td>
                <span className="description-text">{product.nombre}</span>
                <span className="model-text"> (Modelo: {product.numeroDeParte})</span>
              </td>
              <td>{product.categoria}</td>
              <td>{product.id}</td>
              <td>{parseFloat(product.costo).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
              <td>
                <button className='btnEditar' onClick={() => onEditProduct(product.id)}>Editar</button>
                <button className='btnEliminar' onClick={() => onDeleteProduct(product.id)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;
