import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import RemisionForm from "./RemisionForm";

Modal.setAppElement("#root");

const BandejaRemisiones = ({
  remisiones,
  onRowClick,
  guardarRemision,
  modoEdicion,
  remision,
  clientes,
}) => {
  const itemsPerPageOptions = [10, 25, 50, 100];
  const [filteredRemisiones, setFilteredRemisiones] = useState(remisiones);
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showBulkActionsOptions, setShowBulkActionsOptions] = useState(false);
  const [selectedRemisiones, setSelectedRemisiones] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(filteredRemisiones.length / itemsPerPage);

  const paginatedRemisiones = filteredRemisiones.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    const filtered = remisiones.filter((remision) => {
      const formattedImporte = parseFloat(remision?.total)
        ?.toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return (
        remision.nombreCliente.toLowerCase().includes(searchTerm.toLowerCase()) ||
        remision.fechaRemision.includes(searchTerm) ||
        remision.numeroRemision.toString().padStart(4, "0").includes(searchTerm) ||
        formattedImporte.includes(searchTerm)
      );
    });
    setFilteredRemisiones(filtered);
    setCurrentPage(1);
  };

  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month} ${year}`;
  }

  const openFormulario = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const toggleBulkActionsOptions = () => {
    setShowBulkActionsOptions(!showBulkActionsOptions);
  };

  const handleSelectRemision = (remisionId) => {
    setSelectedRemisiones((prevSelected) =>
      prevSelected.includes(remisionId)
        ? prevSelected.filter((id) => id !== remisionId)
        : [...prevSelected, remisionId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRemisiones([]); // Deselecciona todo
    } else {
      setSelectedRemisiones(remisiones.map((rem) => rem.id)); // Selecciona todo
    }
    setSelectAll(!selectAll);
  };

  const showBulkActions = selectedRemisiones.length > 0;

  const exportarPDF = () => {
    console.log("Exportar como PDF");
  };

  const imprimir = () => {
    console.log("Imprimir remisión");
  };

  const eliminar = () => {
    console.log("Eliminar remisión");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.bulk-actions-right')) {
        setShowBulkActionsOptions(false);
      }
    };
    if (showBulkActionsOptions) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showBulkActionsOptions]);

  return (
    <div className="remisiones-bandeja">
      <div className="bandeja-search-form">
        <div className="bandeja-search-input-wrapper">
          <i className="bi bi-search"></i>
          <input
            type="text"
            className="bandeja-search-input"
            placeholder="Buscar..."
            onChange={handleSearch}
          />
        </div>
      </div>

      {showBulkActions && (
        <div className="bulk-actions">
          <div className="bulk-actions-left">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
              style={{ marginTop: "0", alignSelf: "center" }}
            />
            <p>{selectedRemisiones.length} seleccionados</p>
            <button className="boton-accion">Marcar como enviado</button>
            <div className="bulk-actions-right">
              <i className="bi bi-three-dots-vertical" onClick={toggleBulkActionsOptions}></i>
              {showBulkActionsOptions && (
                <div className="dropdown-options">
                  <p onClick={exportarPDF}>Exportar como PDF</p>
                  <p onClick={imprimir}>Imprimir</p>
                  <p onClick={eliminar}>Eliminar</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="encabezado">
        <div className="categoria-filtro" onClick={toggleDropdown}>
          <p>Todas las remisiones</p>
          <i className="bi bi-caret-down-fill"></i>
          {showDropdown && (
            <div className="dropdown-menu">
              <p>Remisiones enviadas</p>
              <p>Remisiones pendientes</p>
            </div>
          )}
        </div>
        <div className="acciones-encabezado" style={{ position: "relative" }}>
          <button className="boton-accion" onClick={openFormulario}>
            + Nuevo
          </button>
          <i className="bi bi-three-dots-vertical" onClick={toggleOptions}></i>
          {showOptions && (
            <div className="dropdown-options">
              <p>Ordenar</p>
              <p>Importar</p>
              <p>Exportar</p>
            </div>
          )}
        </div>
      </div>

      {paginatedRemisiones.length > 0 ? (
        paginatedRemisiones.map((remision) => (
          <div
            key={remision.id}
            className="mensaje-cotizacion"
            onClick={() => onRowClick(remision.id)}
          >
            <div className="info-container">
              <input
                type="checkbox"
                onChange={() => handleSelectRemision(remision.id)}
                checked={selectedRemisiones.includes(remision.id)}
              />
              <div className="info-column">
                <p>
                  <span className="cliente-texto">CLIENTE:</span>{" "}
                  <span className="cliente-nombre">{remision.nombreCliente}</span>
                </p>
                <div className="fecha-referencia">
                  <p>No. Remisión: {remision.numeroRemision.toString().padStart(4, "0")}</p>
                  <span className="punto">•</span>
                  <p>Fecha: {formatDate(new Date(remision.fechaRemision))}</p>
                </div>
              </div>
              <div className="importe-column">
                <p>
                  Importe: $
                  {parseFloat(remision?.total)
                    ?.toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p style={{ position: "relative", right: "25%" }}>
          No existe ninguna remisión que coincida con esta búsqueda.
        </p>
      )}

      <div className="paginador">
        <div className="paginador-opciones">
          <div className="paginador-config">
            <div className="paginador-select-wrapper">
              <i className="bi bi-gear-fill" style={{ marginRight: "10px" }}></i>
              <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                {itemsPerPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option} por página
                  </option>
                ))}
              </select>
            </div>
            <button onClick={() => handlePageChange("prev")} disabled={currentPage === 1}>
              {"<"}
            </button>
            <span>
              {(currentPage - 1) * itemsPerPage + 1} -{" "}
              {Math.min(currentPage * itemsPerPage, filteredRemisiones.length)}
            </span>
            <button onClick={() => handlePageChange("next")} disabled={currentPage === totalPages}>
              {">"}
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Nueva Remisión"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            paddingLeft: 0,
            padding: 0,
            fontFamily: "Roboto, sans-serif",
            "::WebkitScrollbar": {
              width: "0px",
              background: "transparent",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          },
        }}
      >
        <button onClick={closeModal} className="cerrar-button">
          <i className="bi bi-x-lg"></i>
        </button>

        <RemisionForm
          clientes={clientes}
          guardarRemision={guardarRemision}
          remision={remision}
        />
      </Modal>
    </div>
  );
};

export default BandejaRemisiones;
